import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { DomainAction } from './constants'

const formatAction = domain => {
  domain.actionAdd = domain.action === DomainAction.ADD
  domain.actionDeactivate = domain.action === DomainAction.DEACTIVATE
  domain.actionActivate = domain.action === DomainAction.ACTIVATE
  domain.actionNone = domain.action === DomainAction.NONE

  domain.isActionDeactivated = domain.actionDeactivate || (domain.actionNone && !domain.activated)
  domain.isActionActivated = domain.actionActivate || (domain.actionNone && domain.activated)
  domain.isActionAdd = domain.actionAdd

  return domain
}

const formatDomain = createFormatter(formatAction)

const formatDomains = createListFormatter(formatDomain)

export const formatProAccountForm = createFormatter(
  createSubObjectFormatter('domains', formatDomains)
)
