
/**
 * Locale: 'FR', 'EN'
 */
export const isLocale = (locale) => {
  if (!locale || locale.length !== 2) {
    return false
  }

  return true
}
