import { createFormatter } from 'pmt-utils/format'

import { UploadType } from '../constants'

import formatCellErrors from './formatCellErrors'
import formatDataErrors from './formatDataErrors'

import formatRunnerErrors from './formatRunnerErrors'

import formatDataTable from './formatDataTable'

const formatUploadType = (report, props) => {
  report.uploadTypeValidationMode = props.uploadType === UploadType.VALIDATION_MODE
  report.uploadTypeCreationMode = props.uploadType === UploadType.CREATION_MODE
  return report
}

const formatData = (report) => {
  report.dataTable = formatDataTable(report.data)
  return report
}

const formatReportErrors = (report) => {
  report.cellErrors = formatCellErrors(report.cellErrors)
  report.dataErrors = formatDataErrors(report.dataErrors)
  report.runnerErrors = formatRunnerErrors(report.runnerErrors)
  report = formatData(report)

  return report
}

export const formatImportReport = createFormatter(
  formatReportErrors,
  formatUploadType,
)
