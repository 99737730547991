import { apiConsumerListReducer, apiConsumerReducer } from 'pmt-modules/apiConsumer'
import { apiKeyReducer } from 'pmt-modules/apiConsumer/apiKey'
import { apiConsumerConfigurationReducers } from 'pmt-modules/apiConsumer/configuration'
import { userReducer, updateUserReducer } from 'pmt-modules/user'

import { userOrdersReducer } from 'pmt-modules/userOrders'

import { tableReducer } from 'pmt-modules/table'
import { proMeReducer } from 'pmt-modules/proMe'
import { authProReducer } from 'pmt-modules/authPro'

import { meDomainReducer, domainReducer } from 'pmt-modules/domain'

import { boReducer } from 'pmt-modules/bo'

import { restaurantReducer } from 'pmt-modules/restaurant'
import { restaurantDocumentsStatusReducer } from 'pmt-modules/restaurantDocuments'

import { importUserReducer } from 'pmt-modules/importUser'

import { userCardReducer } from 'pmt-modules/userCard'

import { userCreationReducer } from 'pmt-modules/proCreateUser'

import { userUserAccountsReducer, updateUserAccountReducer } from 'pmt-modules/userAccount'

import { userHistoryReducer } from 'pmt-modules/userHistory'

import { proUserUpdateReducer } from 'pmt-modules/proUpdateUser'

import { exportRestaurantsGroupReducer } from 'pmt-modules/exportRestaurantsGroup'

import { proForgotPasswordReducer } from 'pmt-modules/proForgotPassword'

import { restaurantsGroupRolesReducer } from 'pmt-modules/restaurantsGroupRoles'
import { domainRestaurantsGroupsReducer } from 'pmt-modules/domainRestaurantsGroups'
import { proAccountReducer } from 'pmt-modules/proAccount'
import { proRoleReducer, postProRoleReducer, putProRoleReducer } from 'pmt-modules/proRole'

import { proAccountsListReducer } from 'pmt-modules/proAccountsList'
import { restaurantsGroupReducer, postRestaurantsGroupReducer } from 'pmt-modules/restaurantsGroup'

import { appConfigReducer } from 'pmt-modules/appConfig'

import { entityHistoryReducer } from 'pmt-modules/entityHistory'

import { feedbackAnswerReducer } from 'pmt-modules/feedback/answer'
import { feedbackCampaignReducer } from 'pmt-modules/feedback/campaign'
import { feedbackResumeReducer } from 'pmt-modules/feedback/resume'
import { templateReducer } from 'pmt-modules/template'

import { auditReducer } from 'pmt-modules/audit'
import { orderDetailReducer } from 'pmt-modules/orderDetail'
import { depositDetailReducer } from 'pmt-modules/depositDetail'
import { scheduledActionReducer } from 'pmt-modules/scheduledAction'
import { proNotificationReducer } from 'pmt-modules/proNotificationSetting'
import { mediaReducer } from 'pmt-modules/media'
import { uploadReducer } from 'pmt-modules/upload'

import { restaurantOrdersOnGoingReducer } from 'pmt-modules/orderOnGoingRestaurant'
import { restaurantCheckupReducer } from 'pmt-modules/restaurantCheckup'

import { pmtFirebaseReducer } from 'pmt-modules/firebase'

import {
  apiAsyncServiceDataReducer,
  apiAsyncServiceRunnerReducer,
  apiAsyncServiceResultReducer,
} from 'pmt-modules/apiAsyncService'

import { appSecurityReducer } from 'pmt-modules/appSecurity'

import { paymentReducer } from 'pmt-modules/paymentBo'

import { requestApiReducer } from 'pmt-modules/useApi'
import { flashMessagesReducer } from 'pmt-modules/flashMessages'

//
//
//
import { payoutReducer } from 'pmt-modules/payout'
import { payoutsReducer } from 'pmt-modules/payouts'

//
//
//

export default {
  appConfig: appConfigReducer,
  apiConsumerList: apiConsumerListReducer,
  apiConsumer: apiConsumerReducer,
  apiConsumerConfiguration: apiConsumerConfigurationReducers,
  apiKey: apiKeyReducer,
  appSecurity: appSecurityReducer,
  user: userReducer,
  updateUser: updateUserReducer,
  proUserUpdate: proUserUpdateReducer,
  userOrders: userOrdersReducer,
  table: tableReducer,
  proMe: proMeReducer,
  authPro: authProReducer,
  meDomain: meDomainReducer,
  domain: domainReducer,
  proForgotPassword: proForgotPasswordReducer,
  bo: boReducer,
  importUser: importUserReducer,
  userCard: userCardReducer,
  userCreation: userCreationReducer,
  userAccounts: userUserAccountsReducer,
  userHistory: userHistoryReducer,
  restaurant: restaurantReducer,
  restaurantDocumentsStatus: restaurantDocumentsStatusReducer,
  updateUserAccount: updateUserAccountReducer,
  exportRestaurantsGroup: exportRestaurantsGroupReducer,

  restaurantsGroupRoles: restaurantsGroupRolesReducer,
  domainRestaurantsGroups: domainRestaurantsGroupsReducer,
  proAccount: proAccountReducer,
  proRole: proRoleReducer,
  postProRole: postProRoleReducer,
  putProRole: putProRoleReducer,
  proAccountsList: proAccountsListReducer,
  restaurantsGroup: restaurantsGroupReducer,
  postRestaurantsGroup: postRestaurantsGroupReducer,

  entityHistory: entityHistoryReducer,
  feedbackAnswer: feedbackAnswerReducer,
  feedbackCampaign: feedbackCampaignReducer,
  feedbackResume: feedbackResumeReducer,

  template: templateReducer,

  audit: auditReducer,

  orderDetail: orderDetailReducer,

  depositDetail: depositDetailReducer,

  scheduledAction: scheduledActionReducer,

  restaurantOrdersOnGoing: restaurantOrdersOnGoingReducer,
  restaurantCheckup: restaurantCheckupReducer,

  pmtFirebase: pmtFirebaseReducer,

  proNotification: proNotificationReducer,
  media: mediaReducer,
  upload: uploadReducer,

  //
  //
  //

  apiAsyncServiceData: apiAsyncServiceDataReducer,
  apiAsyncServiceRunner: apiAsyncServiceRunnerReducer,
  apiAsyncServiceResult: apiAsyncServiceResultReducer,

  //
  //
  //
  payouts: payoutsReducer,
  payout: payoutReducer,
  payment: paymentReducer,

  requestApi: requestApiReducer,
  flashMessages: flashMessagesReducer,
}
