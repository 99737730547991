import { combineReducers, createPaginateReducer } from 'pmt-modules/redux'

import { GetPayoutsListAction, GetPayoutsFailedListAction } from './actions'

const PayoutsListReducer = createPaginateReducer(GetPayoutsListAction, {
  isTypeCursor: true,
})

const PayoutsFailedListReducer = createPaginateReducer(GetPayoutsFailedListAction, {
  isTypeCursor: true,
})

export const payoutsReducer = combineReducers({
  list: PayoutsListReducer,
  payoutsFailed: PayoutsFailedListReducer,
})
