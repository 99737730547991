import invariant from 'invariant'

import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'

/**
 *
 * @param  {string} type            the statistics type
 * @param  {Function} apiCallFunc   the function that gives the API call configuration
 * @param  {Function} formatter     formatter: (optionnal) format list data / cf. createFormatter
 *
 * @return {[type]}                 the statistics:
 *                                  {
 *                                    type, // the statisticsType
 *                                    apiCallFunc, // the function that gives the API call configuration
 *                                    formatter, // a function that returns the default values
 *                                  }
 */
const createStatistics = (type, apiCallFunc, formatter) => {
  invariant(!isNil(type), 'statistics type is required')
  invariant(isFunction(apiCallFunc), 'apiCallFunc must be a function')
  invariant(isNil(formatter) || isFunction(formatter), 'formatter must be a function')

  return {
    type,
    apiCallFunc,
    formatter,
  }
}

export default createStatistics
