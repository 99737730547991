import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import { Link } from 'pmt-modules/routing'

/**
 * Component that combine a Button and a Link
 * See https://material-ui-next.com/demos/buttons/#third-party-routing-library
 */
const ButtonLink = ({ to, params, query, newTab, children, target, href, ...otherProps }) =>
  !href ? (
    <Button
      component={Link}
      to={to}
      params={params}
      query={query}
      target={newTab ? '_blank' : target}
      {...otherProps}
    >
      {children}
    </Button>
  ) : (
    <Button component="a" href={href} target={newTab ? '_blank' : target} {...otherProps}>
      {children}
    </Button>
  )

ButtonLink.getRoute = Link.getRoute

ButtonLink.defaultProps = {
  newTab: false,
}

ButtonLink.propTypes = {
  to: PropTypes.object,

  params: PropTypes.object,

  query: PropTypes.object,

  /**
   * true if the link must be opened in a new tab
   */
  newTab: PropTypes.bool,
}

export default ButtonLink
