import {
  StatisticsGroupType,
  createGroupStatistics,
  createStatistics,
} from 'pmt-modules/statistics'
import StatisticsApi from 'pmt-modules/api/calls/StatisticsApi'

import { OrderStatisticsType } from './constants'

import {
  formatKeyStats,
  formatOrderPeriodStats,
  formatOrderStatsMode,
  formatOrderSourcesStats,
  formatOrderPaymentMethods,
  formatOrderIdentificationMethods,
  formatOrderRestaurants,
} from './format'

export * from './constants'

const keyStats = createStatistics(
  OrderStatisticsType.KEY_STATS,
  StatisticsApi.getOrderStatsKeyStats,
  formatKeyStats
)

const periodStats = createStatistics(
  OrderStatisticsType.PERIOD,
  StatisticsApi.getOrdersStatsPeriod,
  formatOrderPeriodStats
)

const modeStats = createStatistics(
  OrderStatisticsType.MODE,
  StatisticsApi.getOrderStatsMode,
  formatOrderStatsMode
)

const sourcesStats = createStatistics(
  OrderStatisticsType.SOURCE,
  StatisticsApi.getOrderStatsSource,
  formatOrderSourcesStats
)

const paymentMethodsStats = createStatistics(
  OrderStatisticsType.PAYMENT_METHODS,
  StatisticsApi.getOrderStatsPaymentMethods,
  formatOrderPaymentMethods
)

const identificationMethodsStats = createStatistics(
  OrderStatisticsType.IDENTIFICATION_METHODS,
  StatisticsApi.getOrderStatsIdentificationMethods,
  formatOrderIdentificationMethods
)

const restaurantsStats = createStatistics(
  OrderStatisticsType.RESTAURANTS,
  StatisticsApi.getOrderStatsRestaurants,
  formatOrderRestaurants
)

export default createGroupStatistics(StatisticsGroupType.ORDER, [
  keyStats,
  periodStats,
  modeStats,
  sourcesStats,
  paymentMethodsStats,
  identificationMethodsStats,
  // should not be used anymore as we don't have any catering anymore
  //subsidyStats,
  restaurantsStats,
])
