import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '../../SvgIcon';

let AccountCardDetails = (props) => (
  <SvgIcon {...props}>
    <path d="M2,3H22C23.05,3 24,3.95 24,5V19C24,20.05 23.05,21 22,21H2C0.95,21 0,20.05 0,19V5C0,3.95 0.95,3 2,3M14,6V7H22V6H14M14,8V9H21.5L22,9V8H14M14,10V11H21V10H14M8,13.91C6,13.91 2,15 2,17V18H14V17C14,15 10,13.91 8,13.91M8,6A3,3 0 0,0 5,9A3,3 0 0,0 8,12A3,3 0 0,0 11,9A3,3 0 0,0 8,6Z"/>
  </SvgIcon>
);
AccountCardDetails = pure(AccountCardDetails);
AccountCardDetails.displayName = 'AccountCardDetails';
AccountCardDetails.muiName = 'SvgIcon';

export default AccountCardDetails;
