//
// contains the custom actions used for the proAccount form.
//

import FormType from 'pmt-modules/form/FormType'
import createFormCustomAction from 'pmt-modules/form/createFormCustomAction'

/**
 * used as actionName for custom form action
 */
export const SpecificActions = {
  ADD_PRO_DOMAIN: 'PRO_ACCOUNT::FORM::ADD_PRO_DOMAIN',
}

/**
 * Add a new pro domain to the pro `domains`.
 * This new pro domain can be fill or removed.
 */
export const addProDomain = () =>
  createFormCustomAction(FormType.PRO_ACCOUNT_EDIT, SpecificActions.ADD_PRO_DOMAIN, {})
