const ApiEndpoints = {
  RESTAURANTS_GROUPS: '/restaurants-groups',

  RESTAURANTS_GROUP: '/restaurants-groups/:restaurantsGroupId',
  RESTAURANTS_GROUP_RESTAURANTS: '/restaurants-groups/restaurants',
  RESTAURANTS_GROUP_HIERARCHY: '/restaurants-groups/hierarchy',

  API_CONSUMERS: '/restaurants-groups/:restaurantsGroupId/api-consumers',
  API_CONSUMERS_RESTAURANT: '/api-consumers/restaurants/:restaurantId',
  API_CONSUMER: '/api-consumers/:apiConsumerId',
  API_KEY: '/api-consumers/:apiConsumerId/apikey',
  API_CONSUMER_VALIDATION: '/api-consumers/:apiConsumerId/validate',
  CREATE_API_CONSUMER: '/api-consumers',

  RESTAURANTS: '/restaurants',
  RESTAURANT_LIST: '/restaurants',
  RESTAURANT: '/restaurants/:restaurantId',

  RESTAURANT_CHECKUP: '/checkup/restaurants',

  RESTAURANT_DOCUMENTS_KYC: '/restaurants/:restaurantId/docs/kyc',

  RESTAURANTS_GROUP_SEARCH_USERS: '/restaurants-groups/:rGroupId/users/search',

  KIOSK_SETTINGS: '/restaurants/:restaurantId/kiosk-settings',

  // TODO: add 's'
  GET_CHECK_TABLE_NUMBER: '/restaurant/:restaurantId/table/:tableNumber/check',

  GET_CHECK_POS_ID: '/restaurant/:restaurantId/pos-check-id/:posCheckId/check',

  GET_CHECK: '/restaurants/:restaurantId/checks/:checkId',

  PUT_MERGE_CHECKS: '/restaurants/:restaurantId/checks/merge',

  GET_CHECK_CODE: '/restaurant/:restaurantId/code/:code/check',

  RESTAURANT_USER_ACCOUNTS: '/restaurants/:restaurantId/user-accounts',

  OAUTH_TOKEN: '/oauth/token',

  OAUTH_AUTHORIZE_APP: '/oauth/authorize',

  REGISTER: '/users',

  ME_CARD_REGISTRATION: '/users/me/cards-registration',
  ME_CARD_REGISTRATION_TYPE: '/users/me/cards-registration/v2',

  ME_CARD: '/users/me/cards',
  ME_CARD_V2: '/users/me/cards/v2',

  USER_ME: '/users/me',

  USER_CARDS: '/users/:userId/cards',
  USER_CARDS_DELETE: '/users/:userId/cards/:cardId',

  USER_ACCOUNTS: '/users/:userId/accounts',
  USER_ACCOUNT: '/users/:userId/accounts/:userAccountId',
  USER_ACCOUNT_TRANSACTIONS: '/users/:userId/accounts/:userAccountId/transactions',

  USER_ADDRESSES: '/users/:userId/addresses',
  USER_ADDRESS: '/users/:userId/addresses/:addressId',
  USER_FORGOT_PASSWORD: '/users/password-recovery/recover',

  RGROUP_USERS: '/restaurants-groups/:rGroupId/users',
  RGROUP_USER: '/restaurants-groups/:rGroupId/users/:userId',

  USERS: '/users',
  USER: '/users/:userId',

  DEPOSITS: '/deposits',
  DEPOSIT: '/deposits/:depositId',

  GET_PAYMENT: '/payments/:paymentId',
  POST_PAYMENT_EMAIL: '/payments/:paymentId/email',
  GET_PAYMENT_PDF_LINK: '/payments/:paymentId/pdf/link',
  POST_PAYMENT: '/restaurant/:restaurantId/check/:checkId/payment',
  GET_ASYNC_PAYMENT: '/payments/async/:paymentId',

  HISTORY_USER_ORDERS: '/orders',
  HISTORY_USER_BOOKINGS: '/bookings',
  HISTORY_USER_PAYMENTS: '/payments',

  PAYMENTS_HISTORY: '/payments',

  ORDER_PREVIEW: '/orders-previews',

  ORDERS: '/orders',
  ORDERS_ON_GOING: '/orders/on-goings',
  ORDERS_HISTORY: '/orders',

  ORDER: '/orders/:orderId',

  PRO_ME: '/pro/accounts/me',

  PRO_ACCOUNTS: '/pro/accounts',

  PRO_ACCOUNT: '/pro/accounts/:proAccountId',

  PRO_DOMAINS: '/pro/domains',

  PRO_DOMAIN: '/pro/domains/:domainId',

  PRO_ME_DOMAINS: '/pro/accounts/me/domains',

  PRO_ME_DOMAIN: '/pro/domains/:domainId',

  PRO_ROLES: '/pro/roles',
  PRO_ROLE: '/pro/roles/:roleId',

  PRO_FORGOT_PASSWORD: '/pro/password-recovery/recover',

  IMPORT_USER: '/restaurants-groups/:rGroupId/import/user-accounts',

  /**
   * Statistics
   */
  STATISTICS_ORDER: '/restaurants-groups/:restaurantsGroupId/stats/order/general-stats',
  STATISTICS_DEPOSIT: '/restaurants-groups/:restaurantsGroupId/stats/deposit/general-stats',
  STATISTICS_BALANCE_FOR_DAY: '/restaurants-groups/:restaurantsGroupId/stats/balance/for-day',

  EXPORT_DEPOSITS: '/restaurants-groups/:restaurantsGroupId/export/deposits',
  EXPORT_SUBSIDY_BILL: '/restaurants-groups/:restaurantsGroupId/export/subsidy-bill',
  EXPORT_SALES: '/restaurants-groups/:restaurantsGroupId/export/sales',

  EXPORT_RESTAURANTS_GROUP_DATA: '/restaurants-groups/:restaurantsGroupId/export',

  /**
   * CATALOG
   */
  MENU: '/restaurants/:restaurantId/menu',

  /**
   * CATALOG
   */
  CATALOG: '/restaurants/:restaurantId/catalog/v2',

  //
  //
  //

  ENTITY_HISTORY_LIST: '/history',
  ENTITY_HISTORY: '/history/:entityHistoryId',
  ENTITY_HISTORY_FOR_SESSION: '/history/sessions/:sessionId',

  //
  // Feedback
  //
  FEEDBACK_CAMPAIGNS: '/feedbacks/campaigns',
  FEEDBACK_CAMPAIGN: '/feedbacks/campaigns/:feedbackCampaignId',
  FEEDBACK_CAMPAIGN_EXPORT: '/feedbacks/campaigns/:feedbackCampaignId/export',
  FEEDBACK_ANSWERS: '/feedbacks/campaigns/:feedbackCampaignId/answers',
  FEEDBACK_ANSWER_TOKEN: '/feedbacks/answers/token/:feedbackAnswerToken',
  FEEDBACK_RESUME_FOR_OBJECT: '/feedbacks/answers/:objectType/:objectId',
  FEEDBACK_ANSWERS_USER: '/feedbacks/answers/users/:userId',

  //
  // Scheduled Actions
  //
  SCHEDULED_ACTIONS: '/scheduled-actions',
  SCHEDULED_ACTION: '/scheduled-actions/:scheduledActionId',
  SCHEDULED_ACTION_RUN: '/scheduled-actions/run/:scheduledActionId',

  //
  // Template
  //
  CUSTOMS_TEXTS: '/customs/texts-containers',
  CUSTOMS_TEXTS_CONTAINER: '/customs/texts-containers/:containerId',
  TEMPLATE_EMAIL_DATA: '/templates/preview/email/data',

  //
  // Audit
  //
  EVENTS_SEARCH: '/events/search',

  EVENTS_SESSION: '/events/sessions/:sessionId',

  AUDIT_LOGS_SEARCH: '/audit/logs/search',

  //
  //
  //
  FIREBASE_AUTH: '/firebase/auth',

  //
  //
  //
  PRO_NOTIFICATION_IDENTITIES: '/pro-notifications/identities',
  PRO_NOTIFICATIONS: '/pro-notifications',
  PRO_NOTIFICATION: '/pro-notifications/:proNotificationSettingId',

  //
  // Media
  //
  MEDIA: '/restaurants-groups/:rGroupId/media',
  MEDIA_DETAIL: '/restaurants-groups/:rGroupId/media/:mediaId',
  MEDIA_OPEN: '/restaurants-groups/:rGroupId/media/:mediaId/open',

  //
  //
  //
  API_ASYNC_SERVICE_DATA: '/service-async/:asyncServiceId',

  //
  // Payouts
  //
  PAYOUT: '/payouts/:payoutId',
  PAYOUTS_LIST: '/payout/:rGroupId/list',
  PAYOUTS_ERROR_LIST: '/payout/:rGroupId/list-errors',
}

export default ApiEndpoints
