import { createSharedListReducer } from 'pmt-modules/redux/sharedList'

import { LoadDomainRestaurantsGroupsAction } from './actions'

//
// Retain the roles for a restaurants group
// At first, we wanted to retrieve the restaurants groups for a specific domain
// The service does not exists yet, so we use the service that gives the restaurants group and its
// children
// The restaurants group id used is the restaurants group id of the domain used on the backoffice
//

const transformRestaurantsGroupChildrenToArray = restaurantsGroup => {
  let childrenArray = []
  if (restaurantsGroup.children) {
    restaurantsGroup.children.forEach(children => {
      childrenArray = childrenArray.concat(transformRestaurantsGroupChildrenToArray(children))
    })
  }

  return [restaurantsGroup, ...childrenArray]
}

export const domainRestaurantsGroupsReducer = createSharedListReducer(
  LoadDomainRestaurantsGroupsAction,
  action => action.data.restaurantsGroupId,
  {
    dataModifier: transformRestaurantsGroupChildrenToArray,
  }
)
