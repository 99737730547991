import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import ScheduledActionApi from 'pmt-modules/api/calls/ScheduledActionApi'

export const GetScheduledActionActions = createApiEnumAction('SCHEDULED_ACTION::GET')
export const PostScheduledActionActions = createApiEnumAction('SCHEDULED_ACTION::POST')
export const PutScheduledActionActions = createApiEnumAction('SCHEDULED_ACTION::PUT')
export const RunScheduledActionActions = createApiEnumAction('SCHEDULED_ACTION::RUN')

export const getScheduledActions = opts =>
  createApiCallAction(GetScheduledActionActions, ScheduledActionApi.get(opts), {})

export const postScheduledAction = scheduledAction =>
  createApiCallAction(PostScheduledActionActions, ScheduledActionApi.post(scheduledAction), {
    scheduledAction,
  })

export const putScheduledAction = scheduledAction =>
  createApiCallAction(PutScheduledActionActions, ScheduledActionApi.put(scheduledAction), {
    scheduledAction,
  })

export const runScheduledAction = scheduledActionId =>
  createApiCallAction(RunScheduledActionActions, ScheduledActionApi.run(scheduledActionId), {
    scheduledActionId,
  })
