import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Cheese = props => (
  <SvgIcon {...props}>
    <path d="M 8.546875 2.0019531 A 1.0001 1.0001 0 0 0 8.2792969 2.0507812 C 3.1839636 3.7477497 2.0253906 8.6191406 2.0253906 8.6191406 A 1.0001 1.0001 0 0 0 2 8.84375 L 2 19 A 1.0001 1.0001 0 0 0 3 20 L 21 20 A 1.0001 1.0001 0 0 0 22 19 L 22 9 A 1.0001 1.0001 0 0 0 21.435547 8.0996094 L 9.03125 2.0996094 A 1.0001 1.0001 0 0 0 8.546875 2.0019531 z M 8.6074219 4.1171875 L 16.636719 8 L 4.390625 8 C 4.9254567 6.8698858 6.1164914 5.0772748 8.6074219 4.1171875 z M 4 10 L 20 10 L 20 18 L 4 18 L 4 10 z M 14 11 A 2 2 0 0 0 12 13 A 2 2 0 0 0 14 15 A 2 2 0 0 0 16 13 A 2 2 0 0 0 14 11 z M 7.5 14 A 1.5 1.5 0 0 0 6 15.5 A 1.5 1.5 0 0 0 7.5 17 A 1.5 1.5 0 0 0 9 15.5 A 1.5 1.5 0 0 0 7.5 14 z" />
  </SvgIcon>
)
Cheese = pure(Cheese)
Cheese.displayName = 'Cheese'
Cheese.muiName = 'SvgIcon'

export default Cheese
