import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import differenceWith from 'lodash/differenceWith'
import ApiEndpoints from '../config/ApiEndpoints'
import RestaurantApi from './RestaurantApi'

// load a list of restaurants if needed
//
const loadRestaurants = (props, options, json, rids) => {
  // remove duplicates
  rids = uniqBy(rids, function(id) {
    return id
  })

  // get difference between our rids list and the already loaded restaurants (props.restaurantIds)
  // to keep only the ids of the restaurants to load
  rids = differenceWith(rids, props.restaurantIds, isEqual)

  // there is no restaurant to load, we set default data and call success
  if (isEmpty(rids)) {
    json.restaurants = []
    json.restaurantIds = []
    options.success(json)
    return null
  }

  // we load the restaurants
  const getRestaurants = RestaurantApi.getRestaurants(json, rids)
  return getRestaurants
}

const HistoryApi = {
  //
  // for the followin, the `props` param will contain
  // - restaurantIds: A list that describe the restaurants that we already loaded. We don't want
  // to load theme again.
  //

  getOrders: (userId, props, cursor, limit) => {
    return {
      endpoint: ApiEndpoints.HISTORY_USER_ORDERS,
      type: 'GET',
      query: {
        cursor,
        limit,
        userId,
      },
      then: (options, json) => {
        let rids = json.data.map(order => order.restaurantId)
        return loadRestaurants(props, options, json, rids)
      },
    }
  },

  getBookings: (userId, props, cursor, limit) => {
    return {
      endpoint: ApiEndpoints.HISTORY_USER_BOOKINGS,
      type: 'GET',
      query: {
        cursor,
        limit,
        userId,
      },
      then: (options, json) => {
        // TODO: to be tested booking.restaurantId exists?
        let rids = json.data.map(booking => booking.restaurantId)
        return loadRestaurants(props, options, json, rids)
      },
    }
  },

  getPayments: (userId, props, cursor, limit) => {
    return {
      endpoint: ApiEndpoints.HISTORY_USER_PAYMENTS,
      type: 'GET',
      query: {
        cursor,
        limit,
        userId,
      },
      then: (options, json) => {
        // TODO: to be tested payment.restaurantId exists?
        let rids = json.data.map(payment => payment.restaurantId)
        return loadRestaurants(props, options, json, rids)
      },
    }
  },

  getTopUps: (userId, props, cursor, limit, status) => {
    return {
      endpoint: ApiEndpoints.DEPOSITS,
      type: 'GET',
      query: {
        cursor,
        limit,
        userId,
        status,
      },
      // topup isn't link to a restaurant, no need of `then`
    }
  },

  /**
   * @param data:
   * - userAccountId
   * @param cursor: must be non-null, otherwise the service takes a fromTime / toTime.
   */
  getTransactions: (userId, data, cursor = '', limit = 10) => ({
    endpoint: ApiEndpoints.USER_ACCOUNT_TRANSACTIONS,
    type: 'GET',
    params: {
      userId,
      userAccountId: data.userAccountId,
    },
    query: {
      cursor,
      limit,
    },
    // NOTE:
    // transaction can be optionnaly linked to a restaurant, but this is not the case for now.
    // Should we use a `then` or load from the view? Depends of the UI we would want.
  }),

  getPaymentsUserCheck: (userId, data, cursor, limit = 10) => ({
    endpoint: ApiEndpoints.HISTORY_USER_PAYMENTS,
    type: 'GET',
    query: {
      cursor,
      userId,
      limit,
    },
  }),
}

export default HistoryApi
