import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { withStyles } from 'pmt-ui/styles'
import ButtonUI from 'pmt-ui/Button'

import { filterByKey } from 'pmt-utils/object'

const styles = theme => ({
  root: {
    minHeight: 36,
  },
  variantLink: {
    border: 'none',
    color: theme.palette.primary.main,
    textTransform: 'none', // no uppercase
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      background: 'transparent',
    },
    padding: 0,
  },
  disabled: {
    // remove underline
    textDecoration: 'none',
  },
  label: {
    textTransform: 'initial',
    lineHeight: 1,
  },
  sizeSmall: {
    minHeight: 30,
  },
  sizeLarge: {
    minHeight: 55,
  },
  outlined: {
    borderWidth: 1,

    '&:hover, &:focus': {
      borderWidth: 1,
    },
  },
})

const Button = ({ children, variant, classes, className, disabled, ...otherProps }) => (
  <ButtonUI
    classes={filterByKey(classes, ['variantLink', 'variantText'])}
    className={clsx(className, {
      [classes.variantLink]: variant === 'link',
      [classes.variantText]: variant === 'text',
      // default disabled
      [classes.disabled]: disabled === true,
    })}
    variant={variant}
    disabled={disabled}
    {...otherProps}
  >
    {children}
  </ButtonUI>
)

Button.defaultProps = {
  variant: 'outlined',
  color: 'primary',
  size: 'medium',
}

Button.propTypes = {
  variant: PropTypes.oneOf(['flat', 'outlined', 'text', 'contained', 'link']),
}

export default withStyles(styles)(Button)
