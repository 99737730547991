import React from 'react'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import Typography from 'pmt-ui/Typography'
import Button from 'pmt-ui/Button'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

const styles = theme => ({
  root: {
    position: 'absolute',
    // magic
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      background: theme.pmt.colors.greyBackground,
      padding: '0 10%',
    },
  },
  blockContainer: {
    height: '100%',
    position: 'relative',
  },
  error: {
    width: '100%',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
  red800: {
    color: theme.pmt.colors.red800,
  },
  button: {
    position: 'absolute',
    left: '50%',
    bottom: 24,
    transform: 'translateX(-50%)',
  },
})

class ErrorLayout extends React.PureComponent {
  render() {
    const { error, buttonLabel, buttonAction, classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classNames(classes.error, 'u-textAlignCenter')}>
          <ActionInfoOutline className={classNames(classes.iconInfo, classes.red800)} />
          <Typography align="center" className={classes.red800}>
            {error}
          </Typography>
        </div>
        {buttonAction && (
          <Button
            variant="raised"
            color="primary"
            className={classes.button}
            onClick={buttonAction}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(ErrorLayout)
