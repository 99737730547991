import { tr } from 'pmt-modules/i18n'
import keys from 'lodash/keys'
import map from 'lodash/map'

/**
 * Defines the different possible type to display the history (on the web customer)
 */
export const FrontCateringHistoryType = {
  PAY_MY_TABLE: 'PAY_MY_TABLE',
  THIRD_PARTY: 'THIRD_PARTY',
}

export const GroupType = {
  USER: 'USER',
  OTHER: 'OTHER',
}

export const AcquirerType = {
  MANGOPAY: 'MANGOPAY',
  PAYGREEN: 'PAYGREEN',
  FAKE: 'FAKE',
  NONE: null,
}

export const getAcquirerLabel = acquirer => {
  switch (acquirer) {
    case AcquirerType.MANGOPAY:
      return 'MangoPay'
    case AcquirerType.PAYGREEN:
      return 'PayGreen'
    case AcquirerType.FAKE:
      return 'FAKE'
    default:
      return tr('global.none')
  }
}

export const UserManagerType = {
  NONE: null,
  LDB: 'leon_de_bruxelles',
}

export const getUserManagerTypeLabel = type => {
  switch (type) {
    case UserManagerType.LDB:
      return 'Leon de Bruxelles'
    default:
      return tr('global.none')
  }
}

export const getGroupType = type => GroupType[type]

export const getGroupTypeLabel = type => {
  switch (type) {
    case GroupType.USER:
      return tr('global.groups.type.user')
    case GroupType.OTHER:
      return tr('global.groups.type.other')
    default:
      return ''
  }
}

export const getGroupTypesLabelAsArray = () =>
  keys(GroupType).map(value => ({
    label: getGroupTypeLabel(value),
    value,
  }))

export const UserAccountManagerType = {
  NONE: 'NONE',

  /**
   * Local user account manager
   */
  paymytable: 'paymytable',

  /**
   * Cashpad user account manager
   */
  cashpad: 'cashpad',

  cashpadV2: 'cashpadV2',

  /**
   * POS CSI user account manager. User account manager settings identifier must contain
   * exploitation code + ":" + exploitation key (both values provided by CSI for each site)
   */
  csi: 'csi',
}

/**
 * Enum of locales that are handled by PMT. Only those locales can be defined as used locales on our
 * configurations (such as RestaurantsGroup locales).
 */
export const PMTLocale = {
  // languages without country
  // so we can handle them if the TCPOS configuration gives us PIM item with localizedNames without the country code

  // cf. LocaleFlag.getCountryForLocale to configure the flag to use for those country-less locales
  sq: 'sq',
  ar: 'ar',
  be: 'be',
  bg: 'bg',
  ca: 'ca',
  zh: 'zh',
  hr: 'hr',
  cs: 'cs',
  da: 'da',
  nl: 'nl',
  en: 'en',
  et: 'et',
  fi: 'fi',
  fr: 'fr',
  de: 'de',
  el: 'el',
  iw: 'iw',
  hi: 'hi',
  hu: 'hu',
  is: 'is',
  in: 'in',
  ga: 'ga',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  lv: 'lv',
  lt: 'lt',
  mk: 'mk',
  ms: 'ms',
  mt: 'mt',
  no: 'no',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sr: 'sr',
  sk: 'sk',
  sl: 'sl',
  es: 'es',
  sv: 'sv',
  th: 'th',
  tr: 'tr',
  uk: 'uk',
  vi: 'vi',

  // languages with country code
  sq_AL: 'sq-AL',
  ar_DZ: 'ar-DZ',
  ar_BH: 'ar-BH',
  ar_EG: 'ar-EG',
  ar_IQ: 'ar-IQ',
  ar_JO: 'ar-JO',
  ar_KW: 'ar-KW',
  ar_LB: 'ar-LB',
  ar_LY: 'ar-LY',
  ar_MA: 'ar-MA',
  ar_OM: 'ar-OM',
  ar_QA: 'ar-QA',
  ar_SA: 'ar-SA',
  ar_SD: 'ar-SD',
  ar_SY: 'ar-SY',
  ar_TN: 'ar-TN',
  ar_AE: 'ar-AE',
  ar_YE: 'ar-YE',
  be_BY: 'be-BY',
  bg_BG: 'bg-BG',
  ca_ES: 'ca-ES',
  zh_CN: 'zh-CN',
  zh_SG: 'zh-SG',
  zh_HK: 'zh-HK',
  zh_TW: 'zh-TW',
  hr_HR: 'hr-HR',
  cs_CZ: 'cs-CZ',
  da_DK: 'da-DK',
  nl_BE: 'nl-BE',
  nl_NL: 'nl-NL',
  en_AU: 'en-AU',
  en_CA: 'en-CA',
  en_IN: 'en-IN',
  en_IE: 'en-IE',
  en_MT: 'en-MT',
  en_NZ: 'en-NZ',
  en_PH: 'en-PH',
  en_SG: 'en-SG',
  en_ZA: 'en-ZA',
  en_GB: 'en-GB',
  en_US: 'en-US',
  et_EE: 'et-EE',
  fi_FI: 'fi-FI',
  fr_FR: 'fr-FR',
  fr_BE: 'fr-BE',
  fr_CA: 'fr-CA',
  fr_LU: 'fr-LU',
  fr_CH: 'fr-CH',
  de_AT: 'de-AT',
  de_DE: 'de-DE',
  de_LU: 'de-LU',
  de_CH: 'de-CH',
  el_CY: 'el-CY',
  el_GR: 'el-GR',
  iw_IL: 'iw-IL',
  hi_IN: 'hi-IN',
  hu_HU: 'hu-HU',
  is_IS: 'is-IS',
  in_ID: 'in-ID',
  ga_IE: 'ga-IE',
  it_IT: 'it-IT',
  it_CH: 'it-CH',
  ja_JP: 'ja-JP',
  ko_KR: 'ko-KR',
  lv_LV: 'lv-LV',
  lt_LT: 'lt-LT',
  mk_MK: 'mk-MK',
  ms_MY: 'ms-MY',
  mt_MT: 'mt-MT',
  no_NO: 'no-NO',
  pl_PL: 'pl-PL',
  pt_BR: 'pt-BR',
  pt_PT: 'pt-PT',
  ro_RO: 'ro-RO',
  ru_RU: 'ru-RU',
  sr_BA: 'sr-BA',
  sr_ME: 'sr-ME',
  sr_RS: 'sr-RS',
  sk_SK: 'sk-SK',
  sl_SI: 'sl-SI',
  es_AR: 'es-AR',
  es_BO: 'es-BO',
  es_CL: 'es-CL',
  es_CO: 'es-CO',
  es_CR: 'es-CR',
  es_DO: 'es-DO',
  es_EC: 'es-EC',
  es_SV: 'es-SV',
  es_GT: 'es-GT',
  es_HN: 'es-HN',
  es_MX: 'es-MX',
  es_NI: 'es-NI',
  es_PA: 'es-PA',
  es_PY: 'es-PY',
  es_PE: 'es-PE',
  es_PR: 'es-PR',
  es_ES: 'es-ES',
  es_US: 'es-US',
  es_UY: 'es-UY',
  es_VE: 'es-VE',
  sv_SE: 'sv-SE',
  th_TH: 'th-TH',
  tr_TR: 'tr-TR',
  uk_UA: 'uk-UA',
  vi_VN: 'vi-VN',
}

export const getLocales = () => map(PMTLocale, value => value)

export const PaymentMethodDetail = {
  //
  // GENERIC/DEFAULT VALUES
  //

  /**
   * Default value to be use.
   * <p>
   * In general means that the payment service is still unknown (eg we didn't receive the payment infos yet)
   */
  UNKNOWN: 'UNKNOWN',
  /**
   * For payment method not supported.
   * <p>
   * In general means that the payment service is not known by PMT
   */
  OTHER: 'OTHER',

  //
  // CARDS
  //

  /**
   * Generic card (eg if PSP doesn't support card brand separation like Mangopay)
   */
  CARD: 'CARD',
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  /**
   * MasterCard's Debit only cards
   */
  MAESTRO: 'MAESTRO',
  /**
   * American Express
   */
  AMEX: 'AMEX',
  /**
   * <a href="https://en.wikipedia.org/wiki/Diners_Club_International">Diners Club International</a>
   * AND <a href="https://en.wikipedia.org/wiki/Discover_Card">Discover Card</a>.
   * <p>
   * Note: Those are linked because Saferpay keep them linked.
   */
  DINERS: 'DINERS',
  /**
   * PostFinance Card
   * <p>
   * Note: To see in the future but can be linked with {@link PaymentMethodDetailEnum#POSTFINANCE}
   */
  POSTCARD: 'POSTCARD',
  /**
   * <a href="https://en.wikipedia.org/wiki/JCB_Co.,_Ltd">Japan Credit Bureau</a>.
   */
  JCB: 'JCB',
  /**
   * MyOne is a local Swiss payment card
   */
  MYONE: 'MYONE',
  /**
   * <a href="https://en.wikipedia.org/wiki/UnionPay">UnionPay</a>, China
   */
  UNIONPAY: 'UNIONPAY',

  //
  // ONLINE PAYMENT SERVICES
  //

  PAYPAL: 'PAYPAL',
  /**
   * <a href="https://www.twint.ch/en/">TWINT</a>,Switzerland’s payment app (also a bit present in Germany and France)
   */
  TWINT: 'TWINT',
  /**
   * <a href="https://en.wikipedia.org/wiki/Klarna">Klarna</a> |
   * <a href="https://docs.saferpay.com/home/integration-guide/payment-methods/klarna-payments">Klarna Payments</a>
   */
  KLARNA: 'KLARNA',
  /**
   * <a href="https://en.wikipedia.org/wiki/Alipay">Alipay</a>
   */
  ALIPAY: 'ALIPAY',
  /**
   * <a href="https://fr.wikipedia.org/wiki/Bancontact">Bancontact</a>, Belgium
   */
  BANCONTACT: 'BANCONTACT',
  /**
   * <a href="https://docs.connect.worldline-solutions.com/payment-product/ePrzelewy/overview">ePrzelewy</a>, Poland
   */
  EPRZELEWY: 'EPRZELEWY',
  /**
   * <a href="https://en.wikipedia.org/wiki/Giropay">Giropay</a>, Germany
   */
  GIROPAY: 'GIROPAY',
  /**
   * <a href="https://en.wikipedia.org/wiki/IDEAL">IDEAL</a>, Netherland
   */
  IDEAL: 'IDEAL',
  /**
   * PostFinance eFinance
   * <p>
   * Note: To see in the future but can be linked with {@link PaymentMethodDetailEnum#POSTCARD}
   */
  POSTFINANCE: 'POSTFINANCE',

  //
  // OTHER
  //

  TRD: 'TRD',
  /**
   * SEPA
   */
  DIRECTDEBIT: 'DIRECTDEBIT',
  /**
   * <a href="https://stripe.com/fr/legal/eps">Electronic Payment Standard</a>, Austria
   */
  EPS: 'EPS',
}

export const getPaymentMethodDetailLabel = paymentMethodDetail => {
  switch (paymentMethodDetail) {
    case PaymentMethodDetail.TRD:
      return tr('global.payment.method.detail.TRD')
    case PaymentMethodDetail.CARD:
      return tr('global.payment.method.detail.CARD')
    case PaymentMethodDetail.OTHER:
      return tr('global.payment.method.detail.OTHER')
    case PaymentMethodDetail.UNKNOWN:
      return tr('global.payment.method.detail.UNKNOWN')
    default:
      return paymentMethodDetail
  }
}
