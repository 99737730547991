/**
 * Enum of different existing forms.
 * The StatsType is used to save / get data on our form reducer
 */
const StatisticsGroupType = {
  ORDER: 'STATISTICS::ORDER',
  TOP_UP: 'STATISTICS::TOP_UP',
}

export default StatisticsGroupType
