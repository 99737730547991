import {
  createApiCallAction,
  //createTestApiCallAction,
  createApiEnumAction,
} from '../api/utils'

import ApiConsumerApi from 'pmt-modules/api/calls/ApiConsumerApi'

export const GetApiConsumerListAction = createApiEnumAction('API_CONSUMER_LIST::GET')

export const fetchApiConsumerList = (rGroupId, query) =>
  createApiCallAction(GetApiConsumerListAction, ApiConsumerApi.getApiConsumerList(rGroupId, query))

export const fetchApiConsumerListFromRestaurant = restaurantId =>
  createApiCallAction(
    GetApiConsumerListAction,
    ApiConsumerApi.getApiConsumerListFromRestaurant(restaurantId)
  )

//
//
//

export const GetApiConsumerAction = createApiEnumAction('API_CONSUMER::GET')

export const fetchApiConsumer = apiConsumerId =>
  createApiCallAction(GetApiConsumerAction, ApiConsumerApi.getApiConsumer(apiConsumerId))

// export const fetchApiConsumer = apiConsumerId =>
//   createTestApiCallAction(GetApiConsumerAction, true, {
//     response: {
//       id: apiConsumerId,
//       name: 'Test api consumer',
//       description: 'An api consumer for tests',
//     },
//   })
