import Immutable from 'immutable'
import concat from 'lodash/concat'
import uniqWith from 'lodash/uniqWith'
import { HistoryType } from './constants'

import { GetHistoryAction } from './actions'

export * from './actions'
export * from './selectors'
export * from './constants'
export * from './components'

const DEFAULT = Immutable.fromJS({
  // Example:
  // [userId]: {
  //  [HistoryType]:
  //    data: null,
  //    pagination: [],
  //    isFetching: false,
  //    lastUpdated: null,
  //    error: null
  //  }
  // }
  //
})

export const userHistoryReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case GetHistoryAction.REQUEST:
      return state.mergeIn([action.data.userId, action.data.historyType], {
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetHistoryAction.SUCCESS:
      let newHistory = []

      /**
       * A null before cursor means we ran the action to load the first
       * data page. In this case, we don't merge the data,
       * we reset it.
       */
      if (action.response.paging.cursors.before === null) {
        newHistory = action.response.data
      } else {
        let actualHistory = state.getIn([action.data.userId, action.data.historyType, 'data'])

        let getIdentifier
        if (action.data.historyType === HistoryType.TRANSACTIONS) {
          // there is no id on the transaction, but a checkPosId
          getIdentifier = a => a.checkPosId
        } else {
          getIdentifier = a => a.id
        }

        if (typeof actualHistory !== 'undefined') {
          actualHistory = actualHistory
            .valueSeq()
            .toArray()
            .map(item => {
              return item.toJS()
            })
        } else {
          actualHistory = []
        }

        newHistory = concat(actualHistory, action.response.data)
        newHistory = uniqWith(newHistory, (a, b) => getIdentifier(a) === getIdentifier(b))
      }

      return state
        .mergeIn([action.data.userId, action.data.historyType], {
          isFetching: false,
          lastUpdated: new Date(),
          error: null,
        })
        .mergeIn(
          [action.data.userId, action.data.historyType, 'pagination'],
          action.response.paging
        )
        .setIn([action.data.userId, action.data.historyType, 'data'], Immutable.fromJS(newHistory))

    case GetHistoryAction.FAILURE:
      return state.mergeIn([action.data.userId, action.data.historyType], {
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    default:
      return state
  }
}
