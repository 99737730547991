import isNil from 'lodash/isNil'
import { formatUser, formatUserList } from './format'
import { createSelector } from 'reselect'

import { getAvailableEntity, createSimpleSelector } from '../redux'

const getUser = state => state.entities.user
const getUserIdOnProps = (state, props) => props.userId

export const isFetchingUser = createSelector([getUser], userData => {
  const user = userData.getIn(['entity', 'data'])
  if (isNil(user)) {
    return false
  }

  return userData.getIn(['entity', 'isFetching'])
})

export const makeGetUser = () =>
  createSelector([getUser, getUserIdOnProps], (user, userIdSelected) => {
    if (isNil(user)) {
      return null
    }

    const userEntity = user.get('entity')
    const userList = user.get('list')
    const entity = getAvailableEntity(userEntity, userList, 'lastUpdated', userIdSelected)

    if (!entity) {
      return null
    }

    return formatUser(entity)
  })

export const getUserError = createSelector([getUser], userData => {
  const error = userData.getIn(['entity', 'error'])
  if (isNil(error)) {
    return null
  }

  return error.toJS()
})

export const isFetchingUserList = createSelector([getUser], user => {
  const userList = user.get('list')
  if (isNil(userList)) {
    return false
  }

  return userList.get('isFetching')
})

export const getUserList = createSelector([getUser], user => {
  const userListData = user.getIn(['list', 'data'])
  if (isNil(userListData)) {
    return [] // TODO: should return null, we didn't load data yet.
  }

  return formatUserList(userListData.toJS())
})

export const makeGetUserListPagination = () =>
  createSelector([getUser], user => {
    if (isNil(user)) {
      return null
    }

    const pagination = user.getIn(['list', 'pagination'])

    if (!pagination) {
      return null
    }

    return pagination.toJS()
  })

//
// Update reducers
//

const updateUserSelectors = createSimpleSelector(state => state.entities.updateUser)

export const isFetchingUpdateUser = updateUserSelectors.isFetching
export const getUpdateUserError = updateUserSelectors.getError
export const getUpdateUserData = updateUserSelectors.getData
