import OrderApi from 'pmt-modules/api/calls/OrderApi'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import createAction from 'pmt-modules/redux/createAction'

export const FetchRestaurantOnGoingOrderListAction = createApiEnumAction(
  'RESTAURANT::ORDER::ON_GOING::LIST'
)

export const fetchRestaurantOnGoingOrderList = restaurantId =>
  createApiCallAction(
    FetchRestaurantOnGoingOrderListAction,
    OrderApi.getRestaurantOnGoing(restaurantId)
  )

export const OnGoingOrderExtraActions = {
  REFRESH: 'RESTAURANT::ORDER::ON_GOING::REFRESH',
}

/**
 * Update a new timestamp in onGoingExtra reducer.
 * This will be used to refresh getRestaurantOnGoingOrdersUpdated selector
 * in order to refresh order color and remaining time
 */
export const refreshOnGoingOrderList = () =>
  createAction(OnGoingOrderExtraActions.REFRESH, {
    date: Date.now(),
  })
