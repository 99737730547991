import Immutable from 'immutable'
import { combineReducers } from 'redux'
import { createReducer, createSimpleReducer } from 'pmt-modules/redux'

import { FetchEmailTemplateData } from './actions'

import {
  FetchCustomTextsContainerAction,
  PutCustomTextsContainerAction,
  PostCustomTextsAction,
} from './actions'

export * from './actions'
export * from './constants'
export * from './selectors'

//
//
//

const DEFAULT = Immutable.fromJS({
  data: null,
  lastUpdated: null,
  isFetching: false,
  error: null,
})

//
//
//

const onRequestCustomTextsContainer = (state, action) =>
  state.merge({
    data: null,
    actionData: action.data,
    isFetching: true,
    lastUpdated: null,
    error: null,
  })

const onSuccessCustomTextsContainer = (state, action) =>
  state.merge({
    data: action.response,
    isFetching: false,
    lastUpdated: new Date(),
    error: null,
  })

const onFailureCustomTextsContainer = (state, action) =>
  state.merge({
    data: null,
    isFetching: false,
    lastUpdated: new Date(),
    error: action.error,
  })

const onRequestPutCustomTextsContainer = (state, action) =>
  state.merge({
    data: null,
    isFetching: true,
    lastUpdated: null,
    error: null,
  })

const onSuccessPutCustomTextsContainer = (state, action) =>
  state.merge({
    data: action.response,
    isFetching: false,
    lastUpdated: new Date(),
    error: null,
  })

const onFailurePutCustomTextsContainer = (state, action) =>
  state.merge({
    data: null,
    isFetching: false,
    lastUpdated: new Date(),
    error: action.error,
  })

const customTextsContainerReducer = createReducer(DEFAULT, {
  [FetchCustomTextsContainerAction.REQUEST]: onRequestCustomTextsContainer,
  [FetchCustomTextsContainerAction.FAILURE]: onFailureCustomTextsContainer,
  [FetchCustomTextsContainerAction.SUCCESS]: onSuccessCustomTextsContainer,
  [PostCustomTextsAction.SUCCESS]: onSuccessCustomTextsContainer,
  [PutCustomTextsContainerAction.REQUEST]: onRequestPutCustomTextsContainer,
  [PutCustomTextsContainerAction.FAILURE]: onFailurePutCustomTextsContainer,
  [PutCustomTextsContainerAction.SUCCESS]: onSuccessPutCustomTextsContainer,
})

//
//
//

const restaurantsGroupEmailTemplateDataReducer = createSimpleReducer(FetchEmailTemplateData)

const createCustomTextsContainerReducer = createSimpleReducer(PostCustomTextsAction)
//
//
//

export const templateReducer = combineReducers({
  customTextsContainer: customTextsContainerReducer,
  createCustomTextsContainer: createCustomTextsContainerReducer,
  restaurantsGroupEmailTemplateData: restaurantsGroupEmailTemplateDataReducer,
})
