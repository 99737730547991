import React from 'react'

import ReactJson from 'react-json-view'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
})

const JsonView = ({ src, classes, ...props }) => (
  <ReactJson
    src={src}
    theme="monokai"
    displayDataTypes={false}
    className={classes.root}
    {...props}
  />
)

export default withStyles(styles)(JsonView)
