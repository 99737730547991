import React from 'react'
import PropTypes from 'prop-types'

import { compose } from 'redux'
import { connect } from 'react-redux'

import invariant from 'invariant'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNull from 'lodash/isNull'

import { Security } from 'pmt-modules/bo'

import { __DEV__ } from 'pmt-modules/environment'

import {
  getRoute,
  redirectTo,
  updatePageQueries,
  replaceWith,
  goBack,
  reloadTo,
  openExternalLink,
  redirectToExternal,
  getRoutingEnum,
} from 'pmt-modules/routing'
import { RoutingContext } from './bo.context'

import {
  getSelectedDomain,
  getSelectedRestaurantsGroup,
  loadDefaultDomain,
  redirectToPermissionsErrorPage,
} from 'pmt-modules/bo'

import AppSecurityContainer from 'pmt-modules/appSecurity/components/AppSecurityContainer'

import { isLoggedIn } from 'pmt-modules/authPro'
import { GroupType } from 'pmt-modules/restaurantsGroup'
import { getConfigForRoute } from 'pmt-modules/routing'

import LoadingPage from 'pmt-ui/LoadingPage'
import { withStyles } from 'pmt-ui/styles'
import withWidth from 'pmt-ui/utils/withWidth'
import FlashMessages from './FlashMessages'
import Header from './Header'
import PageContent from './PageContent'
import PageContentFull from './PageContentFull'
import Sidebar from './Sidebar'
import { SidebarType } from './Sidebar/constants'
import ErrorLayout from '../ErrorLayout'

const getRouteConfig = ({ route }) => getConfigForRoute(route)

const showSideBarBreakpoints = ['lg', 'xl']

const styles = theme => ({
  root: {
    width: '100%',
    height: '100% !important',
    zIndex: 1,
    overflow: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
})

// TODO: user redux-ui for state
class BackofficePage extends React.Component {
  constructor(props) {
    super(props)

    const { route, isLoggedIn, redirectTo, selectedDomain, loadDefaultDomain } = props

    // we need to clone the route config since we change data on it (ariane template string, etc)
    const routeConfig = cloneDeep(getRouteConfig(props))
    // attach the route config object
    this.routeConfig = routeConfig

    if (__DEV__) {
      invariant(!isNil(route), 'route prop is mandatory on BackofficePage')
      invariant(!isNil(routeConfig), `routeConfig not defined for route ${route.name}`)
    }

    this.authRequired = routeConfig.props.authRequired

    // verify we are authorized, otherwise redirect to login
    if (this.authRequired && !isLoggedIn) {
      redirectTo(getRoute('LOGIN'))
    } else {
      if (this.authRequired && isLoggedIn) {
        if (
          !selectedDomain &&
          routeConfig.definition.name !== getRoute('BO_SELECT_PRO_DOMAINS').name &&
          routeConfig.definition.name !== getRoute('BO_SELECT_RGROUP').name
        ) {
          // no domain selected
          loadDefaultDomain()
        }
      }
    }

    // route has permissions defined
    // we check for the permissions of the page
    if (!isEmpty(this.routeConfig.props.permissions) && props.selectedDomain !== null) {
      if (!Security.assert(this.routeConfig.props.permissions)) {
        // not enough permissions.
        this.props.redirectToPermissionsErrorPage(this.routeConfig.props.permissions)
      }
    }

    this.state = {
      sidebarIsOpen: showSideBarBreakpoints.indexOf(props.width) !== -1,

      // true if we are loading required data such as domain and restaurant group
      forceLoading: this.shouldForceLoading(props),
    }
  }

  componentWillReceiveProps(nextProps) {
    const forceLoading = this.shouldForceLoading(nextProps)

    // route has permissions defined
    // we check for the permissions of the page
    if (
      !isEmpty(this.routeConfig.props.permissions) &&
      !forceLoading &&
      nextProps.selectedDomain !== null
    ) {
      if (!Security.assert(this.routeConfig.props.permissions)) {
        // not enough permissions.
        this.props.redirectToPermissionsErrorPage(this.routeConfig.props.permissions)
      }
    }

    if (
      this.routeConfig.props.isForGroupUserOnly &&
      nextProps.selectedRGroup !== null &&
      nextProps.selectedRGroup.type !== GroupType.USER
    ) {
      console.info(`route isForGroupUserOnly`)
      this.props.redirectTo(getRoute('HOME'))
    }

    if (
      this.state.forceLoading &&
      nextProps.selectedRGroup !== null &&
      nextProps.selectedDomain !== null
    ) {
      // we retrieved the default data (rGroup and domain)
      // we update the current url to add the `rGroupId` and `domainId`
      updatePageQueries({
        rGroupId: nextProps.selectedRGroup.id,
        domain: nextProps.selectedDomain.id,
      })
    }

    this.setState({
      forceLoading,
    })
  }

  /**
   * Define if we should force-display a loading, in the case we don't have the data required for
   * a backoffice page (domain & restaurants group)
   */
  shouldForceLoading(props) {
    if (!this.authRequired) {
      return false
    }

    const { selectedDomain, selectedRGroup } = props

    // if there is no domain selected / loaded, we must force loading
    if (
      (isNull(selectedDomain) || isNull(selectedRGroup)) &&
      this.routeConfig.definition.name !== getRoute('BO_SELECT_PRO_DOMAINS').name &&
      this.routeConfig.definition.name !== getRoute('BO_SELECT_RGROUP').name
    ) {
      // no domain selected
      return true
    }

    return false
  }

  handleSidebarToggle = () => {
    this.setState({ sidebarIsOpen: !this.state.sidebarIsOpen })
  }

  renderChildren() {
    const { children } = this.props

    if (isFunction(children)) {
      return children({
        isFetchingRestaurantsGroup: this.props.selectedRGroup === null, // TODO: change?
        restaurantsGroup: this.props.selectedRGroup,
        domain: this.props.selectedDomain,
      })
    }

    return children
  }

  render() {
    const {
      loading,
      isLoggedIn,
      redirectTo,
      arianeProps,
      pageTitle,
      smallTitleArea,
      bigActionArea,
      pageSubtitle,
      pageAction,
      classes,
      selectedRGroup, // TODO: refactor
      // depreacated in favor of tabsConfig
      tabs,
    } = this.props

    const tabsConfig = this.props.tabsConfig || tabs

    if (loading || this.state.forceLoading) {
      return <LoadingPage show />
    }
    // we modify the object below so we impact template vars while loading datas,
    // we need to cloneDeep
    const routeConfig = cloneDeep(this.routeConfig)
    // const routeConfig = this.routeConfig)

    let sidebarIsOpen = this.state.sidebarIsOpen

    let sideBarType = null
    if (showSideBarBreakpoints.indexOf(this.props.width) !== -1) {
      sidebarIsOpen = true
      sideBarType = SidebarType.PERMANENT
    } else {
      sideBarType = SidebarType.PERSISTENT
    }

    //
    // render
    //

    // handle special layout for non-logged case
    if (!isLoggedIn) {
      return <PageContentFull classes={classes}>{this.renderChildren()}</PageContentFull>
    }

    return (
      <AppSecurityContainer>
        {({ appSecurityError }) =>
          appSecurityError ? (
            <ErrorLayout error={appSecurityError.localizedMessage} />
          ) : (
            <div className={classes.root}>
              <div className={classes.appFrame}>
                <Header
                  sidebarIsOpen={sidebarIsOpen}
                  sideBarType={sideBarType}
                  onSidebarToggle={this.handleSidebarToggle}
                  isLoggedIn={isLoggedIn}
                />

                <FlashMessages />

                <Sidebar
                  routeConfig={routeConfig}
                  isOpen={sidebarIsOpen}
                  type={sideBarType}
                  isLoggedIn={isLoggedIn}
                  selectedRGroup={selectedRGroup}
                />

                <RoutingContext.Provider
                  value={{
                    redirectTo,
                    getRoute,
                    replaceWith,
                    goBack,
                    reloadTo,
                    openExternalLink,
                    redirectToExternal,
                    updatePageQueries,
                    getRoutingEnum,
                  }}
                >
                  <PageContent
                    routeConfig={routeConfig}
                    sidebarIsOpen={sidebarIsOpen}
                    sidebarType={sideBarType}
                    arianeProps={arianeProps}
                    redirectTo={redirectTo}
                    pageTitle={pageTitle}
                    smallTitleArea={smallTitleArea}
                    bigActionArea={bigActionArea}
                    pageSubtitle={pageSubtitle}
                    pageAction={pageAction}
                    tabsConfig={tabsConfig}
                  >
                    {this.renderChildren()}
                  </PageContent>
                </RoutingContext.Provider>
              </div>
            </div>
          )
        }
      </AppSecurityContainer>
    )
  }
}

BackofficePage.defaultProps = {
  arianeProps: {},
}

BackofficePage.propTypes = {
  /**
   * if true, display a loading for the whole page
   */
  loading: PropTypes.bool,

  /**
   *
   * - values:
   *    An object than contains the strings to replace on the route's:
   *    - ariane titles
   *    - the page headerTitle
   *
   * - routes:
   *    An object that adds additionnal configuration for the routes, such as url query and params
   *    The index are the RoutingEnum value.
   *    it can contains:
   *    - query: the query to add to the route link
   *    - params: the params to set on the route link
   *
   * The vars are defined on the config/routes
   *
   * ```
   * {
   *   templateValues: {
   *    varToReplace: 'Login Page',
   *   },
   *   routes: {
   *    [getRoute('LOGIN').name]: {
   *      query: {},
   *      params: {},
   *    }
   *   }
   * }
   * ```
   */
  arianeProps: PropTypes.object.isRequired,

  /**
   * The props.route of the page
   */
  route: PropTypes.object.isRequired,

  /**
   * The page content Component.
   * the view to display when not loading
   *
   * Can be a function that will received:
   * - isFetchingRestaurantsGroup: boolean
   * - restaurantsGroup: the selectedRGroup
   * - domain: the selected domain
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

  /**
   * Title of the page, to display in the content
   */
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  /**
   * Use a smaller margin bottom for the page title
   */
  smallTitleArea: PropTypes.bool,

  /**
   * Title of the page, to display in the content, under the pageTitle
   */
  pageSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),

  /**
   * Area on the right of the pageTitle / pageSubtitle, mostly use to display action buttons
   */
  pageAction: PropTypes.any,
}

const mapStateToProps = (state, props) => ({
  isLoggedIn: isLoggedIn(state),
  selectedDomain: getSelectedDomain(state),
  selectedRGroup: getSelectedRestaurantsGroup(state),
})

export default compose(
  connect(mapStateToProps, {
    redirectTo,
    updatePageQueries,
    loadDefaultDomain,
    redirectToPermissionsErrorPage,
    replaceWith,
    reloadTo,
    goBack,
    openExternalLink,
    redirectToExternal,
  }),
  withStyles(styles),
  withWidth()
)(BackofficePage)
