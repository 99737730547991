import { createSelectorsPerObject } from 'pmt-modules/redux'
import { formatDocumentsListStatus } from './format'

const getRestaurantDocumentsStatusSelector = createSelectorsPerObject(
  state => state.entities.restaurantDocumentsStatus,
  (state, props) => props.restaurantId,
  formatDocumentsListStatus
)

export const makeIsFetchingRestaurantDocumentsStatus =
  getRestaurantDocumentsStatusSelector.makeIsFetching
export const makeGetRestaurantDocumentsStatus = getRestaurantDocumentsStatusSelector.makeGetData
export const makeGetRestaurantDocumentsStatusError =
  getRestaurantDocumentsStatusSelector.makeGetError
