import Immutable from 'immutable'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

import { UPLOADED_FILES, FileUploadType } from 'pmt-modules/upload'

import {
  ImportUserAction,
  IMPORT_USER_NEW_IMPORT_FLOW,
} from './actions'

// import { getImportUserReportFixture } from '../api/fixtures/importUser'

export * from './actions'
export * from './selectors'
export * from './constants'

const DEFAULT = Immutable.fromJS({
  // BEGIN FIXTURE:
  // upload: {
  //   files: {
  //     '-Kv4Cfiyi9w0UuzHnaPt': {
  //       downloadURL: 'https://firebasestorage.googleapis.com/v0/b/paymytabledev-firebase-com/o/upload%2Frgroup%2F5750345418407936%2Fimport%2Fuser%2F2017.09.26a%CC%80%2018.03.xlsx?alt=media&token=0e600cfd-0e3c-42dd-b762-e828665d02f6',
  //       fullPath: 'upload/rgroup/5750345418407936/import/user/2017.09.26à 18.03.xlsx',
  //       name: '2017.09.26à 18.03.xlsx'
  //     }
  //   }
  // },
  // 'import': {
  //   isFetching: false,
  //   data: getImportUserReportFixture(),
  //   error: null
  // }
  // END FIXTURE:

  upload: {
    files: null,
  },
  import: {
    isFetching: false,
    data: null,
    uploadType: null, // UploadType
    error: null,
  }
})

export const importUserReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case UPLOADED_FILES:
      if (action.uploadType === FileUploadType.BO_IMPORT_USER) {
        const updateForUploadedFiles = (state, action) => {
          let finalState = state
          const files = action.files

          // there is no more files, so we reset the data previoulsy imported.
          if (isEmpty(files)) {
            finalState = finalState.mergeIn(['import' ], {
              data: null,
            })
          }
          finalState = finalState.setIn([ 'upload' ], {
            files: files,
          })

          return finalState
        }
        return updateForUploadedFiles(state, action)
      }
      return state

    case ImportUserAction.REQUEST:
      return state.mergeIn(['import' ], {
        isFetching: true,
        data: null,
        uploadType: action.data.uploadType,
        error: null,
      })

    case ImportUserAction.SUCCESS:
      return state.mergeIn(['import' ], {
        isFetching: false,
        data: action.response,
        error: null,
      })

    // in case of failure, we have an api error or a 401 with the error report detail
    case ImportUserAction.FAILURE:
      return state.mergeIn(['import' ], {
        isFetching: false,
        // if there is a code, we have an api error object, otherwise an error report
        data: !isUndefined(action.error.code) ? null : action.error.body,
        error: !isUndefined(action.error.code) ? action.error : null,
      })

    case IMPORT_USER_NEW_IMPORT_FLOW:
      const reset = (state) => {
        let finalState = state.mergeIn(['import'], {
          isFetching: false,
          data: null,
          uploadType: null,
          error: null,
        })

        finalState = finalState.mergeIn(['upload'], {
          files: null,
        })

        return finalState
      }
      return reset(state)

    default:
      return state
  }
}
