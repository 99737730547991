import React from 'react'
import Button from '@material-ui/core/Button'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

const ButtonLoading = ({ component = <Button />, size = 40, isLoading, children, ...otherProps }) =>
  React.cloneElement(component, {
    children: (
      <React.Fragment>
        <LoadingBlockWrapper show={isLoading || false} size={size} />
        {children}
      </React.Fragment>
    ),
    ...otherProps,
  })

export default ButtonLoading
