import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import ProNotificationSettingApi from 'pmt-modules/api/calls/ProNotificationSettingApi'

export const GetProNotificationsIdentitiesAction = createApiEnumAction(
  'PRO_NOTIFICATION::IDENTITIES::GET'
)
export const GetProNotificationsAction = createApiEnumAction('PRO_NOTIFICATION::SETTINGS::GET')
export const GetProNotificationSettingAction = createApiEnumAction('PRO_NOTIFICATION::SETTING::GET')
export const PostProNotificationsAction = createApiEnumAction('PRO_NOTIFICATION::SETTINGS::POST')
export const PatchProNotificationSettingAction = createApiEnumAction(
  'PRO_NOTIFICATION::SETTINGS::PATCH'
)

export const fetchProNotificationsIdentities = () =>
  createApiCallAction(
    GetProNotificationsIdentitiesAction,
    ProNotificationSettingApi.getIdentities(),
    {}
  )

export const fetchProNotifications = restaurantsGroupId =>
  createApiCallAction(
    GetProNotificationsAction,
    ProNotificationSettingApi.getProNotifications(restaurantsGroupId),
    {
      restaurantsGroupId,
    }
  )

export const postProNotifications = proNotification =>
  createApiCallAction(PostProNotificationsAction, ProNotificationSettingApi.post(proNotification), {
    proNotification,
  })

export const fetchProNotificationSetting = proNotificationSettingId =>
  createApiCallAction(
    GetProNotificationSettingAction,
    ProNotificationSettingApi.getProNotification(proNotificationSettingId),
    {
      proNotificationSettingId,
    }
  )

export const patchProNotificationSetting = (
  proNotificationSettingId,
  patchData,
  currentConfiguration,
  updatedConfiguration
) =>
  createApiCallAction(
    PatchProNotificationSettingAction,
    ProNotificationSettingApi.patch(proNotificationSettingId, patchData),
    {
      proNotificationSettingId,
      patchData,
      currentConfiguration,
      updatedConfiguration,
    }
  )
