import React from 'react'
import ReactDOM from 'react-dom'

import ApplicationView from './ApplicationView'

//
// get the rootElement
//
const Application = appOptions => {
  const rootElement = appOptions.rootElement || document.getElementById('root')
  ReactDOM.render(<ApplicationView appOptions={appOptions} />, rootElement)
}

export default Application
