import { tr } from 'pmt-modules/i18n'
import React from 'react'

import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

import { withStyles } from 'pmt-ui/styles'

import TextFieldCustom from 'pmt-ui/TextFieldCustom'
import Chip from 'pmt-ui/Chip'
import { InputLabel } from 'pmt-ui/Input'
import { FormControl } from 'pmt-ui/Form'
import Button from 'pmt-ui/Button'
import clsx from 'clsx'

const styles = theme => ({
  multipleViewRoot: {
    width: '100%',
  },
  chipList: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: theme.spacing(3),
  },
  chipListWithTitle: {
    padding: '6px 24px 7px 0',
    marginTop: theme.spacing(2),
  },
  chipListNotEmpty: {
    cursor: 'pointer',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  label: {
    width: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  value: {
    '& input': {
      cursor: 'pointer',
    },
  },
})

const MultipleView = ({
  label,
  isFetching, // TODO: handle isFetching
  helperText, // TODO: handle helperText
  disabled,
  required,
  valueIconFormatter,
  valueFormatter,
  values,
  classes,
  onDeleteValue,
  onOpen,
  emptyRender,
  alwaysDisplayAddButton,
}) => (
  <FormControl className={classes.multipleViewRoot}>
    {!isEmpty(label) && (
      <InputLabel className={classes.label} disabled={disabled}>
        {label}
        {/* handle required field */}
        {required && <span>&thinsp;*</span>}
      </InputLabel>
    )}
    {!isEmpty(values) ? (
      <div
        className={clsx(classes.chipList, classes.chipListNotEmpty, {
          [classes.chipListWithTitle]: !isEmpty(label),
        })}
        onClick={onOpen}
      >
        {values.map((value, index) => (
          <Chip
            disabled={disabled}
            className={classes.chip}
            icon={!isNull(valueIconFormatter) ? valueIconFormatter(value) : null}
            key={`${value}_${index}`}
            label={valueFormatter(value)}
            onDelete={() => !disabled && onDeleteValue(value)}
          />
        ))}
        {alwaysDisplayAddButton && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onOpen}
            className={classes.chip}
            disabled={disabled}
          >
            {tr('global.add')}
          </Button>
        )}
      </div>
    ) : (
      <div
        className={clsx(classes.chipList, {
          [classes.chipListWithTitle]: !isEmpty(label),
        })}
      >
        {emptyRender}
      </div>
    )}
  </FormControl>
)

/**
 * Default view to display for the value
 * if not specified, we display:
 * - a TextField is there is only one value possible
 * - a list of chips (https://material-ui-1dab0.firebaseapp.com/demos/chips/) if there is multiple
 *   values possible
 */
const ValueView = ({
  label,
  required,
  disabled,
  clearable,
  value,
  multiple,
  valueIconFormatter = null,
  valueFormatter = null,
  valueFormatterHandleNullValue = false,
  classes,
  helperText,
  isFetching,
  onDeleteValue,
  onOpen,
  emptyRender,
  alwaysDisplayAddButton,
}) => {
  if (multiple) {
    return (
      <MultipleView
        label={label}
        required={required}
        isFetching={isFetching}
        disabled={disabled}
        classes={classes}
        valueIconFormatter={valueIconFormatter}
        valueFormatter={valueFormatter}
        values={value}
        onDeleteValue={onDeleteValue}
        onOpen={onOpen}
        helperText={helperText}
        emptyRender={emptyRender}
        alwaysDisplayAddButton={alwaysDisplayAddButton}
      />
    )
  } else {
    // Note: TextFieldCustom does not handle null values, we need to pass an empty value to clear
    // the field
    const formattedValue =
      !isNull(valueFormatter) && (!isNull(value) || valueFormatterHandleNullValue)
        ? valueFormatter(value)
        : value || ''

    if (isString(formattedValue)) {
      return (
        <TextFieldCustom
          className={clsx('u-sizeFullWidth', classes.value)}
          label={isFetching ? tr('global.loading') : label}
          fullWidth
          value={formattedValue}
          required={required}
          disabled={disabled}
          onClick={onOpen}
          onSuffixClick={onDeleteValue}
          onChange={null}
          helperText={helperText}
          suffixType={clearable ? TextFieldCustom.SuffixType.CLEAR : null}
        />
      )
    } else {
      return (
        <div
          className="u-sizeFullWidth"
          style={{ display: 'inline-block', cursor: 'pointer' }}
          onClick={onOpen}
        >
          {formattedValue}
        </div>
      )
    }
  }
}

export default withStyles(styles)(ValueView)
