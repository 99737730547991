import invariant from 'invariant'

import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'


/**
 *
 * @param  {SearchType} type          the SearchType
 * @param  {Function} apiCallFunc     the function that gives the API call configuration
 * @param  {Object} options         an object of optional options
 *                                    - formatter: format list data / cf. createListFormatter
 *
 * @return {[type]}                 the search:
 *                                  {
 *                                    type, // the SearchType
 *                                    apiCallFunc, // the function that gives the API call configuration
 *                                    options, // a function that returns the default values
 *                                  }
 */
const createSearch = (type, apiCallFunc, options = {formatter: null}) => {

  invariant(!isNil(type), 'search type is required')
  invariant(isFunction(apiCallFunc), 'apiCallFunc must be a function')

  return {
    type,
    apiCallFunc,
    options,
  }
}

export default createSearch
