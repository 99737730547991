const postPutProRole = {
  // name required
  109: 'global.error.pro_role.update.109',
  // name already taken on restaurants group
  102: 'global.error.pro_role.update.102',
}

const ProRoleErrors = {
  putProRole: {
    // can't remove visibility for a group that is used by a domain
    301: 'global.error.pro_role.put.301',
    ...postPutProRole,
  },
  postProRole: {
    ...postPutProRole,
  },
}

export default ProRoleErrors
