import React from 'react'

import { connect } from 'react-redux'

import { getSelectedRestaurantsGroup, getSelectedDomain } from 'pmt-modules/bo'

import { isLoggedIn } from 'pmt-modules/authPro'

import { getRoute } from 'pmt-modules/routing'
import { removeUrlParameter } from 'pmt-utils/url'

import ButtonLink from 'pmt-ui/ButtonLink'
import Typography from 'pmt-ui/Typography'

const SelectedDomain = ({ domain, classes }) =>
  domain === null ? null : (
    <Typography variant="body2" component="span" className={classes.menuItem}>
      {domain.role.name}
    </Typography>
  )

const RGroup = ({ rGroup, classes }) =>
  rGroup === null ? null : (
    <Typography variant="body2" component="span" className={classes.menuItem}>
      {rGroup.name}
    </Typography>
  )

const Domain = ({
  classes,
  isLoggedIn,
  selectedRGroup = null,
  selectedDomain = null,
  redirectTo,
}) =>
  !isLoggedIn ? null : (
    <ButtonLink
      className="u-flexCenter u-cursorPointer"
      to={getRoute('BO_SELECT_PRO_DOMAINS')}
      query={{
        redirectTo: removeUrlParameter(window.location.href, ['rGroupId', 'domain']),
      }}
    >
      <SelectedDomain domain={selectedDomain} classes={classes} />
      {selectedDomain &&
        selectedRGroup && (
          <Typography
            variant="body2"
            style={{
              marginLeft: 6,
              marginRight: 6,
            }}
            className={classes.menuItem}
          >
            -
          </Typography>
        )}
      <RGroup rGroup={selectedRGroup} classes={classes} />
    </ButtonLink>
  )

const mapStateToProps = (state, props) => ({
  isLoggedIn: isLoggedIn(state),
  selectedDomain: getSelectedDomain(state),
  selectedRGroup: getSelectedRestaurantsGroup(state),
})

export default connect(
  mapStateToProps,
  {}
)(Domain)
