import {
  createApiCallAction,
  createApiEnumAction,
} from '../api/utils'

import ProApi from '../api/calls/ProApi'

export const GetProMeAction = createApiEnumAction('USER::ME::GET')

export const fetchProMe = () => createApiCallAction(
  GetProMeAction,
  ProApi.getProMe(),
  {
  }
)
