import { createSelector } from 'reselect'

import { createSimpleSelector } from 'pmt-modules/redux'

import { formatScheduledAction, formatScheduledActions } from './format'

//
// Get
//
const getScheduledActionsSelectors = createSimpleSelector(
  state => state.entities.scheduledAction.get
)

export const isFetchingGetScheduledActions = getScheduledActionsSelectors.isFetching

const getDataFromState = getScheduledActionsSelectors.getData

export const getDataGetScheduledActions = createSelector(
  [getDataFromState],
  data =>
    data
      ? {
          ...data,
          data: formatScheduledActions(data.data),
        }
      : null
)

export const getErrorGetScheduledActions = getScheduledActionsSelectors.getError

//
// Post
//
const postScheduledActionSelectors = createSimpleSelector(
  state => state.entities.scheduledAction.post
)

export const isFetchingPostScheduledAction = postScheduledActionSelectors.isFetching
const getPostFromState = postScheduledActionSelectors.getData
export const getDataPostScheduledAction = createSelector(
  [getPostFromState],
  data => (data ? formatScheduledAction(data) : null)
)
export const getErrorPostScheduledAction = postScheduledActionSelectors.getError

//
// Put
//
const putScheduledActionSelectors = createSimpleSelector(
  state => state.entities.scheduledAction.put
)

export const isFetchingPutScheduledAction = putScheduledActionSelectors.isFetching
const getPutFromState = putScheduledActionSelectors.getData
export const getDataPutScheduledAction = createSelector(
  [getPutFromState],
  data => (data ? formatScheduledAction(data) : null)
)
export const getErrorPutScheduledAction = putScheduledActionSelectors.getError

//
// Run
//
const runScheduledActionSelectors = createSimpleSelector(
  state => state.entities.scheduledAction.run
)

export const isFetchingRunScheduledAction = runScheduledActionSelectors.isFetching
export const getDataRunScheduledAction = runScheduledActionSelectors.getData
export const getErrorRunScheduledAction = runScheduledActionSelectors.getError
