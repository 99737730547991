import { createFormatter, createListFormatter } from 'pmt-utils/format'

import { ScheduledActionStatus } from './constants'
import { getFrequencyLabel } from './utils'

const formatAction = data => {
  data.isEnabled = data.status === ScheduledActionStatus.ENABLED
  data.isDisabled = data.status === ScheduledActionStatus.DISABLED

  const frequncy = getFrequencyLabel(data.frequencySettings.frequency)
  const hour =
    data.frequencySettings.hour < 10
      ? `0${data.frequencySettings.hour}`
      : data.frequencySettings.hour
  const minute =
    data.frequencySettings.minute < 10
      ? `0${data.frequencySettings.minute}`
      : data.frequencySettings.minute

  data.frequencyFormatted = `${frequncy}, ${hour}:${minute}`
  return data
}

export const formatScheduledAction = createFormatter(formatAction)

export const formatScheduledActions = createListFormatter(formatScheduledAction)
