import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import FeedbackApi from 'pmt-modules/api/calls/FeedbackApi'

//
//
//

export const FetchFeedbackCampaign = createApiEnumAction('FEEDBACK::CAMPAIGN::GET')

export const fetchFeedbackCampaign = feedbackCampaignId =>
  createApiCallAction(FetchFeedbackCampaign, FeedbackApi.getFeedbackCampaign(feedbackCampaignId), {
    feedbackCampaignId,
  })

//
//
//

export const FetchFeedbackCampaignList = createApiEnumAction('FEEDBACK::CAMPAIGN::LIST::GET')

export const fetchFeedbackCampaignList = (restaurantsGroupId, cursor = '', limit = 10) =>
  createApiCallAction(
    FetchFeedbackCampaignList,
    FeedbackApi.getFeedbackCampaignList(restaurantsGroupId, cursor, limit),
    {
      restaurantsGroupId,
      cursor,
      limit,
    }
  )

//
//
//

export const ExportFeedbackCampaign = createApiEnumAction('FEEDBACK::CAMPAIGN::EXPORT')

export const exportFeedbackCampaign = feedbackCampaignId =>
  createApiCallAction(
    ExportFeedbackCampaign,
    FeedbackApi.exportFeedbackCampaign(feedbackCampaignId),
    {
      feedbackCampaignId,
    }
  )
