import { createPaginateSelector } from 'pmt-modules/redux'

import { formatEventsList } from '../format'

const eventListSelectors = createPaginateSelector(state => state.entities.audit.event.list, {
  formatter: formatEventsList,
})

export const isFetchingEventList = eventListSelectors.isFetching
export const getEventList = eventListSelectors.getList
export const getEventListPagination = eventListSelectors.getPaging
