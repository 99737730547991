import keys from 'lodash/keys'

import StatusChip from 'pmt-ui/StatusChip'

import OrderMode from './OrderMode'
import OrderStatus from './OrderStatus'
import { tr } from '../../i18n/utils'

export OrderMode from './OrderMode'

export const PaymentFlowEnum = {
  /**
  * Full payment is to be made with the order
  */
  FULL_PAYMENT_WITH_ORDER: 'FULL_PAYMENT_WITH_ORDER',
  /**
   * Payment is to be made later
   */
  NO_PAYMENT_WITH_ORDER: 'NO_PAYMENT_WITH_ORDER',
}

export function getPaymentFlowEnumLabel(paymentFlowEnum) {
  switch (paymentFlowEnum) {
    case PaymentFlowEnum.FULL_PAYMENT_WITH_ORDER:
      return tr('global.order.PaymentFlowEnum.FULL_PAYMENT_WITH_ORDER')
    case PaymentFlowEnum.NO_PAYMENT_WITH_ORDER:
      return tr('global.order.PaymentFlowEnum.NO_PAYMENT_WITH_ORDER')
    default:
      return paymentFlowEnum;
  }
}

export const OrderOnGoingStatus = [
  OrderStatus.SENT,
  OrderStatus.RECEIVED,
  OrderStatus.PRODUCING,
  OrderStatus.READY,
  OrderStatus.IN_DELIVERY,
]

export const getOrderStatusLabel = status => {
  switch (status) {
    case OrderStatus.CREATED:
      return tr('global.order.status.CREATED');
    case OrderStatus.SENT:
      return tr('global.order.status.SENT');
    case OrderStatus.RECEIVED:
      return tr('global.order.status.RECEIVED');
    case OrderStatus.PRODUCING:
      return tr('global.order.status.PRODUCING');
    case OrderStatus.READY:
      return tr('global.order.status.READY');
    case OrderStatus.IN_DELIVERY:
      return tr('global.order.status.IN_DELIVERY');
    case OrderStatus.DELIVERED:
      return tr('global.order.status.DELIVERED');
    case OrderStatus.CANCELED:
      return tr('global.order.status.CANCELED');
    case OrderStatus.FAILED:
      return tr('global.order.status.FAILED');
    default:
      return status
  }
}

export const getOrderStatusType = status => {
  switch (status) {
    case OrderStatus.CREATED:
      return StatusChip.Status.WARNING
    case OrderStatus.SENT:
      // TODO: for now the API is using SENT, it does not set RECEIVED status when received by the POS
      return StatusChip.Status.INFO 
    case OrderStatus.RECEIVED:
      return StatusChip.Status.INFO
    case OrderStatus.PRODUCING:
      return StatusChip.Status.INFO
    case OrderStatus.READY:
      return StatusChip.Status.INFO
    case OrderStatus.IN_DELIVERY:
      return StatusChip.Status.INFO
    case OrderStatus.DELIVERED:
      return StatusChip.Status.VALID
    case OrderStatus.CANCELED:
      return StatusChip.Status.ERROR
    case OrderStatus.FAILED:
      return StatusChip.Status.ERROR
    default:
      return status
  }
}

export const getOrderModeLabel = mode => {
  switch (mode) {
    case OrderMode.ON_SITE:
      return tr('global.order.mode.on_site')
    case OrderMode.TAKE_AWAY:
      return tr('global.order.mode.take_away')
    case OrderMode.DELIVERY:
      return tr('global.order.mode.delivery')
    default:
      //Logger.warning('OrderViewUtils', `getOrderModeLabel: invalid mode ${mode}`)
      return ''
  }
}

export const getOrderModeStr = mode => {
  const modes = {
    0: 'ON_SITE',
    1: 'TAKE_AWAY',
    2: 'DELIVERY',
  }
  return modes[mode]
}

export const getOrderModeFromStr = modeStr => {
  const modes = {
    'ON_SITE': 0,
    'TAKE_AWAY': 1,
    'DELIVERY': 2,
  }
  return modes[modeStr]
}

export const getOrderModeLabelAsArray = () =>
  keys(OrderMode).map(value => ({
    label: getOrderModeLabel(OrderMode[value]),
    value,
  }))

export const ORDER_MINIMUM_AMOUNT = 0.5

export const OrderErrorCode = {
  INVALID_DUE_DATE: '150',
  RESTAURANT_DISABLED: '432',
  ORDER_MODE_DISABLED: '151',
  TABLE_NOT_FOUND: '402',
}

/**
 * Only used for display purpose
 * These values have no meaning in order data.
 * TODO: maybe the API should gives us this info.
 */
export const OrderPaymentStatus = {
  NOT_PAID: 'NOT_PAID',
  PAID: 'PAID',
  UNDERPAYMENT: 'UNDERPAYMENT',
  OVERPAYMENT: 'OVERPAYMENT',
  PAID_THIRD_PARTY: 'PAID_THIRD_PARTY',
}

export const getOrderPaymentStatusLabel = status => {
  switch (status) {
    case OrderPaymentStatus.NOT_PAID:
      return tr('global.order.payment.status.NOT_PAID')
    case OrderPaymentStatus.PAID:
      return tr('global.order.payment.status.PAID')
    case OrderPaymentStatus.UNDERPAYMENT:
      return tr('global.order.payment.status.UNDERPAYMENT')
    case OrderPaymentStatus.OVERPAYMENT:
      return tr('global.order.payment.status.OVERPAYMENT')
    case OrderPaymentStatus.PAID_THIRD_PARTY:
      return tr('global.order.payment.status.PAID_THIRD_PARTY')
    default:
      return status
  }
}

export const getOrderPaymentStatusType = status => {
  switch (status) {
    case OrderPaymentStatus.NOT_PAID:
      return StatusChip.Status.INFO
    case OrderPaymentStatus.PAID:
    case OrderPaymentStatus.PAID_THIRD_PARTY:
      return StatusChip.Status.VALID
    case OrderPaymentStatus.UNDERPAYMENT:
      return StatusChip.Status.ERROR
    case OrderPaymentStatus.OVERPAYMENT:
      return StatusChip.Status.WARNING
    default:
      return status
  }
}

export const CancellingParty = {
  MERCHANT: 'MERCHANT',
  CUSTOMER: 'CUSTOMER',
  THIRD_PARTY: 'THIRD_PARTY',
  UNKNOWN: 'UNKNOWN',
}

export const getCancellingPartyFormatted = cancellingParty => {
  switch (cancellingParty) {
    case CancellingParty.MERCHANT:
      return tr('global.order.cancellingParty.MERCHANT')
    case CancellingParty.CUSTOMER:
      return tr('global.order.cancellingParty.CUSTOMER')
    case CancellingParty.THIRD_PARTY:
      return tr('global.order.cancellingParty.THIRD_PARTY')
    case CancellingParty.UNKNOWN:
      return tr('global.order.cancellingParty.UNKNOWN')
    default:
      return cancellingParty
  }

}