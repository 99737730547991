import React from 'react'
import PropTypes from 'prop-types'
import isNull from 'lodash/isNull'
import { connect } from 'react-redux'

import {
  fetchProMe,
} from '../actions'

import {
  getProMe,
  isFetchingProMe,
} from '../selectors'

import {
  haveAuthProCredentials,
} from '../../authPro'

/**
 * @specs N/A
 *
 * A HOC that fetch the current logged in pro and pass it to the children.
 *
 * Requirements:
 * -
 *
 * see `withProMe`
 *
 */
class ProMeContainer extends React.Component {

  componentWillMount() {
    if (isNull(this.props.proMe)) {
      this.loadProMe(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.proId !== props.proId) {
      this.loadProMe(nextProps)
    }
  }

  loadProMe(props) {
    props.fetchProMe()
  }

  render() {
    const { ProMeWrappedComponent, isFetchingMe, ...other } = this.props
    return (
      <ProMeWrappedComponent
        ProMeWrappedComponent={null}
        proId={'me'}
        haveAuthProCredentials={haveAuthProCredentials}
        isFetchingMe={isFetchingMe}
        {...other}
      />
    )
  }
}

ProMeContainer.propTypes = {
  fetchProMe: PropTypes.func.isRequired,
  isFetchingMe: PropTypes.bool,
  proMe: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  return {
    proMe: getProMe(state, props),
    isFetchingMe: isFetchingProMe(state, props),
    haveCredentials: haveAuthProCredentials(state),
  }
}

export default connect(mapStateToProps, {
  fetchProMe,
})(ProMeContainer)
