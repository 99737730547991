import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { withForm, FormType } from 'pmt-modules/form'

import { Link } from 'pmt-modules/routing'

import HibpPasswordView from 'app/components/HibpPasswordView'
import TextField from 'pmt-ui/TextField'
import PasswordField from 'pmt-ui/PasswordField'

const LoginFormView = withForm(FormType.PRO_LOGIN)(({ formData, formErrors, onChange }) => (
  <div>
    <TextField
      type="email"
      label={tr('auth.login.email')}
      placeholder="votre@email.com"
      fullWidth
      value={formData.username}
      onChange={event => onChange({ ...formData, username: event.target.value })}
      error={!isNil(formData.username) && !isEmpty(formErrors.username.message)}
      helperText={
        !isNil(formData.username) && !isEmpty(formErrors.username.message)
          ? formErrors.username.message
          : false
      }
    />

    <PasswordField
      label={tr('auth.login.password')}
      className="u-marginTop10"
      value={formData.password}
      onChange={event => onChange({ ...formData, password: event.target.value })}
      error={!isNil(formData.password) && !isEmpty(formErrors.password.message)}
      helperText={
        !isNil(formData.password) && !isEmpty(formErrors.password.message)
          ? formErrors.password.message
          : false
      }
      fullWidth
    />

    <HibpPasswordView
      password={formData.password}
      pwnedText={tr('security.hibp.pwned.should_change')}
      displayCheckSecure={false}
      displayIsSecureMessage={false}
    />

    <div className="u-textAlignRight u-foreground u-marginTop20">
      <Link to={Link.getRoute('FORGOT_PASSWORD')} className="u-contentSerifItalic">
        {tr('auth.login.forgot_password')}
      </Link>
    </div>
  </div>
))

export default LoginFormView
