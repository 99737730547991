import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import AuditApi from 'pmt-modules/api/calls/AuditApi'

export const FetchAuditEventListAction = createApiEnumAction('AUDIT::REQUEST_LIST')

/**
 * {
 *    fromTime,
 *    toTime,
 *    restaurantsGroupId: rGroupId,
 *    cursor,
 *    limit,
 *    events: [ 'service.response' ]
 *  }
 */
export const fetchAuditEventList = props =>
  createApiCallAction(FetchAuditEventListAction, AuditApi.getEvents(props), props)
