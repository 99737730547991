import isNull from 'lodash/isNull'
import invariant from 'invariant'
import { findOnArray } from 'pmt-utils/array'
import { createSelector } from 'reselect'

import { formatOrders } from '../order'

import { getApiConsumerListWithDefault } from '../apiConsumer'

import { formatTopUps } from '../topUp'

import { HistoryType } from './constants'

import { getRestaurantMap } from '../restaurant'

import { formatTransaction, formatTransactionsList } from '../userTransaction/format'
import { formatUserTransactionsList } from '../userCheck/format'

const getUserHistoryData = state => state.entities.userHistory
const getUserIdOnProps = (state, props) => props.userId
const getHistoryTypeOnProps = (state, props) => props.historyType
const getCheckPosIdOnProps = (state, props) => props.checkPosId

export const getUserHistoryTypeData = createSelector(
  [getUserHistoryData, getUserIdOnProps, getHistoryTypeOnProps],
  (data, userId, historyType) => {
    if (!data) {
      return null
    }

    const userHistory = data.get(userId)

    if (!userHistory) {
      return null
    }

    const userHistoryType = userHistory.get(historyType)

    if (!userHistoryType) {
      return null
    }

    return userHistoryType.toJS()
  }
)

export const makeIsFetchingHistory = () => {
  return createSelector([getUserHistoryTypeData], historyTypeData => {
    if (isNull(historyTypeData)) {
      return true
    }

    return historyTypeData.isFetching
  })
}

export const makeGetUserHistoryTypeList = () => {
  return createSelector(
    [
      getUserHistoryTypeData,
      getHistoryTypeOnProps,
      getRestaurantMap,
      getApiConsumerListWithDefault,
    ],
    (historyTypeData, historyType, formattedRestaurantsMap, apiConsumerList) => {
      if (isNull(historyTypeData)) {
        return null
      }

      const data = historyTypeData.data

      if (!data) {
        return null
      }

      //
      // For better performances, we get formatted restaurants list here and pass it to the order
      // format, instead of formatting the restaurant on each order formatter
      //
      const props = { formattedRestaurantsMap }

      switch (historyType) {
        case HistoryType.ORDER:
          const orders = formatOrders(data, props)
          // sort by date
          return orders.sort((a, b) => b.dueDate - a.dueDate)

        case HistoryType.TOP_UP:
          const topUps = !isNull(apiConsumerList)
            ? formatTopUps(data, { ...props, ...apiConsumerList })
            : formatTopUps(data, props)

          // sort by date
          return topUps.sort((a, b) => b.creationDate - a.creationDate)

        case HistoryType.USER_TRANSACTIONS:
          return formatUserTransactionsList(data)

        case HistoryType.TRANSACTIONS:
          return formatTransactionsList(data, props)

        default:
          invariant(false, `Invalid HistoryType ${historyType}`)
      }
    }
  )
}

export const makeGetUserHistoryTypePagination = () => {
  return createSelector([getUserHistoryTypeData], historyTypeData => {
    if (isNull(historyTypeData)) {
      return null
    }

    const pagination = historyTypeData.pagination

    if (!pagination) {
      return null
    }

    return pagination
  })
}

export const makeGetUserHistoryTransaction = () =>
  createSelector([getUserHistoryTypeData, getCheckPosIdOnProps], (data, checkPosId) => {
    if (!data) {
      return null
    }

    const transaction = findOnArray(data.data, a => a.checkPosId === checkPosId)

    if (!transaction) {
      return null
    }

    return formatTransaction(transaction)
  })
