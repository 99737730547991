/**
 * Define the different type of history data that can be retrieved.
 */
export const HistoryType = {
  PAYMENT: 'HistoryType::PAYMENT',
  ORDER: 'HistoryType::ORDER',
  BOOKING: 'HistoryType::BOOKING',
  TOP_UP: 'HistoryType::TOP_UP',

  /**
   * Special type of history where we have payment and top up on the same list.
   * This list contains less informations than we can expect, and is used to display data we don't
   * own but retrieve from a thirst party API (e.g CSI).
   */
  TRANSACTIONS: 'HistoryType::TRANSACTIONS',

  // All the online payments (user check) of the user (order / payment / top up etc)
  USER_TRANSACTIONS: 'USER_TRANSACTIONS',
}
