import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'

import { getExcelColumnName } from 'pmt-utils/excel'

import formatRowInformation from './formatRowInformation'
import formatErrorDescription from './formatErrorDescription'

const formatCellInformation = (cellError) => {
  const cellInformation = cellError.cellInformation

  if (cellInformation.hasCellInformation === true) {
    //
    // We get the indexes of the row / cell starting at '0'
    // An excel file column begins at 1
    //
    cellInformation.columnName = getExcelColumnName(cellInformation.column + 1)
    cellInformation.rowFormatted = cellInformation.row + 1
  }

  cellError.cellInformation = cellInformation

  return cellError
}

const formatCellError = createFormatter(
  formatCellInformation,
  createSubObjectFormatter('rowInformation', formatRowInformation),
  createSubObjectFormatter('errorDescription', formatErrorDescription),
)

const formatCellErrors = createListFormatter(formatCellError)

export default formatCellErrors
