import { saveToStorage, getFromStorage } from 'pmt-modules/storage'

const StorageItem = {
  BO_RESTAURANTS_GROUP_ID: 'BO::LAST_USED::RESTAURANTS_GROUP_ID',
  BO_DOMAIN_ID: 'BO::LAST_USED::DOMAIN_ID',
}

export const getSessionSelectedRestaurantsGroupId = () =>
  getFromStorage(StorageItem.BO_RESTAURANTS_GROUP_ID, null)

// TODO: maybe save it by pro.
// if we have multiple connected pro on a same browser, it will use the default group for the
// second account each time
export const saveSelectedRestaurantsGroupId = rGroupId => {
  saveToStorage(StorageItem.BO_RESTAURANTS_GROUP_ID, rGroupId)
}

export const getSessionSelectedDomainId = () => getFromStorage(StorageItem.BO_DOMAIN_ID, null)

// TODO: maybe save it by pro.
// if we have multiple connected pro on a same browser, it will use the default domain for the
// second account each time
export const saveSelectedDomainId = domainId => {
  saveToStorage(StorageItem.BO_DOMAIN_ID, domainId)
}
