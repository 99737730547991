import Immutable from 'immutable'

import {
  TableAction,
} from './actions'

export {
  TableType,
  TableMode,
} from './constants'

export * from './actions'
export * from './selectors'
export * from './components'

let DEFAULT_STATE = Immutable.fromJS({
})

export const tableReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TableAction.UPDATE_QUERY:
      return state.merge({
        [action.tableType]: {
          sortOptions: action.sortOptions,
        }
      })

    default:
      return state
  }
}
