import Immutable from 'immutable'
import { useDebugValue, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createSelectorsPerObject } from 'pmt-modules/redux'
import useMountEffect from 'pmt-ui/hooks/useMountEffect'
import { createReducer, createAction } from 'pmt-modules/redux'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

const getPath = action => [[action.data.requestName, action.data.id].join('_')]

const ActionResetRequestDataAction = 'actionResetRequestData'
const actionResetRequestData = (requestName, id, where) =>
  createAction(ActionResetRequestDataAction, {
    data: {
      requestName,
      id,
    },
    where,
  })

const ActionSetDataAction = 'actionSetData'
const actionSetData = (requestName, id, body) =>
  createAction(ActionSetDataAction, {
    data: {
      requestName,
      id,
    },
    body,
  })

const RequestApiAction = createApiEnumAction('actionRequestApi')
const actionRequestApi = (requestName, id, apiRequest, meta) =>
  createApiCallAction(RequestApiAction, apiRequest, { requestName, id, apiRequest, meta })

// export const requestApRequestApiActioniReducer = createReducerPerObject(RequestApiAction, action => action.data.requestName)
export const requestApiReducer = createReducer(Immutable.fromJS({}), {
  [RequestApiAction.REQUEST]: (state, action) =>
    state.mergeIn(getPath(action), {
      actionData: action.data,
      isFetching: true,
      error: null,
    }),
  [RequestApiAction.SUCCESS]: (state, action) => {
    // TODO: copy createPaginateReducer with differeent types of pagination
    // we handle only cursors and page for now
    let isNotFirstPage =
      action.data?.meta?.paginate &&
      (!!action.response?.paging?.cursors?.before || action.response?.page?.page !== 1)

    if (!isNotFirstPage) {
      return state.mergeIn(getPath(action), {
        data: action.response,
        isFetching: false,
        error: null,
      })
    }

    let currentList = state.getIn([...getPath(action), 'data', 'data'])
    if (currentList) {
      currentList = currentList
        .valueSeq()
        .toArray()
        .map(item => {
          return item.toJS()
        })
    } else {
      currentList = []
    }

    let newList = [...currentList, ...action.response.data]
    // paginate query
    return state.mergeIn(getPath(action), {
      data: {
        paging:
          action.response.paging ||
          (action.response.page && action.response.sort
            ? { ...action.response.page, ...action.response.sort }
            : null),
        data: newList,
      },
      isFetching: false,
      error: null,
    })
  },
  [RequestApiAction.FAILURE]: (state, action) =>
    state.mergeIn(getPath(action), {
      data: null,
      isFetching: false,
      error: action.error,
    }),
  [ActionResetRequestDataAction]: (state, action) =>
    state.mergeIn(getPath(action), {
      data: null,
      isFetching: false,
      error: null,
    }),
  [ActionSetDataAction]: (state, action) =>
    state.mergeIn(getPath(action), {
      data: action.body,
      isFetching: false,
      error: null,
    }),
})

const cache = {}
function getSelectors(requestName, id) {
  const key = `${requestName}_${id}`
  if (cache[key]) {
    return cache[key]
  }

  const selectors = createSelectorsPerObject(
    state => state.entities.requestApi,
    (state, props) => [requestName, id].join('_')
  )

  cache[key] = {
    isFetching: selectors.makeIsFetching(),
    data: selectors.makeGetData(),
    error: selectors.makeGetError(),
    // request: selectors.makeGetRequest(),
  }
  return cache[key]
}

const useApi = ({
  requestName,
  manualRun: manualRunParam,
  onSuccess,
  onFailure,
  formatter,
  // devResponse,
  id = '_',

  // request data
  url,
  endpoint,
  query,
  params,
  type,
  body,

  resetDataOnMount = false,
  paginate = false,
  autoReset = false,
  keepDataOnRequest = false,
  formatterProps = {},
}) => {
  const dispatch = useDispatch()
  useDebugValue(`[${requestName}] [${type}] ${endpoint}`)

  const manualRun = manualRunParam ? manualRunParam : type !== 'GET'

  const selectors = getSelectors(requestName, id)
  const getDataSelector = selectors.data
  const isFetchingSelector = selectors.isFetching
  const getErrorSelector = selectors.error
  // const getRequestSelector = selectors.request

  const applyFormatter = useCallback(
    res => {
      const data = !paginate ? res : res?.data
      // trick to not use getrequestNameData
      if (data && formatter) {
        return formatter(data, formatterProps)
      } else {
        return data
      }
    },
    [paginate, formatter, formatterProps]
  )

  const allData = useSelector(state => {
    const res = getDataSelector(state)

    const paging = res?.paging ||
      (res?.page && res?.sort ? { ...res.page, ...res.sort } : null) || {
        cursors: {},
      }
    const data = applyFormatter(res)
    return { data, paging }
  }, shallowEqual)

  const isFetching = useSelector(state => {
    const isFetching = isFetchingSelector(state)
    return isFetching
  }, shallowEqual)

  const error = useSelector(state => {
    const error = getErrorSelector(state)
    return error
  }, shallowEqual)

  // const request = useSelector((state) => {
  //   const res = getRequestSelector(state, { requestName, id })
  //   return res
  // }, shallowEqual)

  const resetRequestData = useCallback(
    where => dispatch(actionResetRequestData(requestName, id, where)),
    [requestName, id, dispatch]
  )
  const setData = useCallback(body => dispatch(actionSetData(requestName, id, body)), [
    requestName,
    id,
    dispatch,
  ])

  const runRequest = useCallback(
    (body, newQuery = {}) => {
      const onSuccessCallback = (data, response) => {
        // TODO: send paging as second arg.
        onSuccess && onSuccess(applyFormatter(data), data, response)
        if (autoReset) {
          resetRequestData('on success')
        }
      }

      const onFailureCallback = error => {
        onFailure && onFailure(error)

        if (autoReset) {
          resetRequestData('on failure')
        }
      }

      return dispatch(
        actionRequestApi(
          requestName,
          id,
          {
            url,
            endpoint,
            query: {
              ...(query || {}),
              ...newQuery,
            },
            params,
            type,
            body: type !== 'GET' ? body : undefined,

            onSuccessCallback: onSuccessCallback,
            onFailureCallback: onFailureCallback,
          },
          {
            paginate,
          }
        )
      )
    },
    [
      requestName,
      id,
      params,
      query,
      applyFormatter,
      autoReset,
      dispatch,
      endpoint,
      onSuccess,
      onFailure,
      paginate,
      resetRequestData,
      type,
      url,
    ]
  )

  const loadMore = useCallback(
    (newBody, newQuery) => {
      runRequest(newBody || body, {
        cursor: allData?.paging?.cursors?.after,
        page: allData?.paging?.page + 1,
        pageSize: allData?.paging?.pageSize,
        orderBy: allData?.paging?.orderBy,
        direction: allData?.paging?.direction,
        ...(newQuery || {}),
      })
    },
    [allData, body, runRequest]
  )

  useMountEffect(() => {
    if (resetDataOnMount) {
      resetRequestData()
    }
    if (!manualRun && !isFetching && (resetDataOnMount || !allData?.data)) {
      runRequest(body)
    }

    return () => {
      // reset data on unmount to not keep it on storage for nothing
      if (resetDataOnMount) {
        resetRequestData()
      }
    }
  })

  return {
    runRequest,
    resetRequestData,
    setData,
    loadMore,
    data: allData?.data,
    paging: allData?.paging,
    isFetching,
    error,
    // request,
  }
}

export default useApi
