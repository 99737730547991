import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { tr } from 'pmt-modules/i18n'
import isEmpty from 'lodash/isEmpty'
import {
  DocumentStatus,
  UboResumeStatus,
  RestaurantPspStatus,
  getDocumentTypeLabel,
  getDocumentStatusLabel,
  getRestaurantPspStatusLabel,
  getUboResumeStatusLabel,
  getBankResumeStatusLabel,
  BankResumeStatus,
  RestaurantResumeStatus,
  getRestaurantResumeStatusLabel,
} from './constants'

import { formatDate } from 'pmt-utils/date'

const formatDocumentStatus = documentData => {
  documentData.typeLabel = getDocumentTypeLabel(documentData.type)
  documentData.statusLabel = getDocumentStatusLabel(documentData.status)

  documentData.isStatusUnknown = documentData.status === DocumentStatus.UNKNOWN
  documentData.isStatusError = documentData.status === DocumentStatus.ERROR
  documentData.isStatusCreated = documentData.status === DocumentStatus.CREATED
  documentData.isStatusValidationAsked = documentData.status === DocumentStatus.VALIDATION_ASKED
  documentData.isStatusValidated = documentData.status === DocumentStatus.VALIDATED
  documentData.isStatusRefused = documentData.status === DocumentStatus.REFUSED

  return documentData
}

const formatPspResume = pspResume => {
  pspResume.statusLabel = getRestaurantPspStatusLabel(pspResume.pspStatus)
  pspResume.isStatusUnknown = pspResume.pspStatus === RestaurantPspStatus.UNKNOWN
  pspResume.isStatusNotValidated = pspResume.pspStatus === RestaurantPspStatus.NOT_VALIDATED
  pspResume.isStatusArchived = pspResume.pspStatus === RestaurantPspStatus.ARCHIVED
  pspResume.isStatusValidated = pspResume.pspStatus === RestaurantPspStatus.VALIDATED

  pspResume.pspIdStatusCreated = pspResume.pspIdStatus === BankResumeStatus.CREATED
  pspResume.pspIdStatusNotCreated = pspResume.pspIdStatus === BankResumeStatus.NOT_CREATED

  if (pspResume.pspIdStatusNotCreated) {
    pspResume.refusedReasonMessage = tr('global.restaurant.psp_resume.refused_reason.no_psp_id')
  }

  return pspResume
}

//
//
//

const formatUboResumeData = uboResume => {
  uboResume.statusLabel = getUboResumeStatusLabel(uboResume.status)
  uboResume.isStatusCreated = uboResume.isStatusFailed = uboResume.status === UboResumeStatus.FAILED
  uboResume.isStatusValidationAsked = uboResume.isStatusIncomplete = uboResume.isStatusRefused = uboResume.isStatusNoUboDeclaration =
    uboResume.status === UboResumeStatus.NO_UBO_DECLARATION
  uboResume.isStatusSucceed = uboResume.status === UboResumeStatus.SUCCEED

  return uboResume
}

const formatUbo = ubo => {
  ubo.birthdayFormatted = formatDate(ubo.birthday, 'DD-MM-YYYY')
  ubo.creationDateFormatted = formatDate(ubo.creationDate, 'DD-MM-YYYY')
  return ubo
}

const formatUboResume = createFormatter(
  formatUboResumeData,
  createSubObjectFormatter('uboList', createListFormatter(formatUbo))
)

//
//
//

export const formatDocumentsListStatus = createFormatter(
  createSubObjectFormatter('uboResume', formatUboResume),
  createSubObjectFormatter('pspResume', formatPspResume),
  createSubObjectFormatter('documents', createListFormatter(formatDocumentStatus)),
  //
  // Format the data to display a single table for all of our resume data.
  // See DocumentsStatusView
  //
  restaurantDocumentsStatus => {
    const resumeAllTableData = restaurantDocumentsStatus.documents.map(document => ({
      ...document,
      label: document.typeLabel,
      isStatusArchived: false,
      isDocument: true,
    }))

    //
    // psp
    //
    const pspResume = restaurantDocumentsStatus.pspResume
    const pspData = {
      label: tr('restaurant.view.section.documentsStatus.pspStatus'),
      statusLabel: pspResume.statusLabel,
      isStatusUnknown: pspResume.isStatusUnknown,
      isStatusRefused: pspResume.isStatusNotValidated,
      isStatusArchived: pspResume.isStatusArchived,
      isStatusValidated: pspResume.isStatusValidated,
      isStatusNotCreated: false,
    }
    resumeAllTableData.push(pspData)

    //
    // UBO
    //

    const uboResume = restaurantDocumentsStatus.uboResume

    // add uboData
    const uboData = {
      label: 'UBO',
      ...(uboResume || {}),
      statusLabel: uboResume?.statusLabel,
      isStatusUnknown: !uboResume || uboResume?.isStatusFailed,
      isStatusRefused: uboResume?.isStatusRefused || uboResume?.isStatusIncomplete,
      isStatusNotCreated: uboResume?.isStatusNoUboDeclaration,
      isStatusValidated: uboResume?.isStatusSucceed,
      isStatusValidationAsked: uboResume?.isStatusValidationAsked,
      isStatusArchived: false,
      isStatusCreated: uboResume?.isStatusCreated,
      refusedReasonType: '',
      refusedReasonMessage: '',
    }
    resumeAllTableData.push(uboData)

    //
    // bank resume
    //
    const getBankResumeData = (isDataX, label, dataKey, statusKey) => {
      const bankResume = restaurantDocumentsStatus.bankResume
      const status = bankResume[statusKey]
      const data = bankResume[dataKey]
      return {
        label,
        // isData(Rib|Bic|etc)
        [isDataX]: true,
        isBankResumeData: true,
        data,
        status,
        statusLabel: getBankResumeStatusLabel(status),
        isStatusUnknown: status === BankResumeStatus.UNKNOWN,
        isStatusNotCreated: status === BankResumeStatus.NOT_CREATED,
        // created will be display as a warning (on going) while here we consider it done, so we
        // use validated
        isStatusValidated: status === BankResumeStatus.CREATED,
        isStatusRefused: false,
        isStatusArchived: false,
      }
    }

    // we mix the three following data into one line
    // const dataRib = getBankResumeData(
    //   'isDataRib',
    //   'RIB',
    //   'identityDocument',
    //   'identityDocumentStatus'
    // )
    const dataBic = getBankResumeData('isDataBic', 'BIC', 'bic', 'bicStatus')
    const dataIban = getBankResumeData('isDataIban', 'IBAN', 'iban', 'ibanStatus')
    const dataWallet = getBankResumeData(
      'isDataWalletId',
      'Wallet id',
      'acquirerWalletId',
      'acquirerWalletStatus'
    )

    // TODO: PayGreen: MangoPay does not require the RIB pdf to be uploded, but PayGreen do.
    const allData = [dataBic, dataIban, dataWallet]
    // const allData = [dataRib, dataBic, dataIban, dataWallet]
    const isRibStatusValidated = allData.every(data => data.isStatusValidated)
    const isRibStatusNotCreated = allData.every(data => data.isStatusNotCreated)

    const ribRefusedReasons = []

    // TODO: && PayGreen (optional for MGP)
    // if (!dataRib.isStatusValidated) {
    //   ribRefusedReasons.push('RIB pdf')
    // }

    if (!dataBic.isStatusValidated) {
      ribRefusedReasons.push('BIC')
    }

    if (!dataIban.isStatusValidated) {
      ribRefusedReasons.push('IBAN')
    }

    if (!dataWallet.isStatusValidated) {
      ribRefusedReasons.push('Wallet')
    }

    let ribRefusedReasonType = ribRefusedReasons.join(', ')
    if (!isEmpty(ribRefusedReasonType)) {
      ribRefusedReasonType = `${tr('global.restaurant.psp_resume.missing')} ${ribRefusedReasonType}`
    }

    resumeAllTableData.push({
      label: 'RIB',
      isBankResumeData: true,
      statusLabel: getBankResumeStatusLabel(
        isRibStatusValidated ? BankResumeStatus.CREATED : BankResumeStatus.NOT_CREATED
      ),
      refusedReasonType: ribRefusedReasonType,
      ribRefusedReasonMessage: '',
      isStatusUnknown: false,
      isStatusNotCreated: isRibStatusNotCreated,
      isStatusValidated: isRibStatusValidated,
      isStatusRefused: !isRibStatusNotCreated && !isRibStatusValidated,
      isStatusArchived: false,
    })

    //
    // global: psp and wallet are validated
    //
    const isGlobalValidated =
      isRibStatusValidated && restaurantDocumentsStatus.pspResume.isStatusValidated

    const globalData = {
      label: 'Global',
      statusLabel: getRestaurantResumeStatusLabel(
        isGlobalValidated ? RestaurantResumeStatus.CONFIGURED : RestaurantResumeStatus.TO_CONFIGURE
      ),
      refusedReasonMessage: '',
      isStatusUnknown: false,
      isStatusNotCreated: !isGlobalValidated,
      isStatusValidated: isGlobalValidated,
      isStatusRefused: false,
      isStatusArchived: false,
    }

    restaurantDocumentsStatus.global = globalData
    restaurantDocumentsStatus.resumeAllTableData = resumeAllTableData

    return restaurantDocumentsStatus
  }
)
