import ApiAsyncServiceApi from 'pmt-modules/api/calls/ApiAsyncServiceApi'
import { generateUUID } from 'pmt-utils/uuid'
import { createAction } from 'pmt-modules/redux'

import {
  createApiCallAction,
  // createTestApiCallAction,
  createApiEnumAction,
} from 'pmt-modules/api/utils'

export const FetchApiAsyncServiceDataAction = createApiEnumAction('ApiSyncServiceApi::GET')

export const fetchApiAsyncServiceData = (asyncServiceId, waitFor, iteration = 0) =>
  createApiCallAction(FetchApiAsyncServiceDataAction, ApiAsyncServiceApi.get(asyncServiceId), {
    asyncServiceId,
    waitFor,
    iteration,
  })

//
//
//

export const CallAsyncApiServiceAction = createApiEnumAction('ApiSyncServiceApi::CALL')

export const createAsyncApiCallAction = (actionName, dataStatePath, getApiCall) => {
  const actionUUID = `${actionName}_${generateUUID()}`

  const action = (...args) =>
    createApiCallAction(CallAsyncApiServiceAction, getApiCall.apply(null, args), {
      ...args,
      dataStatePath,
      actionName,
      // unique identifier for the action, adding uuid in case we have multiple actions with the
      // same name
      actionUUID,
    })

  action.actionUUID = actionUUID
  action.dataStatePath = dataStatePath
  action.actionName = actionName

  return action
}

export const SetAsyncApiServiceResultAction = 'API_ASYNC::SET_DATA'

export const setAsyncApiServiceResult = (dataStatePath, result) =>
  createAction(SetAsyncApiServiceResultAction, {
    dataStatePath,
    result,
  })
