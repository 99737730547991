

/**
 * Retrieve the given query on the given props.
 * We handle values such as null or undefined as string, converted to a real value.
 * @param  {[type]} props     Page props
 * @param  {[type]} queryName query to retrieve
 */
export const getPropsQuery = (props, queryName) => {
  let query = props.location.query[queryName]

  if (!query) {
    return null
  }

  if (query === 'null') {
    return null
  }

  if (query === 'undefined') {
    return null
  }

  return query
}
