import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import {
  getCustomTextTypeExplaination,
  CustomTextSource,
  getRestaurantCustomTextTypes,
} from './constants'
import { existsOnArray } from 'pmt-utils/array'

const formatLocalizedText = localizedText => {
  localizedText.explaination = getCustomTextTypeExplaination(localizedText.type)

  return localizedText
}

const formatSourceDefaultKeys = (texts, props) => {
  const missingTexts = []
  // add missing texts keys for the given source if needed
  if (props.actionData?.source === CustomTextSource.RESTAURANT) {
    getRestaurantCustomTextTypes().forEach(customTextType => {
      if (!existsOnArray(texts, localizedText => localizedText.type === customTextType)) {
        missingTexts.push({
          type: customTextType,
          locales: [],
        })
      }
    })
  }

  return [...texts, ...missingTexts]
}

export const formatCustomTextsContainer = createFormatter(
  createSubObjectFormatter(
    'texts',
    createFormatter(formatSourceDefaultKeys, createListFormatter(formatLocalizedText))
  )
)
