import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import ApiConsumerApi from 'pmt-modules/api/calls/ApiConsumerApi'

export const FetchApiKeyAction = createApiEnumAction('API_KEY::GET')

export const fetchApiKey = apiConsumerId =>
  createApiCallAction(FetchApiKeyAction, ApiConsumerApi.getApiKey(apiConsumerId))

//
//
//

export const GenerateApiKeyAction = createApiEnumAction('API_KEY::GENERATE')

export const generateApiKey = apiConsumerId =>
  createApiCallAction(GenerateApiKeyAction, ApiConsumerApi.generateApiKey(apiConsumerId))
