import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { fetchUser, fetchUserWithUnknownRestaurantsGroup } from '../../actions'
import { makeGetUser, isFetchingUser, getUserError } from '../../selectors'

/**
 */
class UserContainer extends React.Component {
  componentWillMount() {
    if (isNil(this.props.user)) {
      this.loadUser(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.userId !== props.userId) {
      this.loadUser(nextProps)
    }
  }

  loadUser(props) {
    if (props.unknownRestaurantsGroup) {
      props.fetchUserWithUnknownRestaurantsGroup(props.userId, false)
    } else {
      props.fetchUser(props.rGroupId, props.userId, false)
    }
  }

  render() {
    const {
      isFetchingUser,
      rGroupId,
      userId,
      user,
      userError,
      children,
      ...otherProps
    } = this.props

    return children({
      isFetchingUser,
      rGroupId,
      userId,
      user,
      userError,
      ...otherProps,
    })
  }
}

UserContainer.propTypes = {
  isFetchingUser: PropTypes.bool,
  rGroupId: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user: PropTypes.object,

  // children MUST be a function
  children: PropTypes.func.isRequired,

  /**
   * When a pro wants to retrieve a user, it does not have the restaurants group in some cases. In
   * this case set this to true.
   */
  unknownRestaurantsGroup: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
  const getUser = makeGetUser()

  return {
    user: getUser(state, props),
    userError: getUserError(state, props),
    isFetchingUser: isFetchingUser(state, props),
  }
}

export default connect(
  mapStateToProps,
  {
    fetchUser,
    fetchUserWithUnknownRestaurantsGroup,
  }
)(UserContainer)
