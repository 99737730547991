import { combineReducers } from 'redux'
import Immutable from 'immutable'

import { createPaginateReducer, createSimpleReducer } from 'pmt-modules/redux'
import { UPLOADED_FILES, FileUploadType } from 'pmt-modules/upload'

import { GetMediaActions, GetMediaListActions, PostMediaActions } from './actions'

export * from './actions'
export * from './constants'
export * from './middlewares'
export * from './selectors'

const DEFAULT = {
  files: null,
}

export const uploadMediaReducer = (state = Immutable.fromJS(DEFAULT), action) => {
  switch (action.type) {
    case PostMediaActions.SUCCESS:
    case PostMediaActions.FAILURE:
      return state.merge(
        Immutable.fromJS({
          ...DEFAULT,
        })
      )
    case UPLOADED_FILES:
      if (action.uploadType === FileUploadType.BO_UPLOAD_MEDIA) {
        return state.merge({
          files: action.files,
        })
      }
      return state

    default:
      return state
  }
}

export const mediaReducer = combineReducers({
  upload: uploadMediaReducer,
  get: createSimpleReducer(GetMediaActions),
  getList: createPaginateReducer(GetMediaListActions),
  post: createSimpleReducer(PostMediaActions),
})
