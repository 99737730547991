import ApiEndpoints from '../config/ApiEndpoints'
import ApiConsumerErrors from '../errors/ApiConsumerErrors'

const ApiConsumerApi = {
  getApiConsumerList: (rGroupId, query) => ({
    endpoint: ApiEndpoints.API_CONSUMERS,
    type: 'GET',
    params: {
      restaurantsGroupId: rGroupId,
    },
    query: query,
  }),

  getApiConsumerListFromRestaurant: restaurantId => ({
    endpoint: ApiEndpoints.API_CONSUMERS_RESTAURANT,
    type: 'GET',
    params: {
      restaurantId,
    },
  }),

  getApiConsumer: (apiConsumerId, loadSettings = false) => ({
    endpoint: ApiEndpoints.API_CONSUMER,
    type: 'GET',
    params: {
      apiConsumerId,
    },
    query: {
      loadSettings,
    },
  }),

  getApiKey: apiConsumerId => ({
    endpoint: ApiEndpoints.API_KEY,
    type: 'GET',
    params: {
      apiConsumerId,
    },
  }),

  generateApiKey: apiConsumerId => ({
    endpoint: ApiEndpoints.API_KEY,
    type: 'POST',
    params: {
      apiConsumerId,
    },
  }),

  getSettings: apiConsumerId => ({
    endpoint: ApiEndpoints.API_CONSUMER,
    type: 'GET',
    params: {
      apiConsumerId,
    },
    query: {
      loadSettings: true,
    },
  }),

  getValidation: apiConsumerId => ({
    endpoint: ApiEndpoints.API_CONSUMER_VALIDATION,
    type: 'GET',
    params: {
      apiConsumerId,
    },
  }),

  patchSettings: (apiConsumerId, body) => ({
    endpoint: ApiEndpoints.API_CONSUMER,
    type: 'PATCH',
    params: {
      apiConsumerId,
    },
    body,
    errorHandler: ApiConsumerErrors.PATCH,
  }),

  post: apiConsumer => ({
    endpoint: ApiEndpoints.CREATE_API_CONSUMER,
    type: 'POST',
    body: apiConsumer,
    query: {
      // TODO: remove
      // debugMode: true,
    },
  }),
}

export default ApiConsumerApi
