import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  identifiers: [],
}

const validationRules = () => ({})

export default createForm(FormType.API_CONSUMER__ANALYTICS, validationRules, defaultData)
