import React, { Fragment, useState } from 'react'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { Link } from 'pmt-modules/routing'

import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles(theme => ({
  itemIcon: {},
  itemIconSelected: {
    color: 'inherit',
  },
  item: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5),
    borderLeft: `4px solid transparent`,
  },
  selectedItem: {
    borderLeft: `4px solid ${theme.palette.primary.dark}`,
    background: theme.palette.primary.light,
    color: '#fff',
    '&.hasChildren': {
      background: 'none',
      color: theme.palette.primary.main,
    },
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.light,
    },
  },
}))

const SidebarMenuItem = ({ itemDefinition, routeConfig, level = 0 }) => {
  const classes = useStyles()

  const hasChildren = !isEmpty(itemDefinition.children)

  //
  // Calculate which items are selected thanks to the menuReferer route prop.
  //
  const menuReferer = routeConfig.props.menuReferer
  let isSelected = false
  if (!isNil(menuReferer)) {
    if (!isNil(itemDefinition.route)) {
      isSelected = itemDefinition.route.name === menuReferer.name
    }

    if (hasChildren) {
      for (let i = 0; i < itemDefinition.children.length; i++) {
        if (itemDefinition.children[i].route.name === menuReferer.name) {
          isSelected = true
          break
        }
      }
    }
  }

  const [open, setOpen] = useState(isSelected)

  const handleClick = () => {
    setOpen(!open)
  }

  if (itemDefinition.display === false) {
    return null
  }

  return (
    <Fragment>
      <ListItem
        button
        className={clsx(classes.item, {
          hasChildren,
          [classes.selectedItem]: isSelected,
        })}
        onClick={handleClick}
        component={itemDefinition.route ? Link : 'li'}
        to={itemDefinition.route}
        params={itemDefinition.routeParams}
        query={itemDefinition.routeQuery}
      >
        <ListItemIcon
          className={clsx(classes.itemIcon, {
            [classes.itemIconSelected]: isSelected,
          })}
        >
          {open && itemDefinition.iconSelected ? itemDefinition.iconSelected : itemDefinition.icon}
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2" color="inherit">
            {itemDefinition.title}
          </Typography>
        </ListItemText>

        {hasChildren && (
          <IconButton
            size="small"
            className={clsx({
              [classes.itemIconSelected]: isSelected,
            })}
          >
            {!open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </IconButton>
        )}
      </ListItem>
      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <SidebarMenu
            menuDefinition={itemDefinition.children}
            routeConfig={routeConfig}
            level={level + 1}
          />
        </Collapse>
      )}
    </Fragment>
  )
}

const SidebarMenu = ({ menuDefinition, routeConfig, level = 0 }) => (
  <List disablePadding>
    {menuDefinition.map((itemDefinition, index) => (
      <SidebarMenuItem
        key={index}
        itemDefinition={itemDefinition}
        routeConfig={routeConfig}
        level={level}
      />
    ))}
  </List>
)

export default SidebarMenu
