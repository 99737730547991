/**
 * Enum of different existing forms.
 * The FormType is used to save / get data on our form reducer
 */
const FormType = {
  REGISTER: 'FORM::REGISTER',
  LOGIN: 'FORM::LOGIN',
  PRO_LOGIN: 'FORM::PRO_LOGIN',
  GROUP_EDIT_GENERAL_INFOS: 'FORM::GROUP_EDIT_GENERAL_INFOS',
  RESTAURANT_LOGIN: 'FORM::RESTAURANT_LOGIN',
  RESTAURANT_CREATE: 'FORM::RESTAURANT_CREATE',
  FORGOT_PASSWORD: 'FORM::FORGOT_PASSWORD',
  PRO_FORGOT_PASSWORD: 'FORM::PRO_FORGOT_PASSWORD',
  USER_LIGHT: 'FORM::USER_LIGHT',
  INCOGNITO: 'FORM::INCOGNITO',
  CREDIT_CARD: 'FORM::CREDIT_CARD',
  COMPANY_ADD: 'FORM::COMPANY_ADD',
  COMPANY_EDIT: 'FORM::COMPANY_EDIT',
  BILLING_ENTITY_ADD: 'FORM::BILLING_ENTITY_ADD',
  BILLING_ENTITY_EDIT: 'FORM::BILLING_ENTITY_EDIT',
  ADMISSION_FEE_RULE_ADD: 'FORM::ADMISSION_FEE_RULE_ADD',
  PRICING_RULE_ADD: 'FORM::PRICING_RULE_ADD',
  SUBSIDY_RULE_ADD: 'FORM::SUBSIDY_RULE_ADD',
  SUBSIDY_RULE_EDIT: 'FORM::SUBSIDY_RULE_EDIT',

  CREATE_USER: 'FORM::CREATE_USER',
  EDIT_USER_ACCOUNT: 'FORM::EDIT_USER_ACCOUNT',
  EDIT_USER: 'FORM::EDIT_USER',
  ADD_USER_ADDRESS: 'FORM::ADD_USER_ADDRESS',
  EDIT_USER_ADDRESS: 'FORM::EDIT_USER_ADDRESS',

  USER_PROFILE: 'FORM::USER_PROFILE',
  USER_PROFILE_DIETARY: 'FORM::USER_PROFILE_DIETARY',

  PRO_ACCOUNT_EDIT: 'FORM::PRO_ACCOUNT_EDIT',
  PRO_ROLE_EDIT: 'FORM::PRO_ROLE_EDIT',

  HISTORY_SELECT: 'FORM::HISTORY_SELECT',

  CHECKOUT_PAYMENT_EMAIL: 'FORM::CHECKOUT::PAYMENT_EMAIL',

  SCHEDULED_ACTION: 'FORM::SCHEDULED_ACTION',

  GROUP_CREATE: 'FORM::GROUP::CREATE',
  GROUP_EDIT_USER_SETTINGS: 'FORM::GROUP::EDIT::USER_SETTINGS',
  GROUP_EDIT_FRONT_SETTINGS: 'FORM::GROUP::EDIT::FRONT_SETTINGS',
  GROUP_EDIT_FINANCE_SETTINGS: 'FORM::GROUP::EDIT::FINANCE_SETTINGS',
  GROUP_EDIT_PAYMENT_METHOD_DETAIL_SETTINGS: 'FORM::GROUP::EDIT::PAYMENT_METHOD_DETAIL_SETTINGS',
  GROUP_EDIT_USER_MANAGEMENT_SETTINGS: 'FORM::GROUP::EDIT::USER_MANAGEMENT_SETTINGS',

  API_CONSUMER__CREATE: 'FORM::API_CONSUMER::CREATE',
  API_CONSUMER__THEME: 'FORM::API_CONSUMER::THEME',
  API_CONSUMER__AUTHENTICATION: 'FORM::API_CONSUMER::AUTHENTICATION',
  API_CONSUMER__ANALYTICS: 'FORM::API_CONSUMER::ANALYTICS',
  API_CONSUMER__REGISTRATION: 'FORM::API_CONSUMER::REGISTRATION',
  API_CONSUMER__IDENTIFICATION: 'FORM::API_CONSUMER::IDENTIFICATION',
  API_CONSUMER__INCOGNITO_IDENTIFICATION: 'FORM::API_CONSUMER::INCOGNITO_IDENTIFICATION',
  API_CONSUMER__FRONT: 'FORM::API_CONSUMER::FRONT',
  API_CONSUMER__SECURITY: 'FORM::API_CONSUMER::SECURITY',
  API_CONSUMER__THIRDPARTY: 'FORM::API_CONSUMER::THIRDPARTY',
  API_CONSUMER__TEXTS: 'FORM::API_CONSUMER::TEXTS',
  API_CONSUMER__ORDER: 'FORM::API_CONSUMER::ORDER',
  API_CONSUMER__DIGITAL_MENU: 'FORM::API_CONSUMER::DIGITAL_MENU',
  API_CONSUMER__CATALOG: 'FORM::API_CONSUMER::CATALOG',
  API_CONSUMER__GENERAL: 'FORM::API_CONSUMER::API_CONSUMER__GENERAL',

  BO_PIM_SETTINGS_PRICING_CLUSTER_EDIT: 'FORM::BO_PIM_SETTINGS_PRICING_CLUSTER::EDIT',
  // Tip: NEW_FORM_ABOVE
}

export default FormType
