import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'

/**
 * @param permissionsParam can either be an array of permissions, or a permission string.
 * @param permissionsParam The permission to check. Example: 'booking.canEdit..'
 * @param proRole The permission will be checked for the given proRole. If null, we retrieve the
 * proRole set on the session.
 */
const hasPermissions = (permissionsParam, proRole = null) => {
  let permissions = []
  if (!isArray(permissionsParam)) {
    permissions = [permissionsParam]
  } else {
    permissions = permissionsParam
  }

  if (isNil(proRole)) {
    return false
  }

  let res = true
  permissions.forEach(permission => {
    try {
      const obj = permission.split('.').reduce((object, key) => {
        return object[key]
      }, proRole.permissions)

      /**
       * The permission is ok if the object equals true or is an object that contains child
       * permissions.
       */
      const hasPermission = !isNil(obj) && obj !== false

      if (!hasPermission) {
        res = false
        return false
      }
    } catch (e) {
      // do nothing
      res = false
      return false
    }
  })

  return res
}

export default hasPermissions
