import React from 'react'
import throttle from 'lodash/throttle'

export const ScrollSpyContext = React.createContext()

class ScrollSpyMenu extends React.PureComponent {
  static contextType = ScrollSpyContext

  constructor(props) {
    super(props)

    this.state = {
      activeAnchor: props.anchors[0],
    }
  }

  componentDidMount() {
    let { container, offset, throttleWait } = this.context

    container.onscroll = throttle(() => {
      const { askedAnchor } = this.state
      let lastAnchor = null

      for (let i = 0; i < this.props.anchors.length; i++) {
        if (
          document.querySelector(`#${this.props.anchors[i]}`)?.offsetTop - offset >
          container.scrollTop
        ) {
          const index = i - 1 < 0 ? 0 : i - 1
          lastAnchor = this.props.anchors[index]
          this.setActiveAnchor(this.props.anchors[index])
          break
        }
      }

      // if scroll has reached bottom scroll but have not yet activate the askedAnchor
      if (this.hasReachedBottom() && askedAnchor && !lastAnchor !== askedAnchor) {
        const offsetTop = document.getElementById(askedAnchor)?.offsetTop
        if (offsetTop >= container.scrollHeight - container.clientHeight) {
          this.setState({
            activeAnchor: askedAnchor,
          })
        }
      }
    }, throttleWait)
  }

  hasReachedBottom = () => {
    const { container } = this.context
    return container.scrollHeight - container.scrollTop === container.clientHeight
  }

  goToAnchor = anchor => {
    const { container, offset } = this.context
    const offsetTop = document.getElementById(anchor).offsetTop

    container.scrollTo(0, offsetTop - offset)

    this.setState({
      askedAnchor: anchor,
    })

    // if scroll is already at bottom, active anchor
    if (this.hasReachedBottom()) {
      this.setState({
        activeAnchor: anchor,
      })
    }
  }

  setActiveAnchor = anchor => {
    this.setState({
      activeAnchor: anchor,
    })
  }

  render() {
    return this.props.children({
      activeAnchor: this.state.activeAnchor,
      goToAnchor: this.goToAnchor,
      setActiveAnchor: this.setActiveAnchor,
    })
  }
}

class ScrollSpy extends React.PureComponent {
  constructor(props) {
    super(props)

    this.myRef = React.createRef()

    this.state = {
      offset: props.offset || 0,
      throttleWait: props.throttleWait || 100,
    }
  }

  componentDidMount() {
    this.setState({
      container: this.myRef.current,
    })
  }

  render() {
    return (
      <ScrollSpyContext.Provider value={this.state}>
        {React.cloneElement(this.props.children, {
          ref: this.myRef,
        })}
      </ScrollSpyContext.Provider>
    )
  }
}

ScrollSpy.contextType = ScrollSpyContext

ScrollSpy.Menu = ScrollSpyMenu

export default ScrollSpy
