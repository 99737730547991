import {
  createFormatter,
  createSubObjectFormatter,
} from 'pmt-utils/format'

import {
  formatBirthdate,
  formatGender,
  formatLocales,
} from './'

import {
  formatDietaryPreferences,
} from './dietaryPreferences'

export const formatCreateUser = createFormatter(
  formatBirthdate,
  formatGender,
  formatLocales,
  createSubObjectFormatter(
    'dietaryPreferences',
    formatDietaryPreferences,
  ),
)
