import { createAsyncApiCallAction } from 'pmt-modules/apiAsyncService/actions'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import PayoutsApi from 'pmt-modules/api/calls/PayoutsApi'

//
//
//

export const GetPayoutsListAction = createApiEnumAction('GetPayoutsListAction')

export const fetchPayoutsList = (rGroupId, data) =>
  createApiCallAction(GetPayoutsListAction, PayoutsApi.getPayoutsList(rGroupId, data), {
    rGroupId,
    data,
  })

//
//
//

export const GetPayoutsFailedListAction = createApiEnumAction('GetPayoutsFailedListAction')

export const fetchPayoutsFailedList = (rGroupId, data) =>
  createApiCallAction(GetPayoutsFailedListAction, PayoutsApi.getPayoutsFailedList(rGroupId, data), {
    rGroupId,
    data,
  })

//
//
//

export const postPayoutsReportGeneration = createAsyncApiCallAction(
  'PostPayoutsReportGenerationAction',
  'state.entities.payouts.reports.post',
  ({ rGroupId, fromDate }) => PayoutsApi.postPayoutsReports(rGroupId, fromDate),
  ({ rGroupId, fromDate }) => ({
    rGroupId,
    fromDate,
  })
)
