/**
 * Define the different steps of an user creation.
 * Some step could be skipped considering the permissions of the pro.
 *
 * Each step is handled by its own page.
 *
 * @type {Object}
 */
const CreateUserStep = {
  /**
   * getRoute('BO_USER_ADD')
   */
  USER: 'CREATE_USER_STEP::USER',

  /**
   * getRoute('BO_USER_ADD_STEP_ACCOUNT')
   *
   * Requires permissions for catering business
   */
  ACCOUNT: 'CREATE_USER_STEP::ACCOUNT',

  //
  // Insert new steps below
  // Note: You will have to change the actions/redirectToUserCreationThirdStep func
  //

  /*
   * Must be the last step.
   * We redirect to the created user page
   */
  REDIRECT: 'CREATE_USER_STEP::REDIRECT_TO_USER_PAGE',
}

export default CreateUserStep
