import { createSharedListSelectors } from 'pmt-modules/redux/sharedList'
import { formatProAccountsList } from './format'

const getState = state => state.entities.proAccountsList
const getRestaurantsGroupId = (state, props) => props.restaurantsGroupId

const selectors = createSharedListSelectors({
  getState,
  getObjectId: getRestaurantsGroupId,
  formatter: formatProAccountsList,
})

export const makeGetProAccountsList = selectors.makeGetData

export const makeGetProAccountsListError = selectors.makeGetError

export const makeIsFetchingProAccountsList = selectors.makeIsFetching

export const makeGetProAccountsListPaging = selectors.makeGetPaging
