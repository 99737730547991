import React, { useMemo } from 'react'
import isFunction from 'lodash/isFunction'
import { findIndexOnArray } from 'pmt-utils/array'
import clsx from 'clsx'

import { Link } from 'pmt-modules/routing'

import Divider from 'pmt-ui/Divider'
import Paper from 'pmt-ui/Paper'
import Grid from 'pmt-ui/Grid'
import Tabs, { Tab } from 'pmt-ui/Tabs'
import ScrollSpy from 'pmt-ui/ScrollSpy'
import Typography from 'pmt-ui/Typography'
import { withStyles, withTheme } from 'pmt-ui/styles'

import Ariane from './Ariane'

const styles = theme => ({
  // improve rendering of a global LoadingBlockWrapper
  rootContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.pmt.sidebar.width}px)`,
    },
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(6),
    minHeight: `calc(100% - ${theme.spacing(6)}px)`,
    scrollBehavior: 'smooth',
    overflowY: 'auto',
  },
  rootWithV2Design: {
    background: theme.pmt.colors.backgroundGrey,
  },
  content: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  pageTitleArea: {
    marginBottom: theme.spacing(4), // + 8 padding bottom of the Grid container
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  pageTitleAreaSmall: {
    marginBottom: theme.spacing(0),
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    color: theme.pmt.colors.pageTitleColor,
  },
  pageSubtitle: {
    fontSize: 16,
    color: theme.pmt.colors.pageTitleColor,
    whiteSpace: 'pre-wrap',
  },
  noHeaderSpace: {
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  pageAction: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pageContentBody: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    flexGrow: 1,
  },
  tab: {},
})

const PageContent = ({
  routeConfig,
  arianeProps,
  children,
  pageTitle,
  smallTitleArea = false,
  bigActionArea = false,
  pageSubtitle,
  pageAction,
  classes,
  tabsConfig,
  theme,
}) => {
  const tabs = useMemo(() => {
    if (!tabsConfig) {
      return null
    }
    // gives possibility to make tabs a function. This is usefull when we want to filter tabs
    // depending of the user's permissions.
    // Otherwise, the tabs data is generated BEFORE the permissions has been loaded, making the tabs
    // never appearing.
    // See pages/finance/payouts/config/tabs.js
    if (isFunction(tabsConfig.tabs)) {
      return tabsConfig.tabs()
    }
    return tabsConfig.tabs
  }, [tabsConfig])

  const tabsValue = useMemo(() => {
    if (!tabsConfig) {
      return null
    }
    return (
      tabsConfig.selectedTab ??
      findIndexOnArray(tabs, tab => tab.route.name === routeConfig.definition.name)
    )
  }, [tabsConfig, tabs, routeConfig.definition.name])

  return (
    <div className={classes.rootContainer}>
      <ScrollSpy
        //offset of header height
        offset={theme.pmt.toolbar.dense.minHeight}
      >
        <div
          className={clsx(classes.root, {
            [classes.rootWithV2Design]: routeConfig.props.withV2Design,
          })}
        >
          <Ariane routeConfig={routeConfig} arianeProps={arianeProps} />

          {!routeConfig.props.withV2Design && <Divider className="u-marginBottom10" />}

          {tabsConfig && (
            <Paper square>
              <Tabs
                className={classes.tabs}
                indicatorColor="primary"
                textColor="primary"
                value={tabsValue}
              >
                {tabs.map(({ label, route, routeParams, ...props }) => (
                  <Tab
                    key={`tab-${label}`}
                    component={Link}
                    label={label}
                    {...props}
                    className={classes.tab}
                    to={route}
                    params={routeParams}
                  />
                ))}
              </Tabs>
            </Paper>
          )}

          <div className={classes.content}>
            <Grid
              container
              spacing={2}
              className={clsx(classes.pageTitleArea, {
                [classes.pageTitleAreaSmall]: smallTitleArea,
              })}
            >
              {pageTitle && (
                <Grid item xs={12} sm={pageAction ? (bigActionArea ? 4 : 8) : 12}>
                  {pageTitle && (
                    <Typography component="h1" className={classes.pageTitle}>
                      {isFunction(pageTitle) ? pageTitle() : pageTitle}
                    </Typography>
                  )}
                  {pageSubtitle &&
                    (isFunction(pageSubtitle) ? (
                      pageSubtitle()
                    ) : (
                      <Typography component="div" className={classes.pageSubtitle}>
                        {pageSubtitle}
                      </Typography>
                    ))}
                </Grid>
              )}

              {pageAction && (
                <Grid item xs={12} sm={bigActionArea ? 8 : 4} className={classes.pageAction}>
                  {isFunction(pageAction) ? pageAction() : pageAction}
                </Grid>
              )}

              {/* Add space if there is no pageTitle nor action */}
              {!pageTitle && !pageAction && <Grid item xs={12} className={classes.noHeaderSpace} />}
            </Grid>

            <div className={classes.pageContentBody}>{children}</div>
          </div>
        </div>
      </ScrollSpy>
    </div>
  )
}

export default withTheme(withStyles(styles)(PageContent))
