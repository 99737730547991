export const ScheduledActionFrequency = {
  DAILY: 'DAILY',
}

export const ScheduledActionStatus = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
}

export const ScheduledActionType = {
  NOTIFY_LOW_BALANCE: {
    value: 'NotifyLowBalance',
    className: 'com.paymytable.userAccountBalance.lowBalance.NotifyLowBalanceScheduledAction',
  },
}
