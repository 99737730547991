import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

const getData = state => state.entities.proForgotPassword

export const getForgotPasswordError = createSelector([getData], data => {
  const error = data.get('error')
  if (isNil(error)) {
    return null
  }
  return error.toJS()
})

export const getForgotPasswordData = createSelector([getData], data => {
  const error = data.get('data')
  if (isNil(error)) {
    return null
  }
  return error.toJS()
})

export const getForgotPasswordIsFetching = createSelector([getData], data => {
  return data.get('isFetching', false)
})
