import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import PaymentApi from 'pmt-modules/api/calls/PaymentApi'

//
//
//

export const FetchPaymentAction = createApiEnumAction('FetchPaymentAction')

export const fetchPayment = paymentId =>
  createApiCallAction(FetchPaymentAction, PaymentApi.get(paymentId), {
    paymentId,
  })
