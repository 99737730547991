import { combineReducers } from 'redux'

import { createSimpleReducer } from 'pmt-modules/redux'

import {
  GetScheduledActionActions,
  PostScheduledActionActions,
  PutScheduledActionActions,
  RunScheduledActionActions,
} from './actions'
import { updateArrayObject } from 'pmt-utils/array'

export * from './actions'
export * from './constants'
export * from './selectors'
export * from './utils'

export const scheduledActionReducer = combineReducers({
  get: createSimpleReducer(GetScheduledActionActions, {
    [PostScheduledActionActions.SUCCESS]: (state, action) =>
      state.updateIn(['data', 'data'], dataArray => dataArray.push(action.response)),

    [PutScheduledActionActions.SUCCESS]: (state, action) => {
      return state.updateIn(['data', 'data'], data => {
        let dataArray = data.toJS ? data.toJS() : data
        dataArray = updateArrayObject(dataArray, action.response, action.response.id)
        return dataArray
      })
    },
  }),
  post: createSimpleReducer(PostScheduledActionActions),
  put: createSimpleReducer(PutScheduledActionActions),
  run: createSimpleReducer(RunScheduledActionActions),
})
