import ApiEndpoints from '../config/ApiEndpoints'

const EntityHistoryApi = {
  getDetail: entityHistoryId => ({
    endpoint: ApiEndpoints.ENTITY_HISTORY,
    type: 'GET',
    params: {
      entityHistoryId,
    },
  }),

  getList: (entityType, entityId, cursor, limit) => ({
    endpoint: ApiEndpoints.ENTITY_HISTORY_LIST,
    type: 'GET',
    params: {},
    query: {
      entityId,
      entityType,
      cursor,
      limit,
    },
  }),

  getForSession: sessionId => ({
    endpoint: ApiEndpoints.ENTITY_HISTORY_FOR_SESSION,
    type: 'GET',
    params: {
      sessionId,
    },
  }),
}

export default EntityHistoryApi
