const PimPermissions = {
  // Init PIM and edit its settings
  SETTINGS_ACCESS: 'pim.settings',
  SETTINGS_EDIT: 'pim.settings.canEdit',

  // Permissions to see and edit the data of a PIM
  //  (menus, revisions, syncs, items, itemparts, categories, hierarchies, sale channels, medias, tags, custom data, custom
  //  texts, level configurations, store configurations, ...)
  //  includes syncs, as syncs are "automatized editions" of the PIM data
  DATA_ACCESS: 'pim.data',
  DATA_EDIT: 'pim.data.canEdit',

  // Catalog generation and deployment
  CATALOG_ACCESS: 'pim.catalog',
  CATALOG_EDIT: 'pim.catalog.canEdit',
}

export default PimPermissions
