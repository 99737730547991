import ApiEndpoints from '../config/ApiEndpoints'

const ProNotificationSettingApi = {
  getIdentities: () => ({
    endpoint: ApiEndpoints.PRO_NOTIFICATION_IDENTITIES,
    type: 'GET',
  }),

  getProNotifications: restaurantsGroupId => ({
    endpoint: ApiEndpoints.PRO_NOTIFICATIONS,
    type: 'GET',
    query: {
      restaurantsGroupId,
    },
  }),

  post: proNotification => ({
    endpoint: ApiEndpoints.PRO_NOTIFICATIONS,
    type: 'POST',
    body: proNotification,
  }),

  getProNotification: proNotificationSettingId => ({
    endpoint: ApiEndpoints.PRO_NOTIFICATION,
    type: 'GET',
    params: {
      proNotificationSettingId,
    },
  }),

  patch: (proNotificationSettingId, patchData) => ({
    endpoint: ApiEndpoints.PRO_NOTIFICATION,
    type: 'PATCH',
    params: {
      proNotificationSettingId,
    },
    body: patchData,
  }),
}

export default ProNotificationSettingApi
