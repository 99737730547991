import React from 'react'

import { getRoute } from 'pmt-modules/routing'

import BackofficePage from '../BackofficePage'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

/**
 * Fake BackofficePage to be displayed while loading the chunk of the real page
 * see config/routes
 */
const PageLoading = ({ route, classes }) => (
  <BackofficePage
    loading={false}
    // Backoffice page requires a route. Give him a random one
    route={getRoute('PAGE_LOADING')}
    arianeProps={{
      templateValues: {},
      routes: {},
    }}
  >
    <LoadingBlockWrapper show debug="PageLoading" />
  </BackofficePage>
)

export default PageLoading
