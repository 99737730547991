import { tr } from 'pmt-modules/i18n'
import React from 'react'

import moment from 'moment'

import DateField from 'pmt-ui/DateField'

const createHandleChangeDateRange = (options, onChangeOptions) => dateRange => {
  if (options.fromTime !== dateRange[0].valueOf() || options.toTime !== dateRange[1].valueOf()) {
    onChangeOptions({
      ...options,
      ...{
        fromTime: dateRange[0],
        toTime: dateRange[1],
      },
    })
  }
}

const DateRangeView = ({ options, children, onChangeOptions }) => (
  <DateField
    mode={DateField.Mode.CALENDAR}
    label={tr('global.statistics.date_range.label')}
    value={[moment(options.fromTime).startOf('day'), moment(options.toTime).endOf('day')]}
    onChange={createHandleChangeDateRange(options, onChangeOptions)}
    options={{
      mode: 'range',
    }}
  >
    {children}
  </DateField>
)

export default DateRangeView
