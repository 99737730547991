import ApiEndpoints from '../config/ApiEndpoints'

const FeedbackApi = {
  getFeedbackCampaign: feedbackCampaignId => ({
    endpoint: ApiEndpoints.FEEDBACK_CAMPAIGN,
    type: 'GET',
    params: {
      feedbackCampaignId,
    },
    query: {
      loadFeedbackForm: true,
    },
  }),

  exportFeedbackCampaign: feedbackCampaignId => ({
    endpoint: ApiEndpoints.FEEDBACK_CAMPAIGN_EXPORT,
    type: 'POST',
    params: {
      feedbackCampaignId,
    },
    body: {
      directReport: false,
      feedbackCampaignId,
    },
  }),

  getFeedbackCampaignList: (restaurantsGroupId, cursor, limit) => ({
    endpoint: ApiEndpoints.FEEDBACK_CAMPAIGNS,
    type: 'GET',
    params: {},
    query: {
      restaurantsGroupId,
      cursor,
      limit,
    },
  }),

  getFeedbackAnswer: (feedbackAnswerToken, temporaryScore = null) => {
    const request = {
      endpoint: ApiEndpoints.FEEDBACK_ANSWER_TOKEN,
      type: 'GET',
      params: {
        feedbackAnswerToken,
      },
      query: {
        setFormOpened: true,
      },
    }

    if (temporaryScore) {
      request.query.temporaryScore = temporaryScore
    }
    return request
  },

  putFeedbackAnswer: (token, feedbackAnswer) => ({
    endpoint: ApiEndpoints.FEEDBACK_ANSWER_TOKEN,
    type: 'PUT',
    params: {
      feedbackAnswerToken: token,
    },
    body: feedbackAnswer,
  }),

  getFeedbackResumeForObject: (objectType, objectId) => ({
    endpoint: ApiEndpoints.FEEDBACK_RESUME_FOR_OBJECT,
    type: 'GET',
    params: {
      objectType,
      objectId,
    },
  }),

  getFeedbackAnswerUser: (userId, limit, cursor) => ({
    endpoint: ApiEndpoints.FEEDBACK_ANSWERS_USER,
    type: 'GET',
    query: {
      cursor,
      limit,
    },
    params: {
      userId,
    },
  }),

  getFeedbackAnswers: (feedbackCampaignId, limit, cursor) => ({
    endpoint: ApiEndpoints.FEEDBACK_ANSWERS,
    type: 'GET',
    query: { cursor, limit },
    params: { feedbackCampaignId },
  }),
}

export default FeedbackApi
