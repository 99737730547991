import { tr } from 'pmt-modules/i18n'
import React from 'react'

import get from 'lodash/get'

import { getRoute } from 'pmt-modules/routing'

import {
  Security,
  //AdminPermissions,
  ApiConsumerPermissions,
  AppInstancePermissions,
  AuditPermissions,
  CrmPermissions,
  ProRolePermissions,
  ProAccountPermissions,
  MediaPermissions,
  OrderPermissions,
  FinancePermissions,
  DocumentationPermissions,
  HistoryPermissions,
  FeedbackPermissions,
  ProEventNotificationsPermissions,
  RestaurantsGroupPermissions,
} from 'pmt-modules/bo'

import { createPimSidebarItems } from '../../../config/routes-pim'

import { GroupType } from 'pmt-modules/restaurantsGroup'

import SidebarMenu from './SidebarMenu'
import Drawer from 'pmt-ui/Drawer'
import { withStyles } from 'pmt-ui/styles'

import AccountCardDetailsIcon from 'pmt-ui/svg-icons/communication/account-card-details'
import DashboardIcon from 'pmt-ui/svg-icons/action/dashboard'
import HomeIcon from 'pmt-ui/svg-icons/action/home'
import DevicesIcon from '@material-ui/icons/Devices'
import AuditIcon from 'pmt-ui/svg-icons/other/audit'
import StoreIcon from 'pmt-ui/svg-icons/maps/store'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import AdminPermissions from 'pmt-modules/bo/security/permissions/AdminPermissions'
import PimPermissions from 'pmt-modules/bo/security/permissions/PimPermissions'

const getMenuDefinition = props =>
  [
    {
      title: tr('sidebar.menu.home'),
      icon: <HomeIcon color={'inherit'} />,
      route: getRoute('HOME'),
      routeParams: {},
    },
    {
      title: tr('sidebar.menu.dashboard'),
      icon: <DashboardIcon color={'inherit'} />,
      display:
        Security.assert(OrderPermissions.ACCESS) ||
        Security.assert(FinancePermissions.ACCESS) ||
        Security.assert(RestaurantsGroupPermissions.ORDER_GENERAL_STATS) ||
        Security.assert(RestaurantsGroupPermissions.DEPOSIT_GENERAL_STATS),
      children: [
        {
          title: tr('sidebar.menu.dashboard.order'),
          icon: null,
          route: getRoute('ORDER_ON_GOING'),
          routeParams: {},
          display: Security.assert(OrderPermissions.ACCESS),
        },
        {
          title: tr('sidebar.menu.dashboard.payment'),
          icon: null,
          route: getRoute('PAYMENT_LIST'),
          routeParams: {},
          display: Security.assert(FinancePermissions.ACCESS),
        },
        {
          title: tr('sidebar.menu.dashboard.order.stats'),
          icon: null,
          route: getRoute('BO_STATISTICS_ORDER'),
          routeParams: {},
          display: Security.assert(RestaurantsGroupPermissions.ORDER_GENERAL_STATS),
        },

        {
          title: tr('sidebar.menu.dashboard.deposit.stats'),
          icon: null,
          route: getRoute('BO_STATISTICS_TOP_UP'),
          routeParams: {},
          display: Security.assert(RestaurantsGroupPermissions.DEPOSIT_GENERAL_STATS),
        },

        {
          title: tr('sidebar.menu.errors'),
          icon: null,
          route: getRoute('ERRORS_LIST'),
          routeParams: {},
          display: Security.assert(OrderPermissions.ACCESS),
        },
      ],
    },

    {
      title: tr('sidebar.pim.title'),
      icon: <FastfoodIcon color={'inherit'} />,
      display:
        Security.assert(PimPermissions.DATA_ACCESS) &&
        get(props.selectedRGroup, 'type', null) === GroupType.USER,
      children: [...createPimSidebarItems()],
    },

    {
      title: tr('sidebar.menu.crm'),
      icon: <AccountCardDetailsIcon color={'inherit'} />,
      display:
        (Security.assert(CrmPermissions.ACCESS) ||
          Security.assert(FeedbackPermissions.ACCESS_CAMPAIGNS)) &&
        get(props.selectedRGroup, 'type', null) === GroupType.USER,
      children: [
        {
          title: tr('sidebar.menu.crm.clients'),
          icon: null,
          display: Security.assert(CrmPermissions.ACCESS),
          route: getRoute('BO_USER_LIST'),
          routeParams: {},
        },
        {
          title: tr('sidebar.menu.feedback'),
          icon: null,
          display: Security.assert(FeedbackPermissions.ACCESS_CAMPAIGNS),
          route: getRoute('BO_FEEDBACK_CAMPAIGN_LIST'),
          routeParams: {},
        },
      ],
    },
    {
      title: tr('sidebar.menu.finance'),
      icon: <AccountBalanceIcon color={'inherit'} />,
      display: Security.assert(FinancePermissions.ACCESS),
      children: [
        {
          title: tr('sidebar.menu.finance.accounting'),
          icon: null,
          route: getRoute('PAYOUTS'),
          routeParams: {},
        },
        {
          title: tr('sidebar.menu.finance.allOnGoingReports'),
          icon: null,
          route: getRoute('PAYOUTS_ALL_ON_GOING_REPORTS'),
          routeParams: {},
          display: Security.assert(AdminPermissions.ACCESS),
        },
      ],
    },

    {
      title: tr('sidebar.menu.media'),
      icon: <PhotoLibraryIcon />,
      display:
        Security.assert(MediaPermissions.ACCESS) &&
        get(props.selectedRGroup, 'type', null) === GroupType.USER,
      children: [
        {
          title: tr('sidebar.menu.media.library'),
          icon: null,
          route: getRoute('BO_MEDIA_LIBRARY'),
          display: props.selectedRGroup !== null,
          routeParams: { groupId: props.selectedRGroup && props.selectedRGroup.id },
        },
        {
          title: tr('sidebar.menu.media.add'),
          icon: null,
          display: Security.assert(MediaPermissions.CREATE),
          route: getRoute('BO_MEDIA_UPLOAD'),
        },
      ],
    },
    {
      title: tr('sidebar.menu.iam'),
      icon: <SupervisedUserCircleIcon />,
      display:
        Security.assert(ProAccountPermissions.ACCESS) ||
        Security.assert(ProRolePermissions.ACCESS) ||
        Security.assert(ProEventNotificationsPermissions.ACCESS),
      children: [
        {
          title: tr('sidebar.menu.iam.users'),
          icon: null,
          display: Security.assert(ProAccountPermissions.ACCESS),
          route: getRoute('BO_PRO_ACCOUNT_LIST'),
        },
        {
          title: tr('sidebar.menu.iam.roles'),
          icon: null,
          display: Security.assert(ProRolePermissions.ACCESS),
          route: getRoute('BO_PRO_ROLE_LIST'),
        },
        {
          title: tr('sidebar.menu.iam.pro_event_notifications_settings'),
          icon: null,
          display: Security.assert(ProEventNotificationsPermissions.ACCESS),
          route: getRoute('PRO_NOTIFICATIONS_SETTINGS_LIST'),
        },
      ],
    },
    {
      title: tr('sidebar.menu.stores'),
      icon: <StoreIcon />,
      display:
        Security.assert(ProAccountPermissions.ACCESS) || Security.assert(ProRolePermissions.ACCESS),
      children: [
        {
          title: tr('sidebar.menu.stores.group_infos'),
          icon: null,
          route: getRoute('BO_GROUPS_INFOS'),
          display: props.selectedRGroup !== null,
          routeParams: { groupId: props.selectedRGroup && props.selectedRGroup.id },
        },
        {
          title: tr('sidebar.menu.stores.group_stores'),
          icon: null,
          route: getRoute('BO_GROUPS_STORES'),
          display: props.selectedRGroup !== null,
          routeParams: { groupId: props.selectedRGroup && props.selectedRGroup.id },
        },
      ],
    },
    {
      title: tr('sidebar.menu.apiConsumers'),
      icon: <DevicesIcon />,
      display: Security.assert(ApiConsumerPermissions.ACCESS),
      route: getRoute('API_CONSUMER_LIST'),
    },
    {
      title: tr('sidebar.menu.appInstances'),
      icon: <SwapHorizIcon />,
      display:
        Security.assert(AppInstancePermissions.ACCESS) &&
        get(props.selectedRGroup, 'type', null) === GroupType.USER,
      route: getRoute('ZDPMID_CONFIGURATION_LIST'),
    },
    {
      title: tr('sidebar.menu.audit'),
      icon: <AuditIcon />,
      display:
        Security.assert(AuditPermissions.ACCESS) || Security.assert(HistoryPermissions.ACCESS),
      children: [
        {
          title: tr('sidebar.menu.audit.history'),
          icon: null,
          display: Security.assert(HistoryPermissions.ACCESS),
          route: getRoute('BO_ENTITY_SELECT'),
        },
      ],
    },
    {
      title: tr('documentation.main_title'),
      icon: <MenuBookIcon />,
      display: Security.assert(DocumentationPermissions.ACCESS),
      children: [
        // useful for us to see all the endpoints at once
        // but third parties / POS will see the relevant endpoints inside the Third-Party / POS pages only
        {
          title: tr('documentation.all_endpoints.title'),
          icon: null,
          display: Security.assert(AdminPermissions.ACCESS),
          route: getRoute('API_DOCUMENTATION_ALL_ENDPOINTS'),
        },
      ],
    },
  ].filter(Boolean)

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: theme.pmt.sidebar.width,
    height: '100%!important',
    backgroundColor: theme.pmt.sidebar.backgroundColor,
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
    },
    paddingTop: 48,
    paddingBottom: 50,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  drawerInner: {
    flexGrow: 1,
    marginTop: 10,
    // avoid version beeing too close
    marginBottom: 10,
  },
})

const Sidebar = ({ classes, isOpen, type, routeConfig, ...otherProps }) => (
  <Drawer
    variant="persistent"
    type={type}
    classes={{
      paper: classes.drawerPaper,
    }}
    open={isOpen}
  >
    <div className={classes.drawerInner}>
      <SidebarMenu routeConfig={routeConfig} menuDefinition={getMenuDefinition(otherProps)} />
    </div>
  </Drawer>
)

export default withStyles(styles)(Sidebar)
