import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'

import formatImporterData from './formatImporterData'
import formatRowInformation from './formatRowInformation'
import formatErrorDescription from './formatErrorDescription'

const formatRunnerErrorRowInformation = (runnerError) => {
  runnerError.data.rowInformation = formatRowInformation(runnerError.data.rowInformation)
  return runnerError
}

const formatRunnerErrorImporterData = (runnerError) => {
  runnerError.data.importerData = formatImporterData(runnerError.data.importerData)
  return runnerError
}

const formatRunnerError = createFormatter(
  formatRunnerErrorRowInformation,
  formatRunnerErrorImporterData,
  createSubObjectFormatter('errorDescription', formatErrorDescription),
)

const formatRunnerErrors = createListFormatter(formatRunnerError)

export default formatRunnerErrors
