import { createSharedListReducer } from 'pmt-modules/redux/sharedList'

import { LoadProAccountsListAction } from './actions'

export * from './selectors'
export * from './actions'

//
// Retain the proAccounts for a restaurants group
//

export const proAccountsListReducer = createSharedListReducer(
  LoadProAccountsListAction,
  action => action.data.restaurantsGroupId,
  {
    pagination: true,
  }
)
