import createAction from '../redux/createAction'


//
// Generic action that is run when one or multiple files where uploaded (or remove) with firebase.
// Each file upload is defined by its `uploadType`, from the `FileUploadType` enum.
//

export const UPLOADED_FILES = 'UPLOAD::UPLOADED_FILES'

/**
 * @param  {[type]} uploadType see FileUploadType
 * @param  {[type]} files      uploadedFiles data given by firebase
 */
export const notifyUploadedFiles = (uploadType, files) => createAction(
  UPLOADED_FILES,
  {
    uploadType,
    files,
  }
)
