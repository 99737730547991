import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import MediaApi from 'pmt-modules/api/calls/MediaApi'

export const GetMediaActions = createApiEnumAction('MEDIA::GET')
export const GetMediaListActions = createApiEnumAction('MEDIA::GET_LIST')
export const PostMediaActions = createApiEnumAction('MEDIA::POST')
export const OpenMediaActions = createApiEnumAction('MEDIA::OPEN')

export const getMedia = (rGroupId, mediaId) =>
  createApiCallAction(GetMediaActions, MediaApi.getMedia(rGroupId, mediaId), {
    rGroupId,
    mediaId,
  })

export const getMediaList = (rGroupId, opts) =>
  createApiCallAction(GetMediaListActions, MediaApi.getMediaList(rGroupId, opts), {
    rGroupId,
    opts,
  })

export const postMedia = (media, params) =>
  createApiCallAction(PostMediaActions, MediaApi.post(media, params), {
    media,
    params,
  })

export const openMedia = (rGroupId, mediaId) =>
  createApiCallAction(OpenMediaActions, MediaApi.openMedia(rGroupId, mediaId), {
    rGroupId,
    mediaId,
  })
