import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { getRoute, redirectTo } from 'pmt-modules/routing'

import { PostApiConsumerAction } from './actions'

const onApiConsumerCreatedMiddleware = createMiddleware(
  PostApiConsumerAction.SUCCESS,
  ({ dispatch, action }) => {
    dispatch(
      redirectTo(
        getRoute('API_CONSUMER_DETAIL'),
        {
          apiConsumerId: action.response.id,
        },
        {
          // notify page we just created the api consumer.
          created: true,
        }
      )
    )
  }
)

export const apiConsumerConfigurationMiddlewares = [onApiConsumerCreatedMiddleware]
