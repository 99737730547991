/**
 * The ProAccount password minimum length.
 */
export const PASSWORD_MIN_LENGTH = 6

/**
 * The ProAccount data sent to the Api have a `domains` array, describing the ProAccount domains, and
 * the action to do on it.
 * This action is defined by the following enum:
 */
export const DomainAction = {
  // add the domain
  // if the domain was disabled, we enable it
  // otherwise, we create the domain
  ADD: 'ADD',
  // disable the domain
  DEACTIVATE: 'DEACTIVATE',
  // activate the domain previously disabled
  ACTIVATE: 'ACTIVATE',
  // do nothin (default value when we are in edit mode)
  NONE: 'NONE',
}
