import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import {
  createFormatter,
  createListFormatter,
  createSubObjectFormatter,
  createDeepFormatter,
} from 'pmt-utils/format'

import {
  hasAllChildrenWithPermission,
  hasOneChildrenWithCreatorPermission,
  hasAllChildrenWithReadPermission,
  hasAllChildrenWithCreatePermission,
  hasAllChildrenWithEditPermission,
  hasAllChildrenWithArchivedPermission,
  hasOneChildrenWithPermission,
} from '../../utils'

const formatIsRead = permission => {
  // isRead set to true when there is sub-permissions
  // do not override if already set: some permissions are final and are read permissions so they
  // will already have a `isRead` set to true
  if (isNil(permission.isRead)) {
    permission.isRead = !isEmpty(permission.permissions)
  }

  return permission
}

const formatHadPermission = section => {
  // set hadPermission, true if the section had the permission when we began to edit it
  // used to display the sections that have a permission when we arrive on the page.
  section.hadPermission = section.hadPermission || section.hasPermission
  return section
}

const formatAllPermissions = section => {
  // !!! when the current permission does no have the permission, and the creator does
  // not have it too, `hasAllChildrenWithPermission` will return true
  section.hasAllChildrenWithPermission = hasAllChildrenWithPermission(section)

  section.hasOneChildrenWithCreatorPermission = hasOneChildrenWithCreatorPermission(section)

  section.hasOneChildrenWithPermission = hasOneChildrenWithPermission(section)

  section.hasAllChildrenWithReadPermission = hasAllChildrenWithReadPermission(section)
  section.hasAllChildrenWithCreatePermission = hasAllChildrenWithCreatePermission(section)
  section.hasAllChildrenWithEditPermission = hasAllChildrenWithEditPermission(section)
  section.hasAllChildrenWithArchivedPermission = hasAllChildrenWithArchivedPermission(section)

  section.hasAllPermissions =
    section.hasPermission &&
    section.hasAllChildrenWithPermission &&
    // when the current permission does no have the permission, and the creator does
    // not have it too, `hasAllChildrenWithPermission` will return true
    section.hasOneChildrenWithCreatorPermission &&
    // when the creator has less permissions than the current permission,
    // hasAllChildrenWithPermission can return true. we need to check that the
    // creatorHasPermission
    section.creatorHasPermission

  return section
}

const formatPermissions = createDeepFormatter(createFormatter(formatIsRead), 'permissions')

// Note: we must keep the formatter order here.
const formatSection = createFormatter(formatPermissions, formatAllPermissions, formatHadPermission)

const formatRestaurantsGroup = proRole => {
  proRole.haveRestaurantsGroupDefined = !isNil(proRole.restaurantsGroupId)
  return proRole
}

export const formatEditProRole = createFormatter(
  formatRestaurantsGroup,
  createSubObjectFormatter(
    'permissions',
    createSubObjectFormatter('sections', createListFormatter(formatSection))
  )
)
