import React from 'react'
import PropTypes from 'prop-types'
import isNull from 'lodash/isNull'
import { connect } from 'react-redux'

import {
  makeGetUserCardUser,
  makeIsFetchingUserCardUser,
  makeGetUserAmountState,
  makeGetUserCardError,
} from '../../selectors'

import { fetchUserCard } from '../../actions'

/**
 * @specs N/A
 *
 * A HOC that fetch the user
 *
 * Requirements:
 * - userId
 *
 */
class UserCardContainer extends React.Component {
  componentWillMount() {
    if (isNull(this.props.user)) {
      this.loadUserCard(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.userId !== props.userId) {
      this.loadUserCard(nextProps)
    }
  }

  loadUserCard(props) {
    props.fetchUserCard(props.rGroupId, props.userId)
  }

  render() {
    const { user, userId, isFetchingUser, userAmountState, userCardError, children } = this.props

    return children({
      user,
      isFetchingUser,
      userAmountState,
      userCardError,
      userId,
      reloadUser: () => this.loadUserCard(this.props),
    })
  }
}

UserCardContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  rGroupId: PropTypes.string.isRequired,

  isFetchingUser: PropTypes.bool,
  user: PropTypes.object,
}

const makeMapStateToProps = (state, props) => {
  const getUserCardUser = makeGetUserCardUser()
  const getUserCardError = makeGetUserCardError()
  const isFetchingUserCardUser = makeIsFetchingUserCardUser()
  const getUserAmountState = makeGetUserAmountState()

  const mapStateToProps = (state, props) => ({
    user: getUserCardUser(state, props),
    userCardError: getUserCardError(state, props),
    isFetchingUser: isFetchingUserCardUser(state, props),
    userAmountState: getUserAmountState(state, props),
  })
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchUserCard,
  }
)(UserCardContainer)
