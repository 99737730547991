import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { withStyles } from 'pmt-ui/styles'
import Typography, { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {},
  row: {},
  cell: {
    padding: `${theme.spacing(1)}px 0`,
    display: 'flex',
  },
  label: {
    textTransform: 'uppercase',
    color: theme.pmt.colors.middlegrey,
    paddingRight: theme.spacing(3),
    fontSize: 11,
  },
  value: {},
})

/**
 * Display a simple table to display info
 */
const InfoTable = ({ datas, classes, className }) => {
  if (!datas.map) {
    debugger
  }
  return (
    <table className={clsx(className, classes.root)}>
      <tbody>
        {datas.map(
          (rowData, index) =>
            rowData && (
              <tr className={classes.row} key={index}>
                <td className={classes.cell}>
                  <Typography component="span" className={clsx(classes.label)}>
                    {rowData.label}
                  </Typography>
                </td>
                <td>
                  <TypographyCustom type="144" component="span" className={clsx(classes.value)}>
                    {rowData.value}
                  </TypographyCustom>
                </td>
              </tr>
            )
        )}
      </tbody>
    </table>
  )
}

InfoTable.propTypes = {
  /**
   * Array of rowData:
   * - label
   * - value
   */
  datas: PropTypes.array.isRequired,
}

export default withStyles(styles)(InfoTable)
