import invariant from 'invariant'
import isNaN from 'lodash/isNaN'
import { getLocale } from 'pmt-modules/i18n'
import { isKiosk } from 'pmt-modules/environment'

/**
 * Currency is set as global var
 * we may set this for example when we fetch the restaurant
 * Default still remains as EUR
 */
let _currency = 'EUR'

export const setCurrency = currency => (_currency = currency)

// deprecated, use formatPriceWithCurrency instead
// this will allow to have 123$ or $123 depending on the locale, and so on
export const getCurrencySymbol = (currency = _currency) => {
  switch (currency) {
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    case 'GBP':
      return '£'
    case 'CHF':
      return 'CHF'
    case 'BGN':
      return 'Лв'
    case 'ARS':
      return 'AR$'
    case 'RON':
      return 'lei'
    case 'MXN':
      return 'MX$'

    default:
      return currency
  }
}

/**
 * ISO_4217 format expected
 * @link https://www.wikiwand.com/en/ISO_4217
 */
export const Currencies = {
  EUR: 'EUR', // Euro => €
  USD: 'USD', // United State Dollar => $
  GBP: 'GBP', // Great Britain Pound => £
  CHF: 'CHF', // Swiss Franc => CHF
  BGN: 'BGN', // Bulgarian Leva => Лв
  ARS: 'ARS', // Argentine peso => AR$
  RON: 'RON', // Romanian Lei => lei
  MXN: 'MXN', // Mexican peso => MX$
  AED: 'AED', // Arab Emirates Dirham
  ISK: 'ISK', // Icelandic Króna
}

export const getFloorAmount = amount => (Math.floor(amount * 100) / 100).toFixed(2)

// this transforms the amount in a formatted string
export const getRoundAmount = amount => (Math.round(amount * 100) / 100).toFixed(2)

// this rounds the amount (but it's still a number)
export const getRoundAmountNumber = amount => Math.round(amount * 100) / 100

export const getCeilAmount = amount => (Math.ceil(amount * 100) / 100).toFixed(2)

// result sometimes goes from 9.7 to 969.9999999999999, so we round it.
export const getAmountInCents = amount => Math.round(amount * 100)

export const formatPriceWithCurrency = (price, currency = _currency) => {
  invariant(!isNaN(price), `${price} is not a number`)
  let locale = getLocale() || 'fr'
  if (currency === 'CHF') {
    // for swiss amounts, we want to be sure the amount will be formatted in the "swiss" way,
    // aka it-CH/de-CH/fr-CH, even if the user's browser is configured as  "it" or "it_IT"
    // (because swiss people don't bother to configure computer/browser with the country-related locale,
    // but complain when the formatting is not as they are used to)
    if (locale.startsWith('it')) {
      locale = 'it-CH'
    }
    if (locale.startsWith('fr')) {
      locale = 'fr-CH'
    }
    if (locale.startsWith('de')) {
      locale = 'de-CH'
    }
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    // kiosk is using chrome 65, which reached its end of support on April 24, 2018.
    currencyDisplay: isKiosk() ? 'symbol' : 'narrowSymbol', // to have $ and not US$ (= the daily-life symbol, and not the very-specific symbol). The ambiguity (between US$ and CA$, for example) should not be a problem, as the restaurant belongs to only one country, so the $ symbol is the one of this country
  }).format(price)
}

export const formatPrice = price => {
  const priceAsFloat = price !== null ? parseFloat(price) : 0
  return priceAsFloat.toLocaleString(getLocale() || 'fr', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const formatPercent = v => `${v} %`

export const convertMillionPrice = price => parseFloat(price / 1000000)

export const formatMillionPrice = price => formatPrice(convertMillionPrice(price))

export const formatMillionPriceWithCurrency = (price, currency = '€') =>
  `${formatMillionPrice(price)} ${currency}`

export const truncatePrice = price => {
  return parseFloat(price.toFixed(2))
}
