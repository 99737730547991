import Immutable from 'immutable'

import {
  GetProMeAction,
} from './actions'

export * from './actions'
export * from './selectors'
export * from './components'

const DEFAULT = Immutable.fromJS({
  proMe: null,
  isFetching: false,
  lastUpdated: null,
  error: null,
})

export const proMeReducer = (state = DEFAULT, action) => {

  switch (action.type) {
    case GetProMeAction.REQUEST:
      return state.merge({
        proMe: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetProMeAction.SUCCESS:
      return state.merge({
        proMe: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetProMeAction.FAILURE:
      return state.merge({
          proMe: null,
          isFetching: false,
          lastUpdated: new Date(),
          error: action.error,
      })

    default:
      return state
  }
}
