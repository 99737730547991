import { createSelector } from 'reselect'
import { createSimpleSelector } from '../redux'

const exportRestaurantsGroupSelectors = createSimpleSelector(
  state => state.entities.exportRestaurantsGroup
)

export const isFetchingExportRestaurantsGroup = exportRestaurantsGroupSelectors.isFetching
export const getExportRestaurantsGroupData = exportRestaurantsGroupSelectors.getData
export const getErrorsExportRestaurantsGroup = exportRestaurantsGroupSelectors.getError

export const isExportRestaurantsGroupRunning = createSelector(
  [getExportRestaurantsGroupData],
  data => {
    return data !== null
  }
)
