import createMiddleware from 'pmt-modules/redux/createMiddleware'

import { PMTFirebaseAuth, postPmtFirebaseAuth, authUserOnFirebase } from './actions'
import { getFirebase } from './index'

import { LOGOUT_PRO } from 'pmt-modules/authPro/actions'
import { BoActions } from 'pmt-modules/bo/actions'
import { parseJwt } from './utils'

import { saveToSession, getFromSession } from 'pmt-modules/storage'

// session storage key. Define for which domain we are currently logged in with firebase
const FIREBASE_CURRENT_DOMAIN_ID = 'FirebaseCurrentDomainId'

// TRICK: couldn't fix the bug where we ask two times in a row for a pmt firebase auth :(
// This occur mostly because firebase does not have a 'isFetching' when login the user, so we do not
// now if we are authenticate him are if we haven't yet.
let isRunningFirebaseAuth = false
const firebaseAuthUser = createMiddleware(authUserOnFirebase, ({ action, dispatch }) => {
  if (!isRunningFirebaseAuth) {
    isRunningFirebaseAuth = true
    dispatch(postPmtFirebaseAuth())
  }

  return createMiddleware.STOP_PROPAGATION
})

const boFirebaseLoginMiddleware = createMiddleware(PMTFirebaseAuth.SUCCESS, ({ next, action }) => {
  // https://firebase.google.com/docs/auth/web/auth-state-persistencei
  const firebase = getFirebase()
  firebase
    .auth()
    // Indicates that the state will only persist in the current session or tab, and will be cleared when the tab or window in which the user authenticated is closed. Applies only to web apps.
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      firebase
        .login({
          token: action.response.token,
        })
        .then(data => {
          const tokenData = parseJwt(action.response.token)
          const authTokenData = tokenData.claims
          const domainId = authTokenData.domainId

          // Define for which domain we are currently logged in with firebase on session
          // required by the boFirebaseRunLogoutMiddleware
          saveToSession(FIREBASE_CURRENT_DOMAIN_ID, domainId)

          next(action)
        })
        .finally(() => {
          isRunningFirebaseAuth = false
        })
    })
    .catch(() => {
      isRunningFirebaseAuth = false
    })

  return createMiddleware.STOP_PROPAGATION
})

/**
 * This middleware handles all the actions that should produce a firebase logout:
 * - When we logout the pro
 * - When the domain change
 */
const boFirebaseRunLogoutMiddleware = createMiddleware(
  [LOGOUT_PRO, BoActions.SET_SELECTED_DOMAIN],
  ({ action }) => {
    const firebase = getFirebase()

    let shouldLogout = true

    // if we set the selected domain, we do not want to logout if the domain has not changed
    if (action.type === BoActions.SET_SELECTED_DOMAIN) {
      const firebaseLoggedInDomainId = getFromSession(FIREBASE_CURRENT_DOMAIN_ID, null)
      const newDomainId = action.selectedDomain.id
      if (firebaseLoggedInDomainId !== null && firebaseLoggedInDomainId === newDomainId) {
        shouldLogout = false
      }
    }

    if (shouldLogout) {
      firebase
        .auth()
        .signOut()
        .then(
          function() {
            console.log('firebase: signed Out')
          },
          function(error) {
            console.error('firebase: Sign Out Error', error)
          }
        )
    }
  }
)

export const boFirebaseMiddlewares = [
  firebaseAuthUser,
  boFirebaseLoginMiddleware,
  boFirebaseRunLogoutMiddleware,
]
