import { actionTypes } from 'react-redux-firebase'
import Immutable from 'immutable'

export * from './actions'
export FileUploadType from './FileUploadType'
export * from './selectors'

const DEFAULT = Immutable.fromJS({
  isFetching: false,
  data: null,
  error: null,
})

export const uploadReducer = (state = DEFAULT, { type, ...action }) => {
  switch (type) {
    case actionTypes.FILE_UPLOAD_START:
      return state.merge({
        isFetching: true,
        data: null,
      })
    case actionTypes.FILE_UPLOAD_COMPLETE:
      return state.merge({
        isFetching: false,
        data: action,
      })
    default:
      return state
  }
}
