import { createSimpleSelector } from 'pmt-modules/redux'
import { formatSession } from './format'

const getSessionSelectors = createSimpleSelector(
  state => state.entities.audit.session,
  formatSession
)

export const isFetchingSession = getSessionSelectors.isFetching
export const getSessionData = getSessionSelectors.getData
export const getSessionError = getSessionSelectors.getError
