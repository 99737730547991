import Immutable from 'immutable'
import { combineReducers } from 'redux'

import { createSimpleReducer, createReducerPerObject } from 'pmt-modules/redux'

import {
  GetProAccountAction,
  PostProAccountAction,
  PutProAccountAction,
  RESET_DATA_ACTION,
} from './actions'

export * from './actions'
export * from './selectors'
// export * from './components'
export * from './constants'
export * from './middlewares'

//
//
//

export const proAccountPerObjectReducer = createReducerPerObject(
  GetProAccountAction,
  action => action.data.proAccountId
)

//
//
//

const resetData = (state, action) => state.set(Immutable.fromJS({}))

export const postProAccountReducer = createSimpleReducer(PostProAccountAction, {
  [RESET_DATA_ACTION]: resetData,
})

export const putProAccountReducer = createSimpleReducer(PutProAccountAction, {
  [RESET_DATA_ACTION]: resetData,
})

export const proAccountReducer = combineReducers({
  proAccount: proAccountPerObjectReducer,
  postProAccount: postProAccountReducer,
  putProAccount: putProAccountReducer,
})
