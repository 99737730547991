import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  comment: null,
  externalInfo: {
    badgeId: null,
    billingEntityId: null,
    billingEntityName: null,
    companyId: null,
    companyName: null,
    employeeGrantLevel: null,
    employeeId: null,
    pricingRuleId: null,
  },
}

// TODO: I18n
const validationRules = {
  externalInfo: {
    // badgeId: [
    // [ notEmpty, 'global.form.required' ],
    // ],
  },
}

export default createForm(FormType.EDIT_USER_ACCOUNT, validationRules, defaultData, devData, {})
