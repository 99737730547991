import { combineReducers } from 'redux'

import { createSimpleReducer } from '../redux'

import { GetDepositDetailAction, PutDepositDetailAction } from './actions'

export const depositDetailReducer = combineReducers({
  get: createSimpleReducer(GetDepositDetailAction),
  put: createSimpleReducer(PutDepositDetailAction),
})
