import forEach from 'lodash/forEach'
import { createFormatter } from 'pmt-utils/format'

import { rowKeysColumn, rowKeysLabel } from '../constants'

const formatVarName = (errorDescription) => {
  errorDescription.columnName = rowKeysColumn[errorDescription.varName] || null
  errorDescription.columnLabel = rowKeysLabel[errorDescription.varName] || null

  let columnIndex = 0
  forEach(rowKeysColumn, (value, varName) => {
    if (varName === errorDescription.varName) {
      return false
    }
    columnIndex += 1
  })
  errorDescription.columnIndex = columnIndex

  return errorDescription
}

const formatErrorDescription = createFormatter(
  formatVarName,
)

export default formatErrorDescription
