import { createContext } from 'react'

// redirectTo
// getRoute
// replaceWith
// goBack
// reloadTo
// openExternalLink
// redirectToExternal
// updatePageQueries
// getRoutingEnum
export const RoutingContext = createContext(null)
