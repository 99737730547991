/**
 * The mode of visibility for a pro role
 *
 * Note:
 * No children = empty 'visibility.restaurantsGroupIds' on API. On front we use NO_CHILDREN
 */
export const ProRoleVisibilityMode = {
  // Note: front only. Correspond to DEFINED_CHILDREN with empty array
  NO_CHILDREN: 'NO_CHILDREN',

  /**
   * All the children of the pro role's restaurants group can see the pro role
   */
  ALL_CHILDREN: 'ALL_CHILDREN',

  /**
   * Only defined children of the pro role's restaurants group can see the pro role
   * Those children are listed in {@link ProRoleVisibilityMode#restaurantsGroupId}
   */
  DEFINED_CHILDREN: 'DEFINED_CHILDREN',
}
