import { tr } from 'pmt-modules/i18n'
import { formatDate } from 'pmt-utils/date'

export const getFirebaseUploadPath = id => `/upload/media/${formatDate(null, 'YYYY-MM-DD')}/${id}`

export const MediaCategory = {
  PIM: 'PIM',
  RESTAURANTS_GROUP: 'RESTAURANTS_GROUP',
}

export const MediaType = {
  IMAGE: 'IMAGE',
}

export const getMediaCategoryLabel = category => {
  switch (category) {
    case MediaCategory.PIM:
      return tr('global.media.category.pim')
    case MediaCategory.RESTAURANTS_GROUP:
      return tr('global.media.category.restaurants_group')
    default:
      return category
  }
}
