import endsWith from 'lodash/endsWith'
import { createFormatter, createListFormatter } from 'pmt-utils/format'

import { formatRestaurantsGroupWithChildren } from 'pmt-modules/restaurantsGroup/format'

export const formatIsAdminMember = domain => {
  domain.isAdminMember = domain.proAccount && endsWith(domain.proAccount.email, '@paymytable.com')
  return domain
}

export const formatRestaurantsGroups = domain => {
  if (domain.restaurantsGroup) {
    domain.restaurantsGroup = formatRestaurantsGroupWithChildren(domain.restaurantsGroup)
  }
  return domain
}

export const formatActivated = domain => {
  // not activated domain aren't return by the API, but we handle it here juste in case
  // verify false since the api could not return the `activated`
  // null `activated` is considered as activated
  domain.activated = domain.activated === false
  return domain
}

export const formatDomain = createFormatter(
  formatRestaurantsGroups,
  formatActivated,
  formatIsAdminMember
)

export const formatDomainList = createListFormatter(formatDomain)
