import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

/**
 * Generate a simple selector with basic isFetching / getData / getErrors but with a make prefix.
 * @param  {function} getState  Function to retrieve entity from state
 *                              state => state.entities.ENTITY_NAME
 * @param  {function} formatData  Callback function to format data
 * @return {object}             Object with 3 selectors
 *                              isFetching / getData / getErrors
 
 */
const createSelectorsPerObject = (getState, getProp, formatData = null) => ({
  makeIsFetching: () =>
    createSelector([getState, getProp], (dataState, prop) => {
      if (isNil(dataState)) {
        return false
      }

      const listObject = dataState.get(prop)

      return isNil(listObject) ? false : listObject.get('isFetching') || false
    }),

  makeGetData: () =>
    createSelector([getState, getProp], (dataState, prop) => {
      if (isNil(dataState)) {
        return null
      }

      const listObject = dataState.get(prop)

      if (isNil(listObject)) {
        return null
      }

      const data = listObject.get('data')

      if (isNil(data)) {
        return null
      }

      return formatData !== null && typeof formatData === 'function'
        ? formatData(data.toJS())
        : data.toJS()
    }),

  makeGetError: () =>
    createSelector([getState, getProp], (dataState, prop) => {
      if (isNil(dataState)) {
        return null
      }

      const listObject = dataState.get(prop)

      if (isNil(listObject)) {
        return null
      }

      const error = listObject.get('error')

      if (isNil(error)) {
        return null
      }

      return error.toJS()
    }),
})

export default createSelectorsPerObject
