import Immutable from 'immutable'
import concat from 'lodash/concat'
import uniqWith from 'lodash/uniqWith'
import isNil from 'lodash/isNil'

import { GetUserAction, GetUserListAction, PutUserAction } from './actions'

import { CreateUserPostUserAction } from '../proCreateUser/actions'

import { UpdateUserAccountAction } from 'pmt-modules/userAccount'

import { findOnArray, updateArrayObject } from 'pmt-utils/array'

import { createSimpleReducer } from '../redux'

export * from './actions'
export * from './components'
export * from './constants'
export * from './format'
export * from './selectors'

// Example of user data
// {
//   user: null,
//   isFetching: false,
//   lastUpdated: null,
//   error: null,
// }

const DEFAULT = Immutable.fromJS({
  entity: {
    data: null,
    isFetching: false,
    lastUpdated: null,
    error: null,
  },
  list: {
    data: null,
    isFetching: false,
    lastUpdated: null,
    pagination: null,
    error: null,
  },
})

export const userReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case GetUserAction.REQUEST:
      return state.merge({
        entity: {
          data: null,
          isFetching: true,
          lastUpdated: null,
          error: null,
        },
      })

    case GetUserAction.SUCCESS:
      return state.merge({
        entity: {
          data: action.response,
          isFetching: false,
          lastUpdated: new Date(),
          error: null,
        },
      })

    case GetUserAction.FAILURE:
      return state.merge({
        entity: {
          data: null,
          isFetching: false,
          lastUpdated: new Date(),
          error: action.error,
        },
      })

    //
    //
    //

    case GetUserListAction.REQUEST:
      return state.mergeIn(['list'], {
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetUserListAction.SUCCESS:
      const mergeWithPagin = (state, action) => {
        let newList = []

        /**
         * A null before cursor means we ran the action to load the first
         * data page. In this case, we don't merge the data,
         * we reset it.
         */
        if (action.response.paging.cursors.before === null) {
          newList = action.response.data
        } else {
          let currentList = state.getIn(['list', 'data'])

          if (!isNil(currentList)) {
            currentList = currentList
              .valueSeq()
              .toArray()
              .map(item => {
                return item.toJS()
              })
          } else {
            currentList = []
          }

          newList = concat(currentList, action.response.data)
          newList = uniqWith(newList, (a, b) => a.id === b.id)
        }

        return state
          .merge({
            list: {
              isFetching: false,
              lastUpdated: new Date(),
              error: null,
            },
          })
          .mergeIn(['list', 'pagination'], action.response.paging)
          .setIn(['list', 'data'], Immutable.fromJS(newList))
      }
      return mergeWithPagin(state, action)

    //
    // add newly created user to our users list
    //
    case CreateUserPostUserAction.SUCCESS:
      const addUserToList = (state, action) => {
        let currentList = state.getIn(['list', 'data'])

        if (!isNil(currentList)) {
          currentList = currentList
            .valueSeq()
            .toArray()
            .map(item => {
              return item.toJS()
            })
        } else {
          currentList = []
        }

        const newList = [action.response, ...currentList]

        return state.setIn(['list', 'data'], Immutable.fromJS(newList))
      }
      return addUserToList(state, action)

    case GetUserListAction.FAILURE:
      return state.merge({
        list: {
          data: null,
          isFetching: false,
          lastUpdated: new Date(),
          error: action.error,
        },
      })

    case PutUserAction.SUCCESS:
      const updateUser = (state, action) => {
        // update entity
        let newState = state.merge({
          entity: {
            data: action.response,
            isFetching: false,
            lastUpdated: new Date(),
            error: null,
          },
        })

        const userId = action.data.userId
        const path = ['list', 'data']

        // update list
        if (!newState.getIn(path)) {
          // path does not exists
          return newState
        }

        return newState.updateIn(path, dataState => {
          if (!dataState) {
            return dataState
          }

          const dataList = dataState.toJS()

          const userData = findOnArray(dataList, userId)

          if (!userData) {
            return dataState
          }

          return Immutable.fromJS(updateArrayObject(dataList, action.response, userId))
        })
      }
      return updateUser(state, action)

    case UpdateUserAccountAction.SUCCESS:
      const updateUserAccount = (state, action) => {
        const userId = action.data.userId
        const updatedUserAccount = action.response

        const path = ['list', 'data']

        if (!state.getIn(path)) {
          // path does not exists
          return state
        }

        return state.updateIn(path, dataState => {
          if (!dataState) {
            return dataState
          }

          const dataList = dataState.toJS()

          const userData = findOnArray(dataList, userId)

          if (!userData) {
            return dataState
          }

          userData.userAccounts = updateArrayObject(
            userData.userAccounts,
            updatedUserAccount,
            updatedUserAccount.id
          )

          return Immutable.fromJS(updateArrayObject(dataList, userData, userId))
        })
      }
      return updateUserAccount(state, action)

    default:
      return state
  }
}

//
//
//

export const updateUserReducer = createSimpleReducer(PutUserAction)
