import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import grey from 'pmt-ui/colors/grey'

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    background: grey[200],
  },
})

const PageContentFull = ({ children, classes }) => (
  <div className={classes.root}>
    <div className="u-paddingTop30">{children}</div>
  </div>
)

export default withStyles(styles)(PageContentFull)
