import React from 'react'

import clsx from 'clsx'

import { withProMe } from 'pmt-modules/proMe'

import { SidebarType } from '../Sidebar/constants'

import { withStyles } from 'pmt-ui/styles'
import AppBar from 'pmt-ui/AppBar'
import Toolbar from 'pmt-ui/Toolbar'
import IconButton from 'pmt-ui/IconButton'
import MenuIcon from 'pmt-ui/svg-icons/navigation/menu-icon'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'
import LogoWhite from 'pmt-ui/svg-icons/pmt/LogoWhite'

import ProMe from './ProMe'
import Domain from './Domain'

const styles = theme => ({
  appBar: {
    position: 'absolute',
    backgroundColor: theme.pmt.navigation.backgroundColor,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoHeader: {
    flex: 1,
    color: 'transparent',
  },
  logo: {
    width: 128,
    height: 40,
  },
  menu: {
    top: `${theme.spacing(6)}px !important`,
    width: '175px',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
    color: 'white',
  },
  hide: {
    display: 'none',
  },
  menuItem: {
    color: theme.pmt.navigation.item.color,
  },
  hidden: {
    visibility: 'hidden',
  },
})

const Header = ({ isLoggedIn, proMe, sideBarType, sidebarIsOpen, onSidebarToggle, classes }) => (
  <AppBar position="static" className={clsx(classes.appBar, sidebarIsOpen && classes.appBarShift)}>
    <Toolbar className={classes.toolbar} variant="dense" disableGutters={sideBarType === SidebarType.PERSISTENT}>
      {/* Left part */}

      {sideBarType === SidebarType.PERSISTENT && (
        <IconButton
          aria-label="open sidebar"
          onClick={onSidebarToggle}
          className={clsx(
            classes.menuButton,
            !isLoggedIn && classes.hidden // hide the button if we are not logged in
          )}
        >
          {!sidebarIsOpen ? <MenuIcon /> : <CloseIcon />}
        </IconButton>
      )}

      <div className={classes.logoHeader}>
        <LogoWhite viewBox={'0 0 150 43.5'} className={classes.logo} />
      </div>

      {/* Right part */}

      <Domain classes={classes} />

      <ProMe display={isLoggedIn} proMe={proMe} classes={classes} />
    </Toolbar>
  </AppBar>
)

export default withStyles(styles)(withProMe(Header))
