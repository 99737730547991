import React from 'react'
import PropTypes from 'prop-types'
import isNull from 'lodash/isNull'
import { connect } from 'react-redux'

import { makeGetMeDomain, makeIsFetchingMeDomain } from '../../selectors'
import { fetchMeDomain } from '../../actions'

/**
 * @specs N/A
 *
 * A HOC that give fetch the domainId and pass it to the children
 *
 * Requirements:
 * - domainId
 *
 */
class MeDomainContainer extends React.Component {
  componentWillMount() {
    if (isNull(this.props.domain) || this.props.forceReload) {
      this.loadMeDomain(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (
      nextProps.domainId !== props.domainId &&
      ((!props.domain || props.domain.id) && nextProps.domainId)
    ) {
      this.loadMeDomain(nextProps)
    }
    if (nextProps.loadArchived !== props.loadArchived) {
      this.loadMeDomain(nextProps)
    }
  }

  loadMeDomain(props) {
    props.fetchMeDomain(props.domainId, { loadArchived: props.loadArchived })
  }

  render() {
    const { domain, isFetchingDomain } = this.props

    return this.props.children({
      domain,
      isFetchingDomain,
    })
  }
}

MeDomainContainer.defaultProps = {
  domain: null,
}

MeDomainContainer.propTypes = {
  domain: PropTypes.object,
}

const makeMapStateToProps = (state, props) => {
  const getMeDomain = makeGetMeDomain()
  const isFetchingMeDomain = makeIsFetchingMeDomain()

  const mapStateToProps = (state, props) => {
    const selectorProps = {
      domainId: props.domainId,
      loadArchived: props.loadArchived,
    }

    return {
      domain: getMeDomain(state, selectorProps),
      isFetchingDomain: isFetchingMeDomain(state, selectorProps),
    }
  }
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchMeDomain,
  }
)(MeDomainContainer)
