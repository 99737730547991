import { createSimpleReducer } from '../redux'
import { combineReducers } from 'redux'
import {
  GetProNotificationsIdentitiesAction,
  GetProNotificationsAction,
  PostProNotificationsAction,
  GetProNotificationSettingAction,
  PatchProNotificationSettingAction,
} from './actions'

const getProNotificationsIdentitiesReducer = createSimpleReducer(
  GetProNotificationsIdentitiesAction
)

const getProNotificationsReducer = createSimpleReducer(GetProNotificationsAction)

const postProNotificationReducer = createSimpleReducer(PostProNotificationsAction)

const getProNotificationReducer = createSimpleReducer(GetProNotificationSettingAction, {
  // update data when patch succeed.
  [PatchProNotificationSettingAction.SUCCESS]: (state, action) =>
    state.merge({
      data: action.response,
    }),
})

const patchProNotificationReducer = createSimpleReducer(PatchProNotificationSettingAction)

export const proNotificationReducer = combineReducers({
  identities: getProNotificationsIdentitiesReducer,
  proNotificationsSettings: getProNotificationsReducer,
  postSetting: postProNotificationReducer,
  proNotificationsSetting: getProNotificationReducer,
  patchSetting: patchProNotificationReducer,
})
