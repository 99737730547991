import React from 'react'

import FormButtons from './FormButtons'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import ErrorBlock from 'pmt-ui/ErrorBlock'

const FormArea = ({
  children,
  cancelBtnProps,
  submitBtnProps,
  onReset,
  onSubmit,
  formIsValid,
  isFetching,
  error,
}) => (
  <LoadingBlock
    show={isFetching}
  >
    <div>

      {children}

      <ErrorBlock
        error={error}
      />

      <FormButtons
        onReset={onReset}
        onSubmit={onSubmit}
        cancel={{
          ...cancelBtnProps,
        }}
        submit={{
          disabled: !formIsValid || isFetching,
          ...submitBtnProps,
        }}
      />
    </div>
  </LoadingBlock>
)

export default FormArea
