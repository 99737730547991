import ProApi from '../api/calls/ProApi'

import { createApiCallAction, createApiEnumAction } from '../api/utils'
import { createAction } from '../redux'

export const ForgotPasswordAction = createApiEnumAction('FORGOT_PASSWORD::GET')

export const forgotPassword = (username, redirectToLogin = false) =>
  createApiCallAction(ForgotPasswordAction, ProApi.getRecoverPassword(username), {
    username,
    redirectToLogin,
  })

export const ForgotPasswordActions = {
  RESET: 'ForgotPasswordActions::RESET',
}

export const resetForgotPassword = () => createAction(ForgotPasswordActions.RESET)
