import Immutable from 'immutable'

import { UpdateUserFormPart } from './constants'

import {
  updateUserFormActions,
} from './actions'
import {
  PutUserAction,
} from 'pmt-modules/user'
import {
  UpdateUserAccountAction,
} from 'pmt-modules/userAccount'

export * from './actions'
export * from './selectors'
export * from './constants'

const DEFAULT = Immutable.fromJS({
  updateUserFormPart: UpdateUserFormPart.NONE,
})

export const proUserUpdateReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case updateUserFormActions.RUN_FORM:
      return state.merge({
        updateUserFormPart: action.updateUserFormPart,
      })

    case PutUserAction.SUCCESS:
    case UpdateUserAccountAction.SUCCESS:
      return state.merge({
        updateUserFormPart: UpdateUserFormPart.NONE,
      })

    default:
      return state
  }
}
