import createSearch from 'pmt-modules/search/createSearch'
import SearchType from 'pmt-modules/search/SearchType'
import RestaurantsGroupApi from 'pmt-modules/api/calls/RestaurantsGroupApi'

import { formatUserList } from '../format'

export default createSearch(
  SearchType.USER,
  RestaurantsGroupApi.searchUser,
  {
    formatter: formatUserList,
  }
)
