const ProAccountPermissions = {
  CREATE_RESTAURANT: 'proAccount.restaurant.canCreateRestaurant',
  ARCHIVE_RESTAURANT: 'proAccount.restaurant.canDelete',

  ACCESS: 'proAccount',

  CREATE_PRO: 'proAccount.canCreate',

  EDIT_PRO: 'proAccount.canEdit',

  ARCHIVE_PRO: 'proAccount.canArchive',

  EDIT_SELF_ACCOUNT: 'proAccount.self.canEditAccount',
}

export default ProAccountPermissions
