import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import DragAndDropSortContainer from 'pmt-ui/DragAndDrop/DragAndDropSortContainer'

import TableGenerator from './TableGenerator'
import { updateTableQueryAction } from '../../actions'
import { getSortOptions } from '../../selectors'

class TableGeneratorContainer extends React.Component {
  componentWillMount() {
    this.updateTable(this.props)
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.tableMode !== props.tableMode || nextProps.tableIndex !== props.tableIndex) {
      this.updateTable(nextProps)
    }
  }

  updateTable(props) {
    if (!isEmpty(props.sortOptions)) {
      props.updateTableQueryAction(props.tableType, props.sortOptions)
    } else if (props.config.table.tableHead && !isEmpty(props.config.table.tableHead.sortOptions)) {
      props.updateTableQueryAction(props.tableType, props.config.table.tableHead.sortOptions)
    }
  }

  onRequestSort = (tableMode, tableIndex) => {
    let newTableMode = tableMode
    if (this.props.sortOptions.tableIndex === tableIndex) {
      newTableMode = tableMode === 'asc' ? 'desc' : 'asc'
    }

    this.props.updateTableQueryAction(this.props.tableType, { tableMode: newTableMode, tableIndex })
  }

  render() {
    const {
      datas,
      config,
      onSort = null,
      sortOptions,
      sortRows = false,
      ...otherProps
    } = this.props

    const { tableMode, tableIndex } = sortOptions

    return sortRows ? (
      <DragAndDropSortContainer list={datas}>
        {({ cards, moveCard }) => (
          <TableGenerator
            config={config}
            datas={cards}
            moveCard={moveCard}
            endDrag={() => onSort(cards)}
            sortRows={sortRows}
            tableMode={tableMode}
            tableIndex={tableIndex}
            {...otherProps}
          />
        )}
      </DragAndDropSortContainer>
    ) : (
      <TableGenerator
        datas={datas}
        config={config}
        tableMode={tableMode}
        tableIndex={tableIndex}
        {...otherProps}
      />
    )
  }
}

TableGeneratorContainer.propTypes = {
  updateTableQueryAction: PropTypes.func.isRequired,
  config: PropTypes.object,
  tableType: PropTypes.string,
  sortOptions: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  return {
    sortOptions: getSortOptions(props.tableType, state),
  }
}

export default connect(
  mapStateToProps,
  {
    updateTableQueryAction,
  }
)(TableGeneratorContainer)
