const ApiConsumerPermissions = {
  ACCESS: 'apiConsumer',

  CAN_EDIT: 'apiConsumer.canEdit',

  CAN_CREATE: 'apiConsumer.canCreate',

  CAN_ARCHIVE: 'apiConsumer.canArchive',

  ACCESS_API_KEY: 'apiConsumer.apiKey',

  CAN_CREATE_API_KEY: 'apiConsumer.apiKey.canCreate',
}

export default ApiConsumerPermissions
