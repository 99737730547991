import { createSimpleReducer } from 'pmt-modules/redux'
import { combineReducers } from 'redux'

import { FetchApiKeyAction, GenerateApiKeyAction } from './actions'

const apiKeyDetailReducer = createSimpleReducer(FetchApiKeyAction, {
  // update current apikey data
  [GenerateApiKeyAction.SUCCESS]: (state, action) =>
    state.merge({
      data: action.response,
      isFetching: false,
      error: null,
    }),
})

const generateApiKeyReducer = createSimpleReducer(GenerateApiKeyAction)

export const apiKeyReducer = combineReducers({
  detail: apiKeyDetailReducer,
  generate: generateApiKeyReducer,
})
