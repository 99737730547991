import { tr } from 'pmt-modules/i18n'
import { notEmpty } from 'pmt-modules/form/validation'
import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

import { GroupType } from '../constants'

const defaultData = {
  name: null,
  parentId: null,
  type: GroupType.OTHER,
  createFinalGroup: false,
  restaurantsGroup: null,
}

/**
 * Validation rules for register form
 * options parameters should contains fields retrieved from api consumer settings
 * it is structured as is : {
 *   type: FIELD_TYPE,
 *   required: boolean
 * }
 * if field is required, we automatically add a notEmpty rule
 */
const validationRules = () => ({
  name: [[notEmpty, tr('global.groups.form.validation.name_invalid')]],
  parentId: [[notEmpty, tr('global.groups.form.validation.parent_missing')]],
  type: [[notEmpty, tr('global.groups.form.validation.type_missing')]],
})

export default createForm(FormType.GROUP_CREATE, validationRules, defaultData)
