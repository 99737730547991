import ApiEndpoints from '../config/ApiEndpoints'

const ExportApi = {
  postSubsidyBill: (rGroupId, props) => {
    return {
      endpoint: ApiEndpoints.EXPORT_SUBSIDY_BILL,
      type: 'POST',
      params: {
        restaurantsGroupId: rGroupId,
      },
      body: {
        restaurantsGroupId: rGroupId,
        ...props,
        fromTime: props.fromTime.valueOf(),
        toTime: props.toTime.valueOf(),
      },
    }
  },

  postDeposits: (rGroupId, props) => {
    return {
      endpoint: ApiEndpoints.EXPORT_DEPOSITS,
      type: 'POST',
      params: {
        restaurantsGroupId: rGroupId,
      },
      body: {
        restaurantsGroupId: rGroupId,
        ...props,
        fromTime: props.fromTime.valueOf(),
        toTime: props.toTime.valueOf(),
      },
    }
  },

  postSales: (rGroupId, props) => {
    return {
      endpoint: ApiEndpoints.EXPORT_SALES,
      type: 'POST',
      params: {
        restaurantsGroupId: rGroupId,
      },
      body: {
        restaurantsGroupId: rGroupId,
        ...props,
        fromTime: props.fromTime.valueOf(),
        toTime: props.toTime.valueOf(),
      },
    }
  },

  //
  //
  //

  postOrders: (rGroupId, props) => ExportApi.postForRestaurantsGroup(2, rGroupId, props),

  postUsers: (rGroupId, props) => ExportApi.postForRestaurantsGroup(4, rGroupId, props),

  postBookings: (rGroupId, props) => ExportApi.postForRestaurantsGroup(8, rGroupId, props),

  postPayments: (rGroupId, props) => ExportApi.postForRestaurantsGroup(16, rGroupId, props),

  postForRestaurantsGroup: (bitfield, rGroupId, props) => ({
    endpoint: ApiEndpoints.EXPORT_RESTAURANTS_GROUP_DATA,
    type: 'GET',
    params: {
      restaurantsGroupId: rGroupId,
    },
    query: {
      entities: bitfield,
      fromTime: props.fromTime.valueOf(),
      toTime: props.toTime.valueOf(),
    },
  }),
}

export default ExportApi
