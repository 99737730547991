import { createSimpleReducer } from '../redux'

import { GetApiConsumerListAction, GetApiConsumerAction } from './actions'

export * from './actions'
export * from './components'
export * from './selectors'

export const apiConsumerListReducer = createSimpleReducer(GetApiConsumerListAction)

export const apiConsumerReducer = createSimpleReducer(GetApiConsumerAction)
