import { tr } from 'pmt-modules/i18n'
import React, { useState } from 'react'

import Dialog, { DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import JsonView from 'pmt-ui/JsonView'

import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import CodeIcon from '@material-ui/icons/Code'
import CompareIcon from '@material-ui/icons/Compare'

import CopyToClipboardWithNotif from 'app/components/CopyToClipboardWithNotif'

const MAX_LENGTH_FOR_BEAUTIFY = 50000 // 50 000

const useStyles = makeStyles(theme => ({
  dialog_paper: {
    width: '90vw',
    maxWidth: '90vw',
  },
  toolbar: {
    justifyContent: 'flex-end',
  },
}))

const View = ({ json, title, onClose, beautify }) => {
  const classes = useStyles()
  const [collapsed, setCollapsed] = useState(false)
  const [displayAsBeautify, setDisplayAsBeautify] = useState(
    beautify === true ||
      (beautify === 'auto' && JSON.stringify(json).length <= MAX_LENGTH_FOR_BEAUTIFY)
  )

  return (
    <Dialog
      open
      fullWidth
      keepMounted
      onClose={onClose}
      aria-labelledby="dialog-json-title"
      aria-describedby="dialog-json-description"
      classes={{ paper: classes.dialog_paper }}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle id="dialog-json-title">{title}</DialogTitle>
      <DialogContent id="dialog-json-description">
        <Toolbar variant="dense" className={classes.toolbar}>
          <Tooltip title={tr(collapsed ? 'global.json_view.show' : 'global.json_view.hide')}>
            <IconButton onClick={() => setCollapsed(!collapsed)}>
              <CodeIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <CopyToClipboardWithNotif text={JSON.stringify(json, null, 2)}>
            <Tooltip title={tr('global.copy_to_clipboard')}>
              <IconButton>
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </CopyToClipboardWithNotif>
          <Tooltip
            title={tr(
              displayAsBeautify ? 'global.json_view.show_source' : 'global.json_view.hide_source'
            )}
          >
            <IconButton onClick={() => setDisplayAsBeautify(!displayAsBeautify)}>
              <CompareIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Toolbar>

        {displayAsBeautify ? (
          <JsonView src={json} collapsed={collapsed} />
        ) : (
          <pre>{JSON.stringify(json, null, 2)}</pre>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default View
