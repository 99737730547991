import { tr } from 'pmt-modules/i18n'
export const RestaurantPspStatus = {
  UNKNOWN: 'UNKNOWN',
  NOT_VALIDATED: 'NOT_VALIDATED',
  ARCHIVED: 'ARCHIVED',
  VALIDATED: 'VALIDATED',
}

export const getRestaurantPspStatusLabel = status => {
  switch (status) {
    case RestaurantPspStatus.UNKNOWN:
      return tr('global.RestaurantPspStatus.UNKNOWN')
    case RestaurantPspStatus.NOT_VALIDATED:
      return tr('global.RestaurantPspStatus.NOT_VALIDATED')
    case RestaurantPspStatus.ARCHIVED:
      return tr('global.RestaurantPspStatus.ARCHIVED')
    case RestaurantPspStatus.VALIDATED:
      return tr('global.RestaurantPspStatus.VALIDATED')

    default:
      return status
  }
}

//
//
//

/**
 * Possible status for a UboResume.
 */
export const UboResumeStatus = {
  /**
   * When the UBO declaration was created
   */
  CREATED: 'CREATED',

  /**
   * Failed to retrieve UBO data.
   */
  FAILED: 'FAILED',

  /**
   * When validation has been requested for the UBO declaration
   */
  VALIDATION_ASKED: 'VALIDATION_ASKED',

  /**
   * When the UBO declaration was incomplete
   */
  INCOMPLETE: 'INCOMPLETE',

  /**
   * When the UBO declaration was refused
   */
  REFUSED: 'REFUSED',

  /**
   * No ubo defined
   */
  NO_UBO_DECLARATION: 'NO_UBO_DECLARATION',

  /**
   * Ubo has been retrieved and is validated.
   */
  SUCCEED: 'SUCCEED',
}

export const getUboResumeStatusLabel = status => {
  switch (status) {
    case UboResumeStatus.CREATED:
      return tr('global.UboResumeStatus.CREATED')
    case UboResumeStatus.FAILED:
      return tr('global.UboResumeStatus.FAILED')
    case UboResumeStatus.VALIDATION_ASKED:
      return tr('global.UboResumeStatus.VALIDATION_ASKED')
    case UboResumeStatus.INCOMPLETE:
      return tr('global.UboResumeStatus.INCOMPLETE')
    case UboResumeStatus.REFUSED:
      return tr('global.UboResumeStatus.REFUSED')
    case UboResumeStatus.NO_UBO_DECLARATION:
      return tr('global.UboResumeStatus.NO_UBO_DECLARATION')
    case UboResumeStatus.SUCCEED:
      return tr('global.UboResumeStatus.SUCCEED')
    default:
      return status
  }
}

//
//
//

export const DocumentType = {
  /**
   * Statuts de l'entreprise
   */
  IDENTITY_PROOF: 'IDENTITY_PROOF',

  /**
   * Carte d'identité ou passeport du représentant légal
   */
  REGISTRATION_PROOF: 'REGISTRATION_PROOF',

  /**
   * Kbis
   */
  ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',

  /**
   * Déclaration d'actionnaires
   */
  SHAREHOLDER_DECLARATION: 'SHAREHOLDER_DECLARATION',

  /**
   * Proof of income, only for PayGreen.
   */
  PROOF_OF_INCOME: 'PROOF_OF_INCOME',
}

export const DocumentStatus = {
  /**
   * Not specified.
   */
  UNKNOWN: 'UNKNOWN',

  DOES_NOT_EXISTS: 'DOES_NOT_EXISTS',

  /**
   * Could not retrieve status
   */
  ERROR: 'ERROR',
  /**
   * CREATED KYC status.
   */
  CREATED: 'CREATED',
  /**
   * VALIDATION ASKED KYC status.
   */
  VALIDATION_ASKED: 'VALIDATION_ASKED',
  /**
   * VALIDATED KYC status.
   */
  VALIDATED: 'VALIDATED',
  /**
   * REFUSED KYC status.
   */
  REFUSED: 'REFUSED',
}

export const getDocumentTypeLabel = type => {
  switch (type) {
    case DocumentType.IDENTITY_PROOF:
      return tr('global.restaurant.document.type.IDENTITY_PROOF')
    case DocumentType.REGISTRATION_PROOF:
      return tr('global.restaurant.document.type.REGISTRATION_PROOF')
    case DocumentType.ARTICLES_OF_ASSOCIATION:
      return tr('global.restaurant.document.type.ARTICLES_OF_ASSOCIATION')
    case DocumentType.SHAREHOLDER_DECLARATION:
      return tr('global.restaurant.document.type.SHAREHOLDER_DECLARATION')
    case DocumentType.PROOF_OF_INCOME:
      return tr('global.restaurant.document.type.PROOF_OF_INCOME')
    default:
      return type
  }
}

export const getDocumentStatusLabel = status => {
  switch (status) {
    case DocumentStatus.UNKNOWN:
      return tr('global.restaurant.document.status.UNKNOWN')
    case DocumentStatus.DOES_NOT_EXISTS:
      return tr('global.restaurant.document.status.DOES_NOT_EXISTS')
    case DocumentStatus.ERROR:
      return tr('global.restaurant.document.status.ERROR')
    case DocumentStatus.CREATED:
      return tr('global.restaurant.document.status.CREATED')
    case DocumentStatus.VALIDATION_ASKED:
      return tr('global.restaurant.document.status.VALIDATION_ASKED')
    case DocumentStatus.VALIDATED:
      return tr('global.restaurant.document.status.VALIDATED')
    case DocumentStatus.REFUSED:
      return tr('global.restaurant.document.status.REFUSED')

    default:
      return status
  }
}

//
//
//

export const BankResumeStatus = {
  UNKNOWN: 'UNKNOWN',
  CREATED: 'CREATED',
  NOT_CREATED: 'NOT_CREATED',
}

export const getBankResumeStatusLabel = key => {
  switch (key) {
    case BankResumeStatus.CREATED:
      return tr('global.restaurant.BankResumeStatus.CREATED')

    case BankResumeStatus.NOT_CREATED:
      return tr('global.restaurant.BankResumeStatus.NOT_CREATED')

    default:
    case BankResumeStatus.UNKNOWN:
      return tr('global.restaurant.BankResumeStatus.UNKNOWN')
  }
}

//
//
//

export const RestaurantResumeStatus = {
  CONFIGURED: 'CONFIGURED',
  TO_CONFIGURE: 'TO_CONFIGURE',
}

export const getRestaurantResumeStatusLabel = key => {
  switch (key) {
    case RestaurantResumeStatus.CONFIGURED:
      return tr('global.restaurant.RestaurantResumeStatus.CONFIGURED')

    case RestaurantResumeStatus.TO_CONFIGURE:
      return tr('global.restaurant.RestaurantResumeStatus.TO_CONFIGURE')

    default:
      return key
  }
}
