import React from 'react'
import { connect } from 'react-redux'

import { getSelectedRestaurantsGroup, getSelectedDomain, getBoData } from '../../selectors'
import { loadDefaultDomain } from '../../actions'

import { isLoggedIn } from 'pmt-modules/authPro'

/**
 * Retrieve and gives to the children all the data available about the context of the BO.
 */
class BoContext extends React.Component {
  constructor(props) {
    super(props)

    if (!props.selectedDomain && props.withDomainLoading === true) {
      this.props.loadDefaultDomain()
    }
  }

  render() {
    const { children, ...otherProps } = this.props

    return children({
      proRole: otherProps.selectedDomain ? otherProps.selectedDomain.role : null,
      ...otherProps,
    })
  }
}
const mapStateToProps = (state, props) => ({
  isLoggedIn: isLoggedIn(state),
  selectedDomain: getSelectedDomain(state),
  selectedRGroup: getSelectedRestaurantsGroup(state),
  boData: getBoData(state),
})

export default connect(
  mapStateToProps,
  { loadDefaultDomain }
)(BoContext)
