import Immutable from 'immutable'

import { combineReducers, createSimpleReducer } from 'pmt-modules/redux'

import { FetchRestaurantOnGoingOrderListAction, OnGoingOrderExtraActions } from './actions'

const restaurantOnGoingOrdersReducer = createSimpleReducer(FetchRestaurantOnGoingOrderListAction)

const DEFAULT = {
  date: null,
}

/**
 * Extra data we may need to display on going order,
 * but is not an on going order data
 */
export const onGoingExtraReducer = (state = Immutable.fromJS(DEFAULT), action) => {
  switch (action.type) {
    case OnGoingOrderExtraActions.REFRESH:
      return state.merge({
        date: action.date,
      })

    default:
      return state
  }
}

export const restaurantOrdersOnGoingReducer = combineReducers({
  onGoing: restaurantOnGoingOrdersReducer,
  onGoingExtra: onGoingExtraReducer,
})
