import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import AuditApi from 'pmt-modules/api/calls/AuditApi'

export const FetchAuditLogsAction = createApiEnumAction('AUDIT::LOGS::GET')

/**
 * @param props
 *  - requestIds: list of requestId
 *  - fromTime / toTime: range of the logs to search in for the requestsIds. Allow to optimize our
 *    request
 */
export const fetchAuditLogs = props =>
  createApiCallAction(FetchAuditLogsAction, AuditApi.getLogs(props), props)
