import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Audit = props => (
  <SvgIcon
    fill="currentColor"
    fillRule="evenodd"
    height="100%"
    viewBox="0 0 24 24"
    width="100%"
    fit=""
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    {...props}
  >
    <path d="M10 7h12V3H10z" opacity=".8" />
    <path d="M6 20h4v-2H6zm-2 0h2V8H4z" opacity=".6" />
    <path d="M10 21h12v-4H10z" opacity=".8" />
    <path d="M6 13h4v-2H6z" opacity=".6" />
    <path d="M10 14h12v-4H10z" opacity=".8" />
    <path d="M2 8h6V2H2z" />
  </SvgIcon>
)
Audit = pure(Audit)
Audit.displayName = 'Audit'
Audit.muiName = 'SvgIcon'

export default Audit
