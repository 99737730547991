import { createReducerPerObject } from 'pmt-modules/redux'

import { FetchRestaurantDocumentsStatus } from './actions'

export * from './actions'
export * from './selectors'

export const restaurantDocumentsStatusReducer = createReducerPerObject(
  FetchRestaurantDocumentsStatus,
  action => action.data.restaurantId
)
