import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { fetchApiConsumerList, fetchApiConsumerListFromRestaurant } from '../actions'

import {
  getApiConsumerList,
  getApiConsumerListWithDefault,
  isFetchingApiConsumerList,
} from '../selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the restaurant group's api consumer and pass it to the children
 *
 * Requirements:
 * - rGroupId
 *
 */
class ApiConsumerListContainer extends React.Component {
  componentWillMount() {
    if (isNil(this.props.apiConsumerList)) {
      this.loadApiConsumerList(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.rGroupId !== props.rGroupId) {
      this.loadApiConsumerList(nextProps)
    }
  }

  loadApiConsumerList(props) {
    if (!isNil(props.restaurantId)) {
      props.fetchApiConsumerListFromRestaurant(props.restaurantId)
    } else {
      props.fetchApiConsumerList(props.rGroupId, {
        withParents: props.withParents,
        withOther: props.withOther,
      })
    }
  }

  render() {
    const {
      isFetchingApiConsumerList,
      rGroupId,
      apiConsumerList,
      children,
      withOther,
      ...otherProps
    } = this.props

    let apiConsumerListToUse = null
    if (apiConsumerList) {
      if (!withOther) {
        apiConsumerListToUse = {
          apiConsumers: apiConsumerList.apiConsumers.filter(apiConsumer => apiConsumer.id !== 0),
        }
      } else {
        apiConsumerListToUse = apiConsumerList
      }
    }

    return children({
      isFetchingApiConsumerList,
      rGroupId,
      apiConsumerList: apiConsumerListToUse,
      ...otherProps,
    })
  }
}

ApiConsumerListContainer.defaultProps = {
  withParents: false,
  restaurantId: null,
  withOther: true,
}

ApiConsumerListContainer.propTypes = {
  apiConsumerList: PropTypes.object,
  // children MUST be a function
  children: PropTypes.func.isRequired,
  isFetchingApiConsumerList: PropTypes.bool,
  rGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  restaurantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // retrieve api consumer with its parents
  withParents: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
  return {
    apiConsumerList: getApiConsumerList(state, props),
    apiConsumerListWithDefault: isNil(props.restaurantId)
      ? getApiConsumerListWithDefault(state, props)
      : null,
    isFetchingApiConsumerList: isFetchingApiConsumerList(state, props),
  }
}

export default connect(
  mapStateToProps,
  {
    fetchApiConsumerList,
    fetchApiConsumerListFromRestaurant,
  }
)(ApiConsumerListContainer)
