import cloneDeep from 'lodash/cloneDeep'
import transformForApi from './transformForApi'
import { ProRoleVisibilityMode } from './constants'

//
// The permissions data on the front differ from the API data.
// This transform the front data to the Api data.
//
const formatProRoleForApi = proRole => {
  const proRoleForApi = cloneDeep(proRole)
  proRoleForApi.permissions = transformForApi(proRoleForApi.permissions)

  // the NO_CHILDREN mode does not exists on API, we replace it here
  if (proRoleForApi.visibility.mode === ProRoleVisibilityMode.NO_CHILDREN) {
    proRoleForApi.visibility.mode = ProRoleVisibilityMode.DEFINED_CHILDREN
    proRoleForApi.visibility.restaurantsGroupIds = []
  }

  if (proRoleForApi.visibility.mode === ProRoleVisibilityMode.DEFINED_CHILDREN) {
    // DEFINED_CHILDREN can't have null restaurantsGroupIds
    if (proRoleForApi.visibility.restaurantsGroupIds === null) {
      proRoleForApi.visibility.restaurantsGroupIds = []
    }
  }

  if (proRoleForApi.visibility.mode === ProRoleVisibilityMode.ALL_CHILDREN) {
    // all children, no need of restaurantsGroupIds
    // we reset it here, since the form could want to keep trace of the restaurantsGroupIds even if
    // we change the mode
    proRoleForApi.visibility.restaurantsGroupIds = null
  }

  return proRoleForApi
}

export default formatProRoleForApi
