import { tr } from 'pmt-modules/i18n'
import { createListFormatter, createFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { formatDate } from 'pmt-utils/date'
import { getPaymentStatusLabel, getPaymentStatusType } from 'pmt-modules/payment/constants'

const PaymentType = {
  OTHER: 0,
  ALL: 1,
  DIVIDE: 2,
  SPECIFIC_AMOUNT: 3,
  SPECIFIC_ITEMS: 4,
  PAYMENT_REQUEST: 5,
  ORDER: 6,
  DEPOSIT: 7,
  IRL_CREDIT_CARD: 8,
}

const getPaymentTypeLabel = method => {
  switch (method) {
    case PaymentType.OTHER:
      return tr('global.payment.Methd.OTHER')
    case PaymentType.ALL:
      return tr('global.payment.Methd.ALL')
    case PaymentType.DIVIDE:
      return tr('global.payment.Methd.DIVIDE')
    case PaymentType.SPECIFIC_AMOUNT:
      return tr('global.payment.Methd.SPECIFIC_AMOUNT')
    case PaymentType.SPECIFIC_ITEMS:
      return tr('global.payment.Methd.SPECIFIC_ITEMS')
    case PaymentType.PAYMENT_REQUEST:
      return tr('global.payment.Methd.PAYMENT_REQUEST')
    case PaymentType.ORDER:
      return tr('global.payment.Methd.ORDER')
    case PaymentType.DEPOSIT:
      return tr('global.payment.Methd.DEPOSIT')
    case PaymentType.IRL_CREDIT_CARD:
      return tr('global.payment.Methd.IRL_CREDIT_CARD')
    default:
      return method
  }
}

const formatUserTransaction = createFormatter(transaction => {
  transaction.formattedCreationDate = formatDate(transaction.date, 'DD/MM/YYYY HH:mm')
  transaction.methodLabel = getPaymentTypeLabel(transaction.paymentType)
  transaction.restaurantId = transaction.restId
  transaction.amountFormatted = formatPriceWithCurrency(transaction.amount, transaction.currency)
  transaction.statusLabel = getPaymentStatusLabel(transaction.status)
  transaction.statusType = getPaymentStatusType(transaction.status)
  transaction.totalAmountFormatted = formatPriceWithCurrency(
    transaction.totalAmount,
    transaction.currency
  )

  return transaction
})

export const formatUserTransactionsList = createListFormatter(formatUserTransaction)
