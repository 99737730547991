import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import TemplateApi from 'pmt-modules/api/calls/TemplateApi'
import { getTemplateForEmailType } from './constants'

//
//
//

export const FetchCustomTextsContainerAction = createApiEnumAction('TEMPLATE::CUSTOMS_TEXTS::GET')

export const fetchCustomTextsContainer = (containerId, source) =>
  createApiCallAction(
    FetchCustomTextsContainerAction,
    TemplateApi.getCustomTextsContainer(containerId),
    {
      containerId,
      source,
    }
  )

//
//
//

export const PostCustomTextsAction = createApiEnumAction('TEMPLATE::CUSTOMS_TEXTS::POST')

export const postCustomTextsForGroup = restaurantsGroupId =>
  createApiCallAction(
    PostCustomTextsAction,
    TemplateApi.postCustomTextsForGroup(restaurantsGroupId)
  )

export const postCustomTextsForRestaurant = restaurantId =>
  createApiCallAction(PostCustomTextsAction, TemplateApi.postCustomTextsForRestaurant(restaurantId))

//
//
//

export const PutCustomTextsContainerAction = createApiEnumAction('TEMPLATE::CUSTOMS_TEXTS::PUT')

export const putCustomTextsContainer = customTextsContainer =>
  createApiCallAction(
    PutCustomTextsContainerAction,
    TemplateApi.putCustomTextsContainer(customTextsContainer)
  )

//
//
//

export const FetchEmailTemplateData = createApiEnumAction(
  'RESTAURANTS_GROUP::TEMPLATE::EMAIL::DATA'
)

export const fetchEmailTemplateData = (emailType, restaurantsGroupId) => {
  const template = getTemplateForEmailType(emailType)

  return createApiCallAction(
    FetchEmailTemplateData,
    TemplateApi.getEmailData(template, restaurantsGroupId),
    {
      emailType,
      restaurantsGroupId,
    }
  )
}
