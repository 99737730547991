const FeedbackPermissions = {
  ACCESS: 'feedback',

  ACCESS_FORMS: 'feedback.form',

  READ_FORM: 'feedback.form.canRead',

  CREATE_FORM: 'feedback.form.canCreate',

  EDIT_FORM: 'feedback.form.canEdit',

  ACCESS_CAMPAIGNS: 'feedback.campaign',

  CREATE_CAMPAIGN: 'feedback.campaign.canCreate',

  EDIT_CAMPAIGN: 'feedback.campaign.canEdit',

  EXPORT_CAMPAIGN: 'feedback.campaign.canExport',
}

export default FeedbackPermissions
