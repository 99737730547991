import { createMiddleware } from '../redux'
import { getRoute, replaceWith } from '../routing'

import { PostProRoleAction, PutProRoleAction } from './actions'

/**
 * Intercepts when a pro account is created, and redirect to the pro role list page
 */
export const proRolePostSuccessMiddleware = createMiddleware(
  PostProRoleAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {
    next(action)

    return next(replaceWith(getRoute('BO_PRO_ROLE_LIST'), {}))
  }
)

/**
 * Intercepts when a pro account is updated, and redirect to the list page
 */
export const proRolePutSuccessMiddleware = createMiddleware(
  PutProRoleAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {
    next(action)

    return next(replaceWith(getRoute('BO_PRO_ROLE_LIST'), {}))
  }
)

export const proRoleMiddlewares = [proRolePostSuccessMiddleware, proRolePutSuccessMiddleware]
