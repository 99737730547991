import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  modes: [],
}

const validationRules = () => ({})

export default createForm(FormType.API_CONSUMER__DIGITAL_MENU, validationRules, defaultData)
