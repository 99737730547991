import { combineReducers } from 'redux'
import { createPaginateReducer } from 'pmt-modules/redux'
import { FetchAuditEventListAction } from './actions'

export * from './actions'
export * from './selectors'

//
//
//

const reducerOptions = {
  // returns true if we should reset the reducer (in case of new pagination, before cursor to null)
  onShouldReset: action => {
    // our filters contains the resetData bool telling us if we need to reset our reducer or not
    return action.data.filters.resetData
  },
}

const auditEventListReducer = createPaginateReducer(FetchAuditEventListAction, null, reducerOptions)

export const auditEventReducer = combineReducers({
  list: auditEventListReducer,
})
