//
// This module store global configuration and data for the bo, that don't requires their own
// module / reducer
//
//
import Immutable from 'immutable'

import { FetchMeDomain, FetchMeProDomains } from '../domain'

import { GetSelectedRestaurantsGroupAction, BoActions } from './actions'

export * from './actions'
export * from './selectors'
export * from './middlewares'
export * from './security'
export BoContext from './components/BoContext'

const DEFAULT = Immutable.fromJS({
  //
  // The domain selected by the pro.
  // It contains
  // - the pro role for this domain
  // - the root restaurants group, and all its children
  //
  selectedDomain: null,
  //
  // The restaurants group selected by the pro.
  // It is the current restaurants group to display.
  // For the root restaurants group, use the selected domain
  //
  selectedRestaurantsGroup: null,
})

export const boReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case BoActions.SELECT_RGROUP:
      // do nothing. since we totally reload the page at this point, no need to change the state.
      // plus it make an ugly effect
      return state

    //
    // This action load the current domain (selected by the user / defined on the url)
    //

    // we do nothing here, it is handle on domain middlewares that will dispatch BoActions:
    // - SET_SELECTED_RGROUP
    // - SET_SELECTED_DOMAIN
    case FetchMeDomain.SUCCESS:
    case FetchMeProDomains.SUCCESS:
      return state

    case BoActions.SET_SELECTED_RGROUP:
      return state.set(
        'selectedRestaurantsGroup',
        Immutable.fromJS(action.selectedRestaurantsGroup)
      )

    case BoActions.SET_SELECTED_DOMAIN:
      return state.set('selectedDomain', Immutable.fromJS(action.selectedDomain))

    case GetSelectedRestaurantsGroupAction.SUCCESS:
      return state.merge({
        selectedRestaurantsGroup: action.response,
      })

    default:
      return state
  }
}
