const postPutProAccount = {
  // invalid email
  108: 'global.error.pro_account.update.108',
  // email already used
  109: 'global.error.pro_account.update.109',
}

const ProErrors = {
  FORGOT_PASSWORD: {
    default: 'global.forgot_password.unknown_email',
  },
  postProAccount: {
    // fakeEmail but no password
    200: 'global.error.pro_account.post.200',
    // at least one domain required
    201: 'global.error.pro_account.post.201',
    ...postPutProAccount,
  },
  putProAccount: {
    107: 'global.error.pro_account.put.107',
    ...postPutProAccount,
  },
}

export default ProErrors
