import { formatDate } from 'pmt-utils/date'
import { createFormatter, createListFormatter } from 'pmt-utils/format'

import { getMediaCategoryLabel } from './constants'

const formatData = data => {
  data.size = Number.parseFloat(data.data.blobInfo.size / 1000).toFixed(2)
  data.categoryFormatted = getMediaCategoryLabel(data.category)
  data.creationDateFormatted = formatDate(data.creationDate)
  data.creationDateShortFormatted = formatDate(data.creationDate, 'DD/MM/YYYY')

  return data
}

export const formatMedia = createFormatter(formatData)

export const formatMedias = createListFormatter(formatMedia)
