import { notEmpty } from 'pmt-modules/form/validation'
import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

import { ProRoleVisibilityMode } from '../../constants'
import { formatEditProRole } from './format'

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  name: null,
  permissions: [],
  restaurantsGroupId: null, // see utils, will be replaced by the current restaurants group
  visibility: {
    mode: ProRoleVisibilityMode.NO_CHILDREN,
    restaurantsGroupIds: [],
  },
  comment: '',
}

const validationRules = () => ({
  name: [[notEmpty, 'Nom requis']],
})

const reducer = (data, action) => {
  switch (action.actionName) {
    default:
      return data
  }
}

export default createForm(FormType.PRO_ROLE_EDIT, validationRules, defaultData, devData, {
  reducer,
  modifier: formatEditProRole,
  customActions: {},
})
