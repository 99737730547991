import { tr } from 'pmt-modules/i18n'
import React from 'react'

import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import isObject from 'lodash/isObject'
import clsx from 'clsx'

import { withStyles } from 'pmt-ui/styles'

import ViewType from '../ViewType'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import List, { ListItem } from 'pmt-ui/List'
import TextField from 'pmt-ui/TextField'
import Typography from 'pmt-ui/Typography'
import Pagination from './Pagination'
import { lighten } from 'pmt-utils/theme/colorManipulator'
import Checkbox from 'pmt-ui/Checkbox'
import Radio from 'pmt-ui/Radio'

const styles = theme => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    display: 'flex',
    minHeight: 40,
  },
  list: {
    overflowY: 'auto',
    width: '100%',
  },
  fieldArea: {
    margin: theme.spacing(1),
    marginTop: 0,
  },
  itemSelectedNotMultiple: {
    background: lighten(theme.palette.primary.light, 0.5),
  },
  itemMultipleCheckbox: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  itemSingleRadio: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
})

const OptionSingle = ({ option, viewType, selected, classes }) => {
  switch (viewType) {
    case ViewType.RADIO:
      return (
        <span>
          <Radio checked={selected} color="primary" className={classes.itemSingleRadio} />
          &nbsp;
          {option.label}
        </span>
      )

    default:
      return <span>{option.label}</span>
  }
}

const Option = ({ option, optionRenderer, multiple, selected, viewType, classes, onClick }) => (
  <ListItem
    className={clsx({
      [classes.itemSelectedNotMultiple]: selected && !multiple && viewType !== ViewType.RADIO,
    })}
    button
    onClick={onClick}
  >
    {optionRenderer ? (
      optionRenderer(option)
    ) : multiple ? (
      <React.Fragment>
        <Checkbox checked={selected} color="primary" className={classes.itemMultipleCheckbox} />
        {option.label}
      </React.Fragment>
    ) : (
      <OptionSingle viewType={viewType} option={option} classes={classes} selected={selected} />
    )}
  </ListItem>
)

/**
 * View that display the content of the select view:
 * - a text field to search on the list (query)
 * - the list. It is filtered if there is any query
 */
const Body = ({
  classes,
  values,
  comparator,
  hasOptions,
  filteredOptions,
  query,
  isFetching,
  multiple,
  onSelect,
  handleQueryChange,
  disableSearch,
  paging,
  onLoadMore,
  viewType,
}) => (
  <div className={classes.body}>
    {!disableSearch && (
      <div className={classes.fieldArea}>
        <TextField
          autoComplete="off"
          autoFocus
          label={tr('global.list_chooser.search')}
          value={query}
          onChange={handleQueryChange}
          margin="normal"
          onKeyPress={event => {
            // handle key enter press
            if (event.key === 'Enter') {
              event.preventDefault()
              if (!isEmpty(filteredOptions)) {
                onSelect(filteredOptions[0].value)
              }
            }
          }}
        />
      </div>
    )}

    <div className={classes.listContainer}>
      <LoadingBlock show={isFetching}>
        {!hasOptions ? (
          <div>
            <Typography variant="caption">{tr('global.list_chooser.no_data_available')}</Typography>
          </div>
        ) : isEmpty(filteredOptions) ? (
          isEmpty(query) ? (
            <div>
              <Typography variant="caption">{tr('global.list_chooser.search')}</Typography>
            </div>
          ) : (
            <Typography variant="caption">{tr('global.list_chooser.no_results')}</Typography>
          )
        ) : (
          <List className={classes.list}>
            {filteredOptions.map((option, index) => (
              <Option
                key={isObject(option.value) ? index : option.value}
                classes={classes}
                multiple={multiple}
                option={option}
                viewType={viewType}
                selected={
                  find(values, value => {
                    if (!value) {
                      // if value is null we check the option value is null
                      return !option.value
                    }

                    // use comparator if exists and there is a value (avoir to check null on the
                    // comparator)
                    if (comparator && value) {
                      return comparator(option.value, value)
                    }

                    if (value && value.id && option.value.id) {
                      return value.id === option.value.id
                    }

                    return option.value === value
                  }) !== undefined
                }
                onClick={() => onSelect(option.value)}
              />
            ))}
          </List>
        )}
      </LoadingBlock>
    </div>

    <Pagination paging={paging} onLoadMore={onLoadMore} />
  </div>
)

export default withStyles(styles)(Body)
