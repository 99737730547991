import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  paymentMethodDetailMapping: {},
}

const validationRules = () => ({})

export default createForm(
  FormType.GROUP_EDIT_PAYMENT_METHOD_DETAIL_SETTINGS,
  validationRules,
  defaultData
)
