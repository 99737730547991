import { apiConsumerConfigurationMiddlewares } from 'pmt-modules/apiConsumer/configuration/middlewares'
import { authProMiddlewares } from 'pmt-modules/authPro'
import { proCreateUserMiddlewares } from 'pmt-modules/proCreateUser'
import { forgotPasswordMiddlewares } from 'pmt-modules/proForgotPassword'
import { domainMiddlewares } from 'pmt-modules/domain'
import { boMiddlewares } from 'pmt-modules/bo'
import { dataCatcherMiddlewares } from 'pmt-modules/dataCatcher'
import { proAccountMiddlewares } from 'pmt-modules/proAccount'
import { proRoleMiddlewares } from 'pmt-modules/proRole'
import { boFirebaseMiddlewares } from 'pmt-modules/firebase/boMiddlewares'
import documentViewerMiddlewares from 'pmt-modules/documentViewer/middlewares'
import { proNotificationsSettingsMiddlewares } from 'pmt-modules/proNotificationSetting/middlewares'
import { mediaMiddlewares } from 'pmt-modules/media'
import { boUserAccountMiddlewares } from 'pmt-modules/userAccount'
import { appSecurityMiddlewares } from 'pmt-modules/appSecurity'

export default [
  ...apiConsumerConfigurationMiddlewares,
  ...dataCatcherMiddlewares,
  ...appSecurityMiddlewares,
  ...boMiddlewares,
  ...boFirebaseMiddlewares,
  ...authProMiddlewares,
  ...domainMiddlewares,
  ...proCreateUserMiddlewares,
  ...forgotPasswordMiddlewares,
  ...boUserAccountMiddlewares,
  ...proAccountMiddlewares,
  ...proRoleMiddlewares,
  ...documentViewerMiddlewares,
  ...proNotificationsSettingsMiddlewares,
  ...mediaMiddlewares,
]
