import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  userAccountGeneratedAutomatically: false,
  userCardActivatedByDefault: false,
  userAccountSettings: {
    withCatering: false,
    badgeIdFormat: null,
    shouldGenerateBalance: false,
    userAccountManagerSettings: {
      type: null,
      thirdPartySettings: {
        url: null,
        identifier: null,
      },
    },
  },
}

const validationRules = () => ({})

export default createForm(FormType.GROUP_EDIT_USER_SETTINGS, validationRules, defaultData)
