import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import { getApiUrl } from 'pmt-modules/environment'

export const FetchDocumentSignedUrl = createApiEnumAction('DOCUMENT_VIEWER::SIGNED_URL')

export const openDocument = ({ documentUrl, title }) =>
  createApiCallAction(
    FetchDocumentSignedUrl,
    {
      // trick to add our api version on the document url
      url: documentUrl.replace(
        'https://paymytable',
        getApiUrl().substring(0, getApiUrl().indexOf('paymytable') + 'paymytable'.length)
      ),
      type: 'GET',
      query: { v2: true },
    },
    { documentUrl, title }
  ) // for restaurant documents
