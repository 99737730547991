import { createSelector } from 'reselect'

import { formatDomain } from './format'

const getMeData = state => state.entities.meDomain

const getDomainData = state => state.entities.domain

const getDomainId = (state, props) => props.domainId

export const getMeDomainsList = createSelector([getMeData], domainsData => {
  const listData = domainsData.get('list')
  if (!listData) {
    return null
  }
  const list = listData.valueSeq()

  // TODO: find why we can have a returned array [ null ] and remove filter
  return list
    .toJS()
    .map(item => item.data)
    .filter(item => item !== null)
})

export const isFetchingMeDomains = createSelector([getMeData], domainsData =>
  domainsData.get('isFetching', false)
)

export const makeGetMeDomainData = () =>
  createSelector([getMeData, getDomainId], (domainsData, domainId) => {
    const listData = domainsData.get('list')
    if (!listData) {
      return null
    }
    const list = listData.toJS()

    const domainData = list[domainId] || null

    return domainData
  })

export const makeGetMeDomain = () =>
  createSelector([makeGetMeDomainData(), getDomainId], (domainData, domainId) => {
    if (!domainData) {
      return null
    }

    return formatDomain(domainData.data)
  })

export const makeIsFetchingMeDomain = () =>
  createSelector([makeGetMeDomainData(), getDomainId], (domainData, domainId) => {
    if (!domainData) {
      return false
    }

    return domainData.isFetching || false
  })

//
//
//

export const makeGetDomainData = () =>
  createSelector([getDomainData, getDomainId], (domainsData, domainId) => {
    const listData = domainsData.get('list')
    if (!listData) {
      return null
    }
    const list = listData.toJS()

    const domainData = list[domainId] || null

    return domainData
  })

export const makeGetDomain = () =>
  createSelector([makeGetDomainData(), getDomainId], (domainData, domainId) => {
    if (!domainData) {
      return null
    }

    return formatDomain(domainData.data)
  })

export const makeIsFetchingDomain = () =>
  createSelector([makeGetDomainData(), getDomainId], (domainData, domainId) => {
    if (!domainData) {
      return false
    }

    return domainData.isFetching || false
  })
