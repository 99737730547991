import isNil from 'lodash/isNil'
import { formatProMe } from './format'
import { createSelector } from 'reselect'

const getProMeData = (state) => state.entities.proMe

export const isFetchingProMe = createSelector(
  [ getProMeData ],
  (proMeData) => {
    if (isNil(proMeData)) {
      return true
    }

    return proMeData.get('isFetching')
  }
)

export const getProMe = createSelector(
  [ getProMeData ],
  (proMeData) => {
    if (isNil(proMeData)) {
      return null
    }
    const proMe = proMeData.get('proMe')

    if (isNil(proMe)) {
      return null
    }
    return formatProMe(proMe.toJS())
  }
)
