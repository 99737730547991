import { combineReducers } from 'redux'
import { createSimpleReducer } from '../../redux'

import { FetchFeedbackAnswerResumeForObject } from './actions'

const feedbackResumeForObjectReducer = createSimpleReducer(FetchFeedbackAnswerResumeForObject)

export const feedbackResumeReducer = combineReducers({
  forObject: feedbackResumeForObjectReducer,
})
