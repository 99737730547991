import { SdkRoutingEnum, Route } from 'pmt-modules/routing'
import { routesPimv2Definitions } from './routes-pim'

const BackofficeRoutingEnum = {
  ...SdkRoutingEnum,

  //
  //
  //

  BO_SETTINGS_PAGE: new Route('BO::BO_SETTINGS_PAGE', '/settings'),

  // ERRORS
  ERRORS_LIST: new Route('ERRORS_LIST', '/errors'),

  //
  // Statistics
  //
  BO_STATISTICS_ORDER: new Route('BO_STATISTICS_ORDER', '/statistics/order'),

  BO_STATISTICS_TOP_UP: new Route('BO_STATISTICS_TOP_UP', '/statistics/top-up'),

  //
  // Users
  //

  BO_USER_LIST: new Route('BO::USER_LIST', '/clients/list'),

  BO_USER_ADD: new Route('BO::USER_ADD::STEP_USER', '/clients/add'),

  BO_USER_ADD_STEP_ACCOUNT: new Route('BO::USER_ADD::STEP_ACCOUNT', '/clients/add/:userId/account'),

  BO_USER_CARD: new Route('BO::USER', '/clients/:userId'),

  BO_USER_FEEDBACK_LIST: new Route('BO::USER_FEEDBACK_LIST', '/clients/:userId/feedback'),

  BO_USER_ORDER_LIST: new Route('BO::USER_ORDER_LIST', '/clients/:userId/order/list'),

  BO_USER_TOP_UP_LIST: new Route('BO::USER_TOP_UP_LIST', '/clients/:userId/topup/list'),

  BO_USER_TRANSACTIONS_LIST: new Route('BO_USER_TRANSACTIONS_LIST', '/clients/:userId/payments'),

  //
  // Pro
  //
  BO_PRO_ACCOUNT_LIST: new Route('BO::BO_PRO_ACCOUNT_LIST', '/users'),
  BO_PRO_ACCOUNT_CREATE: new Route('BO::PRO_ACCOUNT_CREATE', '/users/add'),
  BO_PRO_ACCOUNT_EDIT: new Route('BO::PRO_ACCOUNT_EDIT', '/users/:proAccountId/edit'),

  //
  // Roles
  //
  BO_PRO_ROLE_LIST: new Route('BO::BO_PRO_ROLE_LIST', '/roles'),

  /**
   * query params:
   * - selectedRGroupId: id of the restaurants group to select by default
   */
  BO_PRO_ROLE_CREATE: new Route('BO::BO_PRO_ROLE_CREATE', '/roles/add'),
  BO_PRO_ROLE_EDIT: new Route('BO::BO_PRO_ROLE_EDIT', '/roles/:roleId/edit'),

  //
  // Auth
  //

  BO_SELECT_PRO_DOMAINS: new Route('BO::DOMAINS::SELECT', '/domains/select'),

  BO_SELECT_RGROUP: new Route('BO::R_GROUP::SELECT', '/domains/:domainId/rgroup/select'),

  //
  // Security
  //

  BO_PERMISSIONS_ERROR: new Route('BO::PERMISSIONS::MISSING', '/security/missing-permissions'),

  //
  //
  //
  BO_GROUPS_CREATE: new Route('BO::GROUPS::CREATE', '/groups/:groupId/create'),

  BO_GROUPS_INFOS: new Route('BO::GROUPS::INFOS', '/groups/:groupId'),

  BO_GROUPS_TREE: new Route('BO::GROUPS::TREE', '/groups/:groupId/tree'),

  BO_GROUPS_STORES: new Route('BO::GROUPS::STORES', '/groups/:groupId/stores'),

  //
  // Custom Texts
  //

  BO_GROUPS_CUSTOM_TEXTS_EMAIL_LIST: new Route(
    'BO::GROUPS:CUSTOM_TEXTS_EMAIL_LIST',
    '/groups/:groupId/customs-texts/emails'
  ),

  BO_GROUPS_CUSTOM_TEXTS_EMAIL_DETAIL: new Route(
    'BO::GROUPS:CUSTOM_TEXTS_EMAIL_DETAIL',
    '/groups/:groupId/customs-texts/emails/:emailType'
  ),

  BO_CUSTOM_TEXTS_RESTAURANT: new Route(
    'BO_CUSTOM_TEXTS_RESTAURANT',
    '/groups/:groupId/custom-texts/restaurants'
  ),

  BO_GROUPS_EDIT_HIERARCHY: new Route(
    'BO::GROUPS::EDIT_HIERARCHY',
    '/groups/:groupId/edit-hierarchy'
  ),

  // PSP Settings
  BO_GROUPS_PSP_SETTINGS_LIST: new Route(
    'BO::GROUPS:PSP_SETTINGS_LIST',
    '/groups/:groupId/psp-settings/list'
  ),

  BO_GROUPS_PSP_SETTINGS_CREATE: new Route(
    'BO::GROUPS:PSP_SETTINGS_CREATE',
    '/groups/:groupId/psp-settings/create'
  ),

  BO_GROUPS_PSP_SETTINGS_DETAILS: new Route(
    'BO::GROUPS:PSP_SETTINGS_DETAILS',
    '/groups/:groupId/psp-settings/details/:pspSettingsId/:asDetails'
  ),

  BO_GROUPS_PSP_SETTINGS_EDIT: new Route(
    'BO::GROUPS:PSP_SETTINGS_EDIT',
    '/groups/:groupId/psp-settings/edit/:pspSettingsId/:asDetails'
  ),

  MOVE_STORE: new Route('MOVE_STORE', '/move-store'),

  BO_GROUPS_EDIT_STORES: new Route('BO::GROUPS::EDIT_STORES', '/groups/:groupId/edit-stores-list'),

  BO_GROUPS_STORES_CREATE: new Route(
    'BO::GROUPS::STORES::CREATE',
    '/groups/:groupId/stores/create'
  ),

  BO_RESTAURANTS_DOCUMENTS_STATUS: new Route(
    'BO::SETTINGS::STORES::LIST::DOCUMENTS_STATUS',
    '/groups/:groupId/stores/documents-status'
  ),

  BO_SETTINGS_STORES_VIEW_DETAILS: new Route(
    'BO::SETTINGS::STORES::VIEW::DETAILS',
    '/settings/stores/:restaurantId/details'
  ),

  BO_SETTINGS_STORES_VIEW_SETTINGS: new Route(
    'BO::SETTINGS::STORES::VIEW::SETTINGS',
    '/settings/stores/:restaurantId/settings'
  ),

  STORE_THIRDPARTY_SETTINGS_CREATE: new Route(
    'BO::SETTINGS::STORES::VIEW::SETTINGS::STORE_THIRDPARTY_SETTINGS::CREATE',
    '/settings/stores/:restaurantId/settings/storeThirdPartySettings/create'
  ),

  STORE_THIRDPARTY_SETTINGS_DETAILS: new Route(
    'BO::SETTINGS::STORES::VIEW::SETTINGS::STORE_THIRDPARTY_SETTINGS',
    '/settings/stores/:restaurantId/settings/storeThirdPartySettings/details/:thirdPartyIdentifier'
  ),

  BO_SETTINGS_STORES_VIEW_API_CONSUMERS: new Route(
    'BO::SETTINGS::STORES::VIEW::API_CONSUMER',
    '/settings/stores/:restaurantId/apiConsumers'
  ),

  BO_SETTINGS_STORES_VIEW_STORES_GROUPS: new Route(
    'BO::SETTINGS::STORES::VIEW::STORES_GROUPS',
    '/settings/stores/:restaurantId/groups'
  ),

  //
  // checkup
  //

  BO_CHECKUP_STORE: new Route('BO::BO_CHECKUP_STORE', '/settings/stores/:restaurantId/checkup'),
  BO_CHECKUP_GROUP_STORES: new Route(
    'BO::BO_CHECKUP_GROUP_STORES',
    '/settings/group/:groupId/checkup/stores'
  ),

  //
  // Entity history
  //

  BO_ENTITY_SELECT: new Route('BO::ENTITY_HISTORY::SELECT', '/entity-history/select'),

  BO_ENTITY_HISTORY_LIST: new Route(
    'BO::ENTITY_HISTORY::LIST',
    '/entity-history/:entityType/:entityId'
  ),

  BO_ENTITY_HISTORY_DETAIL: new Route(
    'BO::ENTITY_HISTORY::DETAIL',
    '/entity-history/:entityType/:entityId/:entityHistoryId'
  ),

  //
  // Feedback
  //

  BO_FEEDBACK_CAMPAIGN_LIST: new Route('BO::FEEDBACK::CAMPAIGN_LIST', '/feedback/campaigns'),

  BO_FEEDBACK_CAMPAIGN: new Route(
    'BO::FEEDBACK::CAMPAIGN',
    '/feedback/campaigns/:feedbackCampaignId'
  ),
  BO_FEEDBACK_CAMPAIGN_ANSWERS: new Route(
    'BO::FEEDBACK::CAMPAIGN::ANSWERS',
    '/feedback/campaigns/:feedbackCampaignId/answers'
  ),

  //
  // Audit
  //

  AUDIT_SESSION: new Route('BO::AUDIT::SESSION', '/audit/session/:sessionId'),

  //
  // Order
  //

  ORDER_DETAIL: new Route('BO::ORDER::DETAIL', '/order/:orderId'),

  ORDER_LIST: new Route('ORDER_LIST', '/orders'),

  ORDER_ON_GOING: new Route('BO::ORDER::ON_GOING', '/orders/on-going'),

  //
  // Payments
  //
  PAYMENT_DETAIL: new Route('BO::PAYMENTS::DETAIL', '/payments/payment/:paymentId'),

  PAYMENT_LIST: new Route('PAYMENT_LIST', '/payments'),

  //
  // Deposits
  //

  // Note:
  DEPOSIT_DETAIL: new Route('BO::DEPOSIT::DETAIL', '/deposits/:depositId'),

  //
  // Api consumer
  //

  API_CONSUMER_LIST: new Route('BO::API_CONSUMER::LIST', '/api-consumers'),

  API_CONSUMER_CREATE: new Route('BO::API_CONSUMER::CREATE', '/api-consumers/new'),

  API_CONSUMER_DETAIL: new Route('BO::API_CONSUMER::DETAIL', '/api-consumers/:apiConsumerId'),

  //
  // ZdpMid Configurations
  //

  ZDPMID_CONFIGURATION_LIST: new Route('BO::ZDPMID_CONFIGURATION::LIST', '/zdp-mid-configurations'),

  ZDPMID_CONFIGURATION_CREATE: new Route(
    'BO::ZDPMID_CONFIGURATION::CREATE',
    '/zdp-mid-configurations/new'
  ),

  ZDPMID_CONFIGURATION_DETAIL: new Route(
    'BO::ZDPMID_CONFIGURATION::DETAIL',
    '/zdp-mid-configurations/:zdpMidConfigurationId'
  ),

  //
  // Pro notifications settings
  //
  PRO_NOTIFICATIONS_SETTINGS_LIST: new Route(
    'BO::PRO_NOTIFICATIONS_SETTINGS::LIST',
    '/pro-notifications-settings'
  ),
  PRO_NOTIFICATIONS_SETTINGS_EDIT: new Route(
    'BO::PRO_NOTIFICATIONS_SETTINGS::EDIT',
    '/pro-notifications-settings/:proNotificationSettingId/edit'
  ),

  //
  // Media
  //
  BO_MEDIA_LIBRARY: new Route('BO::MEDIA', '/media'),
  BO_MEDIA_UPLOAD: new Route('BO::MEDIA::UPLOAD', '/media/upload'),

  //
  // Finance
  //
  PAYOUTS_REPORTS: new Route('PAYOUTS_REPORTS', '/payments/payouts/reports'),
  PAYOUT_REPORT: new Route('PAYOUT_REPORT', '/payments/payouts/reports/:reportId'),
  PAYOUTS_ALL_ON_GOING_REPORTS: new Route(
    'PAYOUTS_ALL_ON_GOING_REPORTS',
    '/payments/payouts/reports/support/on-going'
  ),
  PAYOUTS: new Route('PAYOUTS', '/payments/payouts'),
  PAYOUTS_FAILED: new Route('PAYOUTS_FAILED', '/payments/payouts/failed'),

  PAYOUT: new Route('PAYOUT', '/payments/payouts/:payoutId'),

  //
  // Documentation
  //

  API_DOCUMENTATION_ALL_ENDPOINTS: new Route(
    'API_DOCUMENTATION_ALL_ENDPOINTS',
    '/documentation/all-endpoints'
  ),
  API_DOCUMENTATION_ENDPOINTS: new Route(
    'API_DOCUMENTATION_ENDPOINTS',
    '/documentation/endpoints/:version/:access'
  ),
  API_DOCUMENTATION_POS_ENDPOINTS: new Route(
    'API_DOCUMENTATION_POS_ENDPOINTS',
    '/documentation/pos/endpoints/:version/:access'
  ),

  API_DOCUMENTATION_USER_ENDPOINTS: new Route(
    'API_DOCUMENTATION_POS_ENDPOINTS',
    '/documentation/pos/endpoints/:version/:access'
  ),

  //
  // Pim v2
  //

  ...routesPimv2Definitions,
}

export default BackofficeRoutingEnum
