import ExportApi from 'pmt-modules/api/calls/ExportApi'

import { createApiCallAction, createApiEnumAction } from '../api/utils'

import { createAction } from '../redux'
import { ExportType } from './constants'

/**
 * Export catering is one single action that can handle multiple export type one at a time
 */
export const ExportRestaurantsGroupAction = createApiEnumAction('EXPORT::CATERING::POST')

export const exportRestaurantsGroupData = (rGroupId, props) => {
  const apiCalls = {
    [ExportType.ORDER]: ExportApi.postOrders,
    [ExportType.USER]: ExportApi.postUsers,
    [ExportType.PAYMENT]: ExportApi.postPayments,
    [ExportType.BOOKING]: ExportApi.postBookings,
  }

  return createApiCallAction(ExportRestaurantsGroupAction, apiCalls[props.type](rGroupId, props), {
    ...props,
  })
}

//
//
//

export const ExportRestaurantsGroupActions = {
  RESET: 'EXPORT::CATERING::RESET',
}

export const resetExportRestaurantsGroup = () => createAction(ExportRestaurantsGroupActions.RESET)
