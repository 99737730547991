import { combineReducers } from 'redux'

import { auditEventReducer } from './events'
import { auditSessionReducer } from './session'
import { auditLogsReducer } from './log'

export * from './constants'

export const auditReducer = combineReducers({
  event: auditEventReducer,
  session: auditSessionReducer,
  logs: auditLogsReducer,
})
