import ApiEndpoints from '../config/ApiEndpoints'
import AuthProErrors from '../errors/AuthProErrors'

const ProAuthApi = {
  postBasicPassword: authorization => {
    return {
      endpoint: ApiEndpoints.PRO_ME,
      type: 'GET',
      headers: {
        Authorization: authorization,
      },
      errorHandler: AuthProErrors.POST,
    }
  },
}

export default ProAuthApi
