import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import { getRoute, generatePathWithParams } from 'pmt-modules/routing'
import { createListFormatter, createFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { identifyBrowserWithUserAgent } from 'pmt-utils/browser'
import { formatDate } from 'pmt-utils/date'
import { formatQueryParams } from 'pmt-utils/url'
import { formatOrder } from 'pmt-modules/order/format'
import { formatEntityCreated } from './formatEntityCreated'

import { ActorType, getActorTypeLabel, Event } from '../constants'

const DATE_FORMAT_MS = 'DD-MM-YYYY HH:mm:ss.SSS'

const formatEventDate = event => {
  event.dateFormatted = formatDate(event.date, DATE_FORMAT_MS)
  return event
}

const formatShare = event => {
  event.shareUrl = `${window.location.origin}${generatePathWithParams(getRoute('AUDIT_SESSION'), {
    sessionId: event.context.sessionId,
  })}`
  return event
}

//
// Actor
//

const getActorTypeData = actor => {
  switch (actor.type) {
    case ActorType.USER:
      return actor.user

    case ActorType.PRO:
      return actor.pro

    default:
      return null
  }
}

const formatActorPro = pro => {
  pro.hasDomain = !isNull(pro.domainId)
  return pro
}

const formatActorType = actor => {
  actor.typeLabel = getActorTypeLabel(actor.type)

  actor.isPro = actor.type === ActorType.PRO
  actor.isUser = actor.type === ActorType.USER
  actor.isAnonymous = actor.type === ActorType.ANONYMOUS
  actor.isAdmin = actor.type === ActorType.ADMIN
  actor.isRestaurant = actor.type === ActorType.RESTAURANT
  return actor
}

const formatActorData = actor => {
  const actorTypeData = getActorTypeData(actor)

  actor.email = ''

  if (!isNull(actorTypeData)) {
    actor.email = actorTypeData.email
  }

  if (actor.isPro) {
    actor.pro = formatActorPro(actor.pro)
  }

  return actor
}

const formatActor = createSubObjectFormatter(
  'actor',
  createFormatter(formatActorType, formatActorData)
)

//
// Context
//

const formatUserAgent = context => {
  context.userAgentShort = identifyBrowserWithUserAgent(context.userAgent)
  return context
}

const formatResourceQueryParams = resource => {
  resource.hasQueryParams = !isEmpty(resource.queryParams)
  if (resource.hasQueryParams) {
    resource.urlWithQueryParams = formatQueryParams(resource.url, resource.queryParams)
  } else {
    resource.urlWithQueryParams = resource.url
  }
  return resource
}

const formatResource = createFormatter(formatResourceQueryParams)

const formatLocation = location => {
  location.hasGeoPt = !isNull(location.geoPt)
  return location
}

const formatContextData = context => {
  context.hasApiConsumer = !isNull(context.apiConsumerId)
  context.hasRestaurantsGroup = !isNull(context.restaurantsGroupId)
  context.hasLocation = !isNull(context.location)
  return context
}

const formatContext = createSubObjectFormatter(
  'context',
  createFormatter(
    formatUserAgent,
    formatContextData,
    createSubObjectFormatter('entitiesCreated', createListFormatter(formatEntityCreated)),
    createSubObjectFormatter('resource', formatResource),
    createSubObjectFormatter('location', formatLocation)
  )
)

//
//
//

const formatPayloadPerEvent = event => {
  // list our formatters per event. Those formatters only receive the event payload and must return
  // the payload.
  const formatters = {
    [Event.SERVICE_RESPONSE]: formatEventServiceResponse,
  }

  const formatter = formatters[event.eventName]
  if (formatter) {
    event.payload = formatter(event.payload)
  }

  // format order if on payload
  if (event.payload.order) {
    event.payload.order = formatOrder(event.payload.order)
  }

  return event
}

//
// -------------------------------------------------------------------------------------
// formats per events
// -------------------------------------------------------------------------------------
//

//
// service.response
//

const formatValidResponse = payload => {
  payload.isValidResponse = payload.httpCode >= 200 && payload.httpCode < 400
  payload.requestBeginDateFormatted = formatDate(payload.requestBeginDate, DATE_FORMAT_MS)
  return payload
}

const formatEventServiceResponse = createFormatter(formatValidResponse)

//
// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------
//

export const formatEvent = createFormatter(
  formatContext,
  formatEventDate,
  formatActor,
  formatShare,
  formatPayloadPerEvent
)

const sortByDate = events => events.sort((a, b) => a.date - b.date)

export const formatEventsList = createFormatter(createListFormatter(formatEvent), sortByDate)
