import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Celery = props => (
  <SvgIcon {...props}>
    <path d="M 16.5 2 C 15.671875 2 15 2.671875 15 3.5 C 15 3.738281 15.058594 3.957031 15.15625 4.15625 C 14.957031 4.058594 14.738281 4 14.5 4 C 13.671875 4 13 4.671875 13 5.5 C 13 5.960938 13.234375 6.351563 13.5625 6.625 C 12.648438 7.449219 11.175781 8.40625 9.59375 9.4375 C 9.285156 9.640625 4.824219 12.753906 3.96875 13.5625 C 3.863281 13.648438 3.769531 13.730469 3.6875 13.8125 C 1.816406 15.683594 1.816406 18.722656 3.6875 20.59375 C 5.558594 22.464844 8.699219 22.5625 10.46875 20.59375 C 13.203125 17.558594 15.898438 13.265625 17.25 11.34375 C 17.519531 11.742188 17.984375 12 18.5 12 C 19.191406 12 19.765625 11.511719 19.9375 10.875 C 20.109375 10.945313 20.300781 11 20.5 11 C 21.328125 11 22 10.328125 22 9.5 C 22 9.113281 21.863281 8.765625 21.625 8.5 C 21.863281 8.234375 22 7.886719 22 7.5 C 22 6.742188 21.445313 6.136719 20.71875 6.03125 C 20.898438 5.730469 21 5.375 21 5 C 21 3.894531 20.105469 3 19 3 C 18.625 3 18.269531 3.101563 17.96875 3.28125 C 17.863281 2.554688 17.257813 2 16.5 2 Z M 15 7.9375 C 14.636719 8.316406 9.933594 13.183594 7.75 15.1875 C 5.628906 17.136719 5.460938 15.1875 8.4375 12.65625 C 11.230469 10.28125 13.847656 8.933594 15 7.9375 Z M 15.875 9.65625 C 15.925781 9.691406 15.980469 9.722656 16.03125 9.75 C 14.886719 11.257813 13.03125 14.34375 11.25 16.625 C 8.292969 20.300781 7.3125 18.449219 8.25 17.40625 C 12.332031 12.867188 14.785156 10.597656 15.875 9.65625 Z " />
  </SvgIcon>
)
Celery = pure(Celery)
Celery.displayName = 'Celery'
Celery.muiName = 'SvgIcon'

export default Celery
