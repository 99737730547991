import isEmpty from 'lodash/isEmpty'
import createMiddleware from '../redux/createMiddleware'
import {
  SELECT_DOMAIN,
  FetchMeDomain,
  FetchMeProDomains,
  fetchMeProDomainsList,
  fetchMeDomain,
} from './actions'

import { saveSelectedRestaurantsGroupId, saveSelectedDomainId } from '../bo/session'

import { Security } from '../bo/security'
import { getRoute, redirectTo, updatePageQueries, Query } from '../routing'

import { setSelectedRestaurantsGroup, setSelectedDomain } from '../bo/actions'
import { findRestaurantsGroupOnDomain } from './utils'
import { logoutPro } from '../authPro/actions'

//
// TODO: must of those middlewares should be in the bo middleware
//

const getRedirectToOnAction = action =>
  action.options ? action.options.redirectTo || Query.REMOVE_ME : Query.REMOVE_ME

/**
 * When the use select a domain, we redirect him to the restaurants group selection page.
 */
const meDomainSelectedMiddleware = createMiddleware(SELECT_DOMAIN, ({ action, dispatch }) => {
  const domain = action.domain

  if (domain && domain.id) {
    dispatch(
      redirectTo(
        getRoute('BO_SELECT_RGROUP'),
        {
          domainId: domain.id,
        },
        {
          redirectTo: getRedirectToOnAction(action),
          // remove old `domainId` from the url
          domain: Query.REMOVE_ME,
          // remove current `rGroupId` query
          rGroupId: Query.REMOVE_ME,
        }
      )
    )
  }
})

const onDomainReceived = domain => {
  // register the pro role
  Security.registerProRole(domain.role)
}

/**
 * Search for the corresponding `rGroupIdToFind` on the domain.
 * For now, the domain is loaded with all its groups. that allow us to search on the domain for
 * the restaurants group to use.
 * If we don't load the restaurants group anymore, uncomment on the bo middleware, but find a way
 * to verify that the restaurants group loaded there is valid for the domain that we receive here
 *
 * @param {*} domain
 * @param {*} rGroupIdToFind
 * @param {*} defaultRGroups array of default groups, by priority. the last one must be valid for
 *                          the domain (domain.restaurantsGroup.id)
 */
const getRestaurantsGroupForDomain = (domain, rGroupIdToFind, defaultRGroups) => {
  const group = findRestaurantsGroupOnDomain(domain, rGroupIdToFind)

  // group not found, try to find another one
  if (!group) {
    const defaultRGroup = defaultRGroups.shift()
    return getRestaurantsGroupForDomain(domain, defaultRGroup, defaultRGroups)
  }

  return group
}

const updateSession = (selectedDomain, selectedRestaurantsGroup) => {
  if (selectedRestaurantsGroup != null) {
    saveSelectedRestaurantsGroupId(selectedRestaurantsGroup.id)
  }
  saveSelectedDomainId(selectedDomain.id)
  // update the query domain.
  // When the current query domain is invalid, we look for a valid domain. So we need to update the
  // current query domain with the chosen domain id.
  updatePageQueries({ domain: selectedDomain?.id })
}

const fetchMeDomainSuccessMiddleware = createMiddleware(
  FetchMeDomain.SUCCESS,
  ({ action, dispatch }) => {
    const selectedDomain = action.response
    handleSelectRestaurantsGroupForDomain(
      selectedDomain,
      action.response.restaurantsGroup,
      action,
      dispatch
    )
  }
)

const fetchMeDomainsSuccessMiddleware = createMiddleware(
  FetchMeProDomains.SUCCESS,
  ({ action, dispatch }) => {
    // an user with disabled domains will receive an empty response.
    if (!isEmpty(action.response)) {
      // Note: we take the case 0 from the array of domains
      // for now the API returns it sorted by the most recent to the older.
      // Maybe we could use another method to select a default domain?
      // see comments on https://paymytable.atlassian.net/browse/PP-810
      const selectedDomain = action.response[0]

      handleSelectRestaurantsGroupForDomain(
        selectedDomain,
        selectedDomain.restaurantsGroup,
        action,
        dispatch
      )
    } else {
      const pathname = window.location.pathname
      // when we are on the logout page, we can't display the page since we arrive here and redirect
      // to the BO_SELECT_PRO_DOMAINS page.
      // In this case, the pro can't logout, so we force the logout here. On a 'normal' logout, the
      // pro is redirect to the logout page where he must confirm he wants to logout. This flow
      // directly logout the pro since we can't display the logout page without a selected domain.
      if (pathname === '/logout') {
        dispatch(logoutPro())
      } else {
        // the user does not have domains, we reset the select domain.
        dispatch(setSelectedDomain(null))
        // we redirect to the select domains page, that display an error (pro account disabled).
        dispatch(redirectTo(getRoute('BO_SELECT_PRO_DOMAINS')))
      }
    }
  }
)

// FetchMeDomain and FetchMeProDomains have the same props, so we handle it here.
const handleSelectRestaurantsGroupForDomain = (
  selectedDomain,
  // a valid restaurants group to use if all our other tests failed. usually the domain restaurants
  // group
  defaultRestaurantsGroup,
  action,
  dispatch
) => {
  // Note: action is runned by modules/bo/middlewares/boLoadDefaultDomainMiddleware
  // A domain as been retrieved
  // sometimes, the action is not runned by the bo middleware, this flag allow us to handle the
  // success only if it is the auth pro flow, and not just a data retrieving.
  if (action.data.useAsSelectedDomain) {
    // save the succeded found domain
    onDomainReceived(selectedDomain)
    dispatch(setSelectedDomain(selectedDomain))

    // domain is selected, lets select a restaurantsGroup

    let selectedRestaurantsGroup = null

    // action.data.useDefaultRGroup not handled anymore here since we try to load data by priority
    // if the flag is true, there is mostly action.data.rGoupIdToUse null or undefined, but this
    // is handled by getRestaurantsGroupForDomain.
    selectedRestaurantsGroup = getRestaurantsGroupForDomain(
      selectedDomain,
      action.data.rGoupIdToUse,
      [action.data.sessionRGroupId, defaultRestaurantsGroup.id]
    )

    // set the selected restaurants group
    dispatch(setSelectedRestaurantsGroup(selectedRestaurantsGroup))
    // save on session the selected data
    updateSession(selectedDomain, selectedRestaurantsGroup)
  }
}

/**
 * The domain failed to be loaded, it is mostly due to an invalid domainId on the url, so we
 * redirect to the domain selection
 */
const fetchMeDomainFailureMiddleware = createMiddleware(
  FetchMeDomain.FAILURE,
  ({ action, dispatch }) => {
    if (action.data.fallbackToDefault === true) {
      const tryWithSession = action.data.sessionDomainId !== action.data.domainId

      if (tryWithSession && action.data.fallbackToSession) {
        // we tried to load with the domain on the url, try with the one in session now
        dispatch(
          fetchMeDomain(String(action.data.sessionDomainId), {
            ...action.data,
            fallbackToDefault: true,
            fallbackToSession: false, // false to avoid infinite loop
          })
        )
      } else {
        // allow us to use the default domain if the domainIdToUse is invalid for the pro.
        dispatch(
          fetchMeProDomainsList({
            useAsSelectedDomain: true,
            useDefaultRGroup: true,
            rGoupIdToUse: action.rGoupIdToUse,
            authFlow: true,
          })
        )
      }
    } else {
      // we redirect to the domain selection page
      dispatch(
        redirectTo(
          getRoute('BO_SELECT_PRO_DOMAINS'),
          {},
          {
            redirectTo: getRedirectToOnAction(action),
            domain: Query.REMOVE_ME,
          }
        )
      )
    }
  }
)

export const domainMiddlewares = [
  meDomainSelectedMiddleware,
  fetchMeDomainSuccessMiddleware,
  fetchMeDomainsSuccessMiddleware,
  fetchMeDomainFailureMiddleware,
]
